import { MenuItem,Checkbox,ListItemText } from '@material-ui/core';

/**
 * @param {{
 * optionList: Object, 
 * label: string, 
 * value: string,
 * }} props 
 */

export const getOptions = (dropDownData) => {
    if(typeof dropDownData !== 'string'){
        const {key, value, optionLists} = dropDownData
        return optionLists.map((data, i) => <MenuItem key={i} style={{color: data?.color ? data.color : '#000000de'}} value={data[value]}>{data[key]}</MenuItem>);
    }else{
        return <MenuItem  value={0}>{dropDownData}</MenuItem>
    }
}

export const getOptionsGroups = (optionList, label, value, groupLabelflag, groupLabel) => {
    return optionList.map((data, i) => {
        if (groupLabelflag === data[groupLabel]) {
           return <MenuItem key={i} value={data[value]}>{data[label]}</MenuItem>
        }
    }
    );
}
export const getMultipleOptionsGroups = (optionList, label, value, groupLabelflag, groupLabel, values) => {
    return optionList.map((data, i) => {
        if (groupLabelflag === data[groupLabel]) {
            return <MenuItem key={i} value={data[value]}>
                <Checkbox checked={values.indexOf(data.uniqueId) > -1} />
                <ListItemText primary={data.displayText} />
            </MenuItem>
        }
    }
    );
}