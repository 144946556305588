import { COPY_PROPERTY, VIEW_PROPERTY, EDIT_PROPERTY, ADD_PROPERTY, ENABLE_COPY, GET_STEP_ONE_DATA, GET_FLOOR_DATA, SET_PROPERTY_ID } from './types'

const initialState = {
    isEnableCopy: true,
    floorData: [],
    stepOneData: "",
    propertyID: ""
};

const propertiesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ENABLE_COPY:
            return {
                ...state,
                ...payload
            }
        case GET_STEP_ONE_DATA:
            return {
                ...state,
                ...payload
            }
        case GET_FLOOR_DATA:
            return {
                ...state,
                ...payload
            }
        case SET_PROPERTY_ID:
            return {
                ...state,
                ...payload
            }
        case COPY_PROPERTY:
            return {
                ...state,
                propertyDetails: payload,
                formMode:type
            };
        case VIEW_PROPERTY:
            return {
                ...state,
                propertyDetails: payload,
                formMode: type
            };
        case EDIT_PROPERTY:
            return {
                ...state,
                propertyDetails: payload,
                formMode: type
            };
        case ADD_PROPERTY:
            return {
                ...state,
                propertyDetails: null,
                formMode: type
            };
        default:
            return state;
    }
}
export default propertiesReducer