import React, { useCallback, useEffect, useState } from 'react';
import { FormInputWrapper } from '../../styles/common-styles';
import { Select, InputLabel, MenuItem, FormControl, ListItemText, Checkbox, FormHelperText, ListSubheader, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, Paper, Tooltip, Menu } from '@material-ui/core';
import { getMultipleOptionsGroups } from '../../helper';
import { Box, ClickAwayListener, FormControlLabel } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "auto",
        },
    },
};
/**
 * @param {{
 * label: string,
 * labelId: string,
 * formik: Object,
 * list:Array,
 * isDoubled: Boolean,
 * singleSelect:Boolean,
 * editedValue:Array,
 * mandatory:boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * disabled:boolean,
 * useMultiColumn: Boolean,
 * multiColumnOptions: Array,
 * headers: Array
 * }} props
 */
export const MultipleSelectInput = (props) => {
    const [values, setValue] = useState([]);
    const { customStyle, onTooltip, label, labelId, formik, list, isDoubled, singleSelect, isCustomised, editedValue, mandatory, errors, touched, disabled, useMultiColumn, multiColumnOptions, headers } = props;
    const [isTooltip, setTooltip] = useState(true);
    const handleChange = (event) => {
        const { target: { value } } = event;
        if (!singleSelect) {
            setValue(
                // On autofill we get a the stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
            formik.setFieldValue(labelId, value);
        } else {
            if (value.length > 0) {
                formik.setFieldValue(labelId, [...editedValue, value[value.length - 1]]);
            }
        }
    };

    // Loading initial and edit values
    useEffect(() => {
        if (typeof formik.values[labelId] != 'string') setValue(formik.values[labelId]);
    }, [formik.values[labelId]]);

    const renderHeader = (headers) => (
        <TableHead>
            <TableRow>
                {headers.map((header, index) => (
                    <TableCell key={index} style={{ width: header.width, fontWeight: "bold" }}>{header.text}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

    const renderMultiColumnOptions = (option) => (
        <TableBody>
            <TableRow>
                {option.columns.map((column, index) => (
                    <TableCell className="tableCell" key={index} style={{ width: column.width }}>{column.value}</TableCell>
                ))}
            </TableRow>
        </TableBody >
    );

    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled} className={isDoubled ? 'doubled' : null} isCustomised={isCustomised}>
            <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                <Tooltip title={onTooltip && isTooltip ? onTooltip : ""} arrow>
                    <Select
                        error={(errors && touched) ? true : false}
                        labelId={labelId}
                        id={labelId}
                        multiple
                        value={values}
                        onChange={handleChange}
                        disabled={disabled ? disabled : false}
                        displayEmpty
                        renderValue={(selected) => { return (selected && selected.map((item) => { if (list.length > 0) return list.find(obj => obj.uniqueId == item)?.displayText }).join(", ")) }}
                        MenuProps={MenuProps}
                        onOpen={() => setTooltip(false)}
                        onClose={() => setTooltip(true)}
                    >
                        {useMultiColumn && headers && (
                            <MenuItem>
                                <TableContainer component={Paper} style={{ boxShadow: 'none', paddingLeft: 45 }}>
                                    <Table>
                                        {renderHeader(headers)}
                                    </Table>
                                </TableContainer>
                            </MenuItem>
                        )}
                        {useMultiColumn ? (
                            multiColumnOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <Checkbox checked={values.indexOf(option.value) > -1} />
                                    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                                        <Table>
                                            {renderMultiColumnOptions(option)}
                                        </Table>
                                    </TableContainer>
                                </MenuItem>
                            ))
                        ) : (
                            list.map((name) => (
                                <MenuItem disabled={name.disabled} key={name.uniqueId} value={name.uniqueId}>
                                    <Checkbox disabled={name.disabled} checked={values.indexOf(name.uniqueId) > -1} />
                                    <ListItemText primary={name.displayText} />
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </Tooltip>
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormControl>
        </FormInputWrapper>
    );
};

export const GroupMultipleSelectInput = (props) => {
    const [values, setValue] = useState([]);
    const { customStyle, onTooltip, label, labelId, formik, list, isDoubled,  isCustomised, mandatory, errors, touched, disabled } = props;
    const [open, setOpen] = useState(false);
    const [isTooltip, setTooltip] = useState(true);
    const [interminate, setInterminate] = useState([]);
    const [selectOpen, setSelectOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
    
    var checkBoxValues = [];
    const handleChange = useCallback((event) => {
        
        const { target: { value } } = event;
        list.map((name) => {
            if (value.includes(name.uniqueId) && !value.includes(name.parentUniqueId)) {
                value.push(name.parentUniqueId);
            }
        })
        checkBoxValues = value;
        setValue(checkBoxValues);
        formik.setFieldValue(labelId, checkBoxValues);
    });
    const childHandleChange = (event, id) => {
        const groupData = list.filter((opt) => opt.parentUniqueId === id && opt.parentUniqueId !== opt.uniqueId);
        const selectedData = groupData.filter((opt) => checkBoxValues.includes(opt.uniqueId));
        if (selectedData.length > 0 && groupData.length !== selectedData.length) {
            setInterminate([...interminate, id])
        }
        else {
            setInterminate(interminate.filter(item => item !== id))
            if (selectedData.length === 0) {
                setValue(checkBoxValues.filter(item => item !== id));
                formik.setFieldValue(labelId, checkBoxValues.filter(item => item !== id));
            }
        }
    }
    const parentHandleChange = (event, id) => {
        if (event.target.checked) {
            list.map((name) => {
                if (name.parentUniqueId === id && name.parentUniqueId !== name.uniqueId) {
                    checkBoxValues.push(name.uniqueId);
                }
            })
        }
        else {
            list.map((name) => {
                if (name.parentUniqueId === id) {
                    checkBoxValues = checkBoxValues.filter(item => item !== name.uniqueId)
                }
            })
        }
        setValue(checkBoxValues);
        formik.setFieldValue(labelId, checkBoxValues);
    }
    // Loading initial and edit values
    useEffect(() => {
        if (typeof formik.values[labelId] != 'string') setValue(formik.values[labelId]);
    }, [formik.values[labelId]]);
    const handleClose = () => {
        setOpen(false);
        setTooltip(true);
    }
    const handleOpen = () => {
        setOpen(true);
        setTooltip(false);
    }
    const handleClick = (event) => {
        setOpen(true);
        setTooltip(false);
    }

    const handlePopupClose = () => {
        setOpen(false);
    };

    const handleSelectClose = () => {
        setSelectOpen(false);
    };

    return (
       
            <FormInputWrapper style={customStyle} isDoubled={isDoubled} className={isDoubled ? 'doubled' : null} isCustomised={isCustomised}>
                <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
                {/* <ClickAwayListener onClickAway={handleSelectClose}> */}
                <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                    <Tooltip title={onTooltip && isTooltip ? onTooltip : ""} arrow>
                        <Select
                            error={(errors && touched) ? true : false}
                            labelId={labelId}
                            id={labelId}
                            multiple
                            value={values}
                            onChange={handleChange}
                            disabled={disabled ? disabled : false}
                            displayEmpty
                            renderValue={(selected) => { return (selected && selected.map((item) => { if (list.length > 0) return list.find(obj => obj.uniqueId == item)?.displayText }).join(", ")) }}
                            MenuProps={MenuProps}
                            open={selectOpen}
                            onClose={handleSelectClose}
                            onOpen={() => setSelectOpen(true)}
                            onClick={handleClick}
                        >
                            {list.map((name) => (
                                <MenuItem disabled={name.disabled} key={name.uniqueId} value={name.uniqueId}>
                                    {name.uniqueId === name.parentUniqueId ?
                                        (<FormControlLabel
                                            label={name.displayText}
                                            control={<Checkbox
                                                checked={values.indexOf(name.uniqueId) > -1}
                                                indeterminate={interminate.includes(name.uniqueId) ? true : false} />}
                                            onChange={(val) => parentHandleChange(val, name.uniqueId)}
                                        />)
                                        : (<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                            <FormControlLabel
                                                label={name.displayText}
                                                control={<Checkbox checked={values.indexOf(name.uniqueId) > -1} />}
                                                onChange={(val) => childHandleChange(val, name.parentUniqueId)}
                                            />
                                        </Box>)
                                    }
                                </MenuItem>)
                            )
                            }
                        </Select>
                    </Tooltip>
                    {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
                </FormControl>
                {/* </ClickAwayListener> */}
            </FormInputWrapper>
        
    );
};
export const MultipleSelectInputWithSelectAll = (props) => {

    const [values, setValue] = useState([]);
    const { customStyle, onTooltip, label, labelId, selectAllText, formik, list, isDoubled, singleSelect, isCustomised, editedValue, mandatory, errors, touched, disabled, useMultiColumn, multiColumnOptions, headers } = props;
    const [isTooltip, setTooltip] = useState(true);
    const listValues = list.map((item) => item.uniqueId);

    useEffect(() => {
        formik.setFieldValue(labelId, values);
    }, [values])


    const handleChange = (event) => {
        const { target: { value } } = event;
        if (!singleSelect) {

            if (value.includes('all')) {
                setValue(
                    ((values && values.length) === (list && list.length))
                        ? []
                        : listValues);

                return
            }
            else {
                setValue(
                    // On autofill we get a the stringified value.
                    typeof value === 'string' ? value.split(',') : value,
                );

            }



        } else {
            if (value.length > 0) {
                formik.setFieldValue(labelId, [...editedValue, value[value.length - 1]]);
            }
        }
    };

    // Loading initial and edit values
    useEffect(() => {
        if (typeof formik.values[labelId] != 'string') setValue(formik.values[labelId]);
    }, [formik.values[labelId]]);

    const isAllSelected = list.length > 0 && values.length === list.length;

    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled} className={isDoubled ? 'doubled' : null} isCustomised={isCustomised}>
            <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                <Tooltip title={onTooltip && isTooltip ? onTooltip : ""} arrow>
                    <Select
                        error={(errors && touched) ? true : false}
                        labelId={labelId}
                        id={labelId}
                        multiple
                        value={values}
                        onChange={handleChange}
                        disabled={disabled ? disabled : false}
                        displayEmpty
                        renderValue={(selected) => { return (selected && selected.map((item) => { if (list.length > 0) return list.find(obj => obj.uniqueId == item)?.displayText }).join(", ")) }}
                        MenuProps={MenuProps}
                        onOpen={() => setTooltip(false)}
                        onClose={() => setTooltip(true)}
                    >
                        <MenuItem value="all">
                            <Checkbox checked={isAllSelected}></Checkbox>
                            <ListItemText primary={selectAllText} />
                        </MenuItem>
                        {(
                            list.map((name) => (
                                <MenuItem disabled={name.disabled} key={name.uniqueId} value={name.uniqueId}>
                                    <Checkbox checked={values.indexOf(name.uniqueId) > -1} />
                                    <ListItemText primary={name.displayText} />
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </Tooltip>
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormControl>
        </FormInputWrapper>
    );
};

export const MultipleGroupSelectInput = (props) => {
    const [values, setValue] = useState([]);
    const { label, labelId, formik, list, isDoubled, singleSelect, editedValue, mandatory, errors, touched, groupOne, key, value: valueKey, groupLabel, groupTwo, useMultiColumn, multiColumnOptions, headers } = props;

    const handleChange = (event) => {
        const { target: { value } } = event;
        if (!singleSelect) {
            setValue(
                // On autofill we get a the stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
            formik.setFieldValue(labelId, value);
        } else {
            if (value.length > 0) {
                formik.setFieldValue(labelId, [...editedValue, value[value.length - 1]]);
            }
        }
    };

    // Loading initial and edit values
    useEffect(() => {
        if (typeof formik.values[labelId] !== 'string') setValue(formik.values[labelId]);
    }, [formik.values[labelId]]);

    const renderHeader = (headers) => (
        <TableHead>
            <TableRow>
                {headers.map((header, index) => (
                    <TableCell key={index} style={{ width: header.width }}>{header.text}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

    const renderMultiColumnOptions = (option) => (
        <TableBody>
            <TableRow>
                {option.columns.map((column, index) => (
                    <TableCell className="tableCell" key={index} style={{ width: column.width }}>{column.value}</TableCell>
                ))}
            </TableRow>
        </TableBody>
    );

    return (
        <FormInputWrapper isDoubled={isDoubled ? isDoubled : null}
            className={['customClass', isDoubled ? 'doubled' : null]}
        >
            <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
            <FormControl variant="outlined" sx={{ m: 1, width: 300 }}>
                <Select
                    error={(errors && touched) ? true : false}
                    labelId={labelId}
                    id={labelId}
                    multiple
                    value={values}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={(selected) => selected.map((item) => {
                        if (list.length > 0)
                            return list.find(obj => obj.uniqueId == item).displayText
                    }).join(", ")}
                    MenuProps={MenuProps}
                >
                    {useMultiColumn && headers && (
                        <MenuItem disabled>
                            <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                                <Table>
                                    {renderHeader(headers)}
                                </Table>
                            </TableContainer>
                        </MenuItem>
                    )}
                    {useMultiColumn ? (
                        multiColumnOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {renderMultiColumnOptions(option)}
                            </MenuItem>
                        ))
                    ) : (
                        <>
                            <ListSubheader style={{ pointerEvents: 'none', position: 'relative' }}>{groupOne}</ListSubheader>
                            {getMultipleOptionsGroups(list, key, valueKey, true, groupLabel, values)}
                            <ListSubheader style={{ pointerEvents: 'none', position: 'relative' }}>{groupTwo}</ListSubheader>
                            {getMultipleOptionsGroups(list, key, valueKey, false, groupLabel, values)}
                        </>
                    )}
                </Select>
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormControl>
        </FormInputWrapper>
    );
};
