import {
    PROSPECT_TAB_CHANGE,
    IMPORTEDFILES_TAB_CHANGE,
    REVIEW_TAB_CHANGE,
    GET_IMPORTED_FILES,
    GET_REVIEW_DATA,
    GET_PROSPECT_DATA,
    GET_PROSPECT_RESPONSE
} from './types'
const initialState = {
    prospectStudent: {
        isProspectStudentImport: true,
        isImportedFiles: false,
        isReviewAndProcess: false,
        importedFiles: "",
        reviewAndProcess: "",
        prospectData: "",
        prospectRespose:""
    }

};
const prospectStudentIpmortReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PROSPECT_RESPONSE:
            return {
                ...state, prospectStudent: {
                    ...state.prospectStudent,
                    prospectRespose: payload
                }
            };
        case GET_PROSPECT_DATA:
            return {
                ...state, prospectStudent: {
                    ...state.prospectStudent,
                    prospectData: payload
                }
            };

        case PROSPECT_TAB_CHANGE:
            return {
                ...state, prospectStudent: {
                    ...state.prospectStudent,
                    isProspectStudentImport: true,
                    isImportedFiles: false,
                    isReviewAndProcess: false
                }
            };
        case IMPORTEDFILES_TAB_CHANGE:
            return {
                ...state, prospectStudent: {
                    ...state.prospectStudent,
                    isProspectStudentImport: false,
                    isImportedFiles: true,
                    isReviewAndProcess: false
                }

            };
        case REVIEW_TAB_CHANGE:
            return {
                ...state, prospectStudent: {
                    ...state.prospectStudent,
                    isProspectStudentImport: false,
                    isImportedFiles: false,
                    isReviewAndProcess: true
                }

            };
        case GET_IMPORTED_FILES:
            return {
                ...state, prospectStudent: {
                    ...state.prospectStudent,
                    importedFiles: payload
                }

            };
        case GET_REVIEW_DATA:
            return {
                ...state, prospectStudent: {
                    ...state.prospectStudent,
                    reviewAndProcess: payload
                }

            };



        default:
            return state;
    }
}
export default prospectStudentIpmortReducer;