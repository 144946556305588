import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const locale = navigator.language; // To detect the browser language

let lang;

if (locale.indexOf("en") > -1) {
    lang = "en-US";
    // lang = "fr-CA";
} else {
    if (locale.indexOf("fr") > -1) {
        lang = "fr-CA";
    }
}

const fallbackLng = [lang];
const availableLanguages = [lang];

i18n
   .use(Backend)
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
        fallbackLng: fallbackLng, //if user computer language is not in the list of available languages, then it will be using this.
        debug: true,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;