import config from './config';

const { base_admission_url, base_api_url } = config.common

// IDS Call for client ID
export const ids_clientId = `${base_api_url}/api/Login/GetLoginDetails`;

//Login 
export const login_url = `${base_api_url}/api/Login/Authenticate`;
export const baseAPIURL = base_api_url;
export const idsLogin = `${base_api_url}/api/Login/Token`
// Admission Module Starts here
//Prospect Search
export const prospect_url = `${base_admission_url}/api/prospect/search`;
export const prospect_dropdown = `${prospect_url}/get-select-items-list`;
export const load_prospect_dropdown = `${base_admission_url}/api/student/load-from-prospect/select-items`;
export const load_prospect = `${base_admission_url}/api/student/load-from-prospect`;
export const prospect_email = `${base_admission_url}/api/prospect/email`;

//Prospect Add Information 
export const addInfo_dropdown = `${base_admission_url}/api/prospect/information/get-select-items-list`;
export const addInfo = `${base_admission_url}/api/prospect/information`;

//Student Add Information
export const addStudentInfo_dropdown = `${base_admission_url}/api/student/student-admission/select-items`;
export const addStudentInfo = `${base_admission_url}/api/student/student-admission`;
export const addStudent = `${base_admission_url}/api/student/student-admission`;
export const deleteStudent = `${base_admission_url}/api/student`;
export const get_NationalId = `${base_admission_url}/api/student/student-nationalID`;

//Prospect Portal Access 
export const getPortalAccess = `${base_admission_url}/api/prospect/portal-access`;
export const portal_access_reset_password = `${base_admission_url}/api/prospect/portal-access/reset-password`
export const portal_access_email_portal_info = `${base_admission_url}/api/prospect/portal-access/email-portal-information`


//Prospect Activity
export const activity_url = `${base_admission_url}/api/prospect/activities`;
export const activity_dropdown = `${activity_url}/get-select-items-list`;
export const sequence_dropdown = `${activity_url}/sequence-groups`;
export const activity_groupid = `${activity_url}/sequence-group-activities`;
export const activity_load_sequence = `${activity_url}/load-sequence-activities`;

//Prospect Interest
export const interest_url = `${base_admission_url}/api/prospect/interests`;
export const interest_dropdown = `${interest_url}/get-select-items-list`;

//Prospect Contacts
export const contact_url = `${base_admission_url}/api/prospect/contacts`;
export const contact_dropdown = `${contact_url}/get-select-items-list`;
export const contact_search = `${base_admission_url}/api/prospect/contact-search`;

//Prospect Contacts changed
export const contact_prospect = `${base_admission_url}/api/prospect/contacts-pros`;

//prospect contacts changed
export const contact_url_select = `${base_admission_url}/api/prospect/contacts-select`;
export const contact_get = `${contact_url_select}/get-select-items-list`;

//Prospect Others
export const others_url = `${base_admission_url}/api/prospect/others`;
export const others_dropdown = `${others_url}/select-items`;

// Prospect PrevName
export const prevname_url = `${base_admission_url}/api/prospect/prevname`


// Prospect Questions
export const questions_dropdown = `${base_admission_url}/api/prospect/questions/get-select-items-list`;
export const get_Questions = `${base_admission_url}/api/prospect/searchquestions`;
export const ask_Question = `${base_admission_url}/api/prospect/questions`;
export const get_Answers = `${base_admission_url}/api/prospect/questions/answers?questionId=`;

// Prospect HighSchool
export const highscool_url = `${base_admission_url}/api/prospect/highschool`
export const highscool_completion_dropdown_url = `${highscool_url}/get-select-items-list`

// Prospect TestScore
export const testScore_url = `${base_admission_url}/api/prospect/hstest`

// Prospect Score
export const score_url = `${base_admission_url}/api/prospect/hstestscore`

//Prospect Ethnicity
export const ethnicity_url = `${base_admission_url}/api/prospect/ethnicity`;
export const ethnicity_dropdown = `${ethnicity_url}/get-select-items-list`;

//Prospect Employment
export const employment_url = `${base_admission_url}/api/prospect/employment`;
export const employment_dropdown = `${employment_url}/get-select-items-list`;
export const employment_search = `${base_admission_url}/api/prospect/employment-search`;

//Organization lookup and search
export const organization_url = `${base_admission_url}/api/prospect/organization`;
export const organization_dropdown = `${organization_url}/get-select-items-list`;
export const organization_search = `${base_admission_url}/api/prospect/organization-search`;
export const add_organization_dropdown = `${base_admission_url}/api/organization/select-items`;
export const organization_lookup_url = `${base_admission_url}/api/organization`;
export const org_contacts = `${base_admission_url}/api/organization/contacts`;
export const org_comments = `${base_admission_url}/api/organization/comment`;
export const org_activites = `${base_admission_url}/api/organization/activities`;

//Prospect SOP
export const sop_url = `${base_admission_url}/api/prospect/statement-of-purpose`;

//Prospect Identifiers
export const identifier_url = `${base_admission_url}/api/prospect/identifiers`;
export const identifier_dropdown = `${identifier_url}/get-select-items-list`;

//Prospect User Defined
export const userdefined_url = `${base_admission_url}/api/prospect/user-defined`;
export const userdefined_dropdown = `${userdefined_url}/select-items`;

//Prospect College
export const college_url = `${base_admission_url}/api/prospect/colleges`;
export const college_dropdown = `${college_url}/get-select-items-list`;
export const college_search = `${college_url}/search`;

//Student Search
export const student_url = `${base_admission_url}/api/student/search`;
export const student_dropdown = `${student_url}/get-select-items-list`;
export const student_address = `${base_admission_url}/api/student/student-address`;
export const student_lookup = `${base_admission_url}/api/student/lookup`;

//Prospect Citizenship
export const citizenship_url = `${base_admission_url}/api/prospect/citizenship`;
export const citizenship_dropdown = `${citizenship_url}/get-select-items-list`;

//Prospect International
export const international_url = `${base_admission_url}/api/prospect/prospect-international`;
export const international_dropdown = `${base_admission_url}/api/prospect/international-Information/get-select-items-list`;
export const admission_status_lookup_url = `${base_admission_url}/api/prospect/prospect-applicant-status-ref`;
export const highschool_search = `${base_admission_url}/api/prospect/highschool/search`;

//Prospect Award
export const award_url = `${base_admission_url}/api/prospect/awards`;
export const award_dropdown = `${award_url}/get-select-items-list`;

//Prospect Merge
export const merge_url = `${base_admission_url}/api/prospect/merge`;
export const merge_search_url = `${merge_url}/search`;

//Student Activity
export const student_activity_url = `${base_admission_url}/api/student/activities`;
export const student_activity_dropdown = `${student_activity_url}/get-select-items-list`;
export const student_sequence_dropdown = `${sequence_dropdown}`;
export const student_activity_groupid = `${activity_groupid}`;
export const student_activity_load_sequence = `${student_activity_url}/load-sequence-activities`;

//Student Interest
export const student_interest_url = `${base_admission_url}/api/student/interests`;

//Student Ethnicity
export const student_ethnicity_url = `${base_admission_url}/api/student/ethnicity`;

//Student Employment
export const student_employment_url = `${base_admission_url}/api/student/employment`;

//Student Contacts
export const student_contact_url = `${base_admission_url}/api/student/contacts`;

//Student Degree
export const degree_url = `${base_admission_url}/api/student/degrees`;
export const degree_dropdown = `${degree_url}/get-select-items-list`;

//Faculty Lookup
export const degree_lookup = `${base_admission_url}/api/student/degrees-lookup`;
export const degree_get = `${degree_lookup}/get-select-items-list`;

//Student Greetings
export const greeting_url = `${base_admission_url}/api/student/greeting`;

//Student Relations
export const relation_url = `${base_admission_url}/api/student/relations`;
export const relation_dropdown = `${relation_url}/get-select-items-list`;

//Student Ferpa 
export const FERPA_url = `${base_admission_url}/api/student/student-ferpa`;
export const FERPA_dropdown = `${FERPA_url}/select-items`;

//Student College
export const student_college_url = `${base_admission_url}/api/student/colleges`;

//Student State Reporting 
export const state_reporting_url = `${base_admission_url}/api/student/state-reporting`;
export const state_reporting_dropdown = `${state_reporting_url}/get-select-items-list`;

//Student GPA Group
export const GPA_url = `${base_admission_url}/api/student/gpa-group`;
export const GPA_dropdown = `${GPA_url}/get-select-items-list`;
export const GPA_activate = `${GPA_url}/activate`;

//Student demographic
export const demographic_url = `${base_admission_url}/api/student/student-demographics`;
export const demographic_dropdown = `${demographic_url}/select-items`;
// Student HighSchool
export const student_highscool_url = `${base_admission_url}/api/student/highschool`;
export const document_tracking_loc = `${base_admission_url}/api/student/document-tracking-loc`;
export const document_tracking = `${base_admission_url}/api/student/document-tracking`;
export const student_doc_dropdown = `${document_tracking}/get-select-items-list`;
export const load_colleges_doc = `${document_tracking}/docsList`;
export const get_doc = `${document_tracking}/viewdocimage`;
export const upload_doc = `${document_tracking}/image`;
export const prospect_document_tracking = `${base_admission_url}/api/student/prospect-document-tracking`
export const prospect_document_tracking_post = `${base_admission_url}/api/student/prospect-document-tracking`

//Load Document Sequence
export const document_tracking_doc = `${base_admission_url}/api/student/document-tracking-doc`;
export const load_colleges_doclist = `${document_tracking_doc}/docsList`

//Contacts
export const sub_contact_url = `${base_admission_url}/api/contact/search`;
export const sub_contact_dropdown = `${sub_contact_url}/get-select-items-list`;
export const main_contact_url = `${base_admission_url}/api/contact`;

//Lookups
//Letter Sequences
export const letter_sequences_url = `${base_admission_url}/api/lookup/letter-sequences`;
export const letter_sequences_groups = `${letter_sequences_url}/groups`;
export const letter_sequences_dropdown = `${letter_sequences_url}/get-organization-items-list`;
export const letter_sequences_activities = `${letter_sequences_url}/activities`;
export const letter_sequences_dropdown_group = `${letter_sequences_activities}/get-select-items-list`;
export const letter_sequences_activities_dropdown = `$${base_admission_url}/api/lookup/letter-sequences/activities/get-select-items-list`;
export const letter_sequences_Verify = `${base_admission_url}/api/lookup/letter-sequences/verify`;

// High School
export const lookups_highschool = `${base_admission_url}/api/lookup/highschool`
export const lookups_hs_codes = `${base_admission_url}/api/lookup/highschool/codes`

// Colleges
export const lookups_college = `${base_admission_url}/api/lookup/college`
export const lookup_college_dropdown = `${base_admission_url}/api/lookup/college/get-select-items-list`
export const lookups_college_code = `${base_admission_url}/api/lookup/college-code`

//Student SEVIS
export const student_sevis = `${base_admission_url}/api/student/sevis`;
export const student_sevis_dropdown = `${base_admission_url}/api/student/sevis/get-select-items-list`
export const student_sevis_dependents = `${base_admission_url}/api/student/sevis/dependents`

//Student RISK
export const student_risk = `${base_admission_url}/api/student/risk-indicator`
export const student_risk_dropdown = `${base_admission_url}/api/student/risk-indicator/select-items-list`
export const student_risk_360 = `${base_admission_url}/api/student/risk/student360`
export const student_risk_360_dropdown = `${student_risk_360}/select-items-list`
export const student_risk_type_dropdown = `${base_admission_url}/api/student/risk-type/select-items-list`
export const student_risk_notes = `${base_admission_url}/api/student/risk-indicator/notes`

//Student Evaluation > Applicant
export const student_evaluation_applicant = `${base_admission_url}/api/student/student-eval-applicant`
export const applicant_course_detail = `${student_evaluation_applicant}/course-detail`
export const student_evaluation = `${base_admission_url}/api/student/student-eval`
export const student_evaluation_setup = `${base_admission_url}/api/student/student-eval-setup`
export const setup_subject_list = `${student_evaluation_setup}/subjects-list`
export const evaluation_select_item_list = `${student_evaluation}/select-items-list`
export const subject_grouping_list = `${student_evaluation_setup}/subject-groupings-list`
export const manage_course_detail = `${student_evaluation_applicant}/manage-course-detail`
export const manage_student_colleges = `${student_evaluation_applicant}/manage-student-colleges`
export const manage_applicant_summary = `${student_evaluation_applicant}/manage-eval-applicant-summary`
export const laod_manage_applicant_summary = `${student_evaluation_applicant}/load-prereq-sequence/manage-eval-applicant-summary`
export const applicant_appvalref = `${student_evaluation_applicant}/appevalref`
export const applicant_summary = `${student_evaluation_applicant}/summary`
export const applicant_student_college = `${student_evaluation_applicant}/student-college`
export const check_summary_exists = `${student_evaluation_applicant}/check-summary-exists`
export const units_gpa_grade = `${student_evaluation}/units-gpa-for-grade`

//Laptop Tracking
export const laptop_tracking = `${base_admission_url}/api/student/laptop-tracking`
export const laptop_tracking_equip = `${laptop_tracking}/equipment`
export const laptop_tracking_equip_dropdown = `${laptop_tracking_equip}/select-items`
export const laptop_tracking_item = `${laptop_tracking}/equipment-item`
export const laptop_tracking_item_dropdown = `${laptop_tracking_item}s/select-items`
export const laptop_tracking_item_equip_delete = `${laptop_tracking}/equip-items`

//Working To-Do
export const criteria_tab_selectitems = `${base_admission_url}/api/student/student-report/select-items`
export const criteria_tab_load_info = `${base_admission_url}/api/student/student-report/working-set`
export const student_info_working_to_do = `${base_admission_url}/api/student/student-report/student-info`
export const student_info_delete = `${base_admission_url}/api/student/student-activity`
export const student_notes_smartgrid_data = `${base_admission_url}/api/student/student-notes`
export const add_StudentNote = `${base_admission_url}/api/student/student-report/student-note`;
export const delete_StudentNote = `${base_admission_url}/api/student/student-report`;

// Student > Evaluation > Setup
export const student_setup = `${base_admission_url}/api/student/student-eval-setup`
export const student_setup_subject_list = `${student_setup}/subjects-list`
export const student_setup_subject_groupings = `${student_setup}/subject-groupings-list`
export const student_setup_semester_type = `${student_setup}/semester-types-list`
export const student_setup_manage = `${student_setup}/manage`
export const setup_college_semester_dropdown = `${student_setup}/semester-types-list`
export const setup_delete_base = `${student_setup}/delete`
export const setup_filter_to_term = `${base_admission_url}/api/student/student-eval/select-items-list`

// Student Access
export const student_access = `${base_admission_url}/api/student/student-access`
export const student_access_dropdown = `${student_access}/select-items`
export const student_access_reset_password = `${student_access}/reset-password`
export const student_access_emailInfo = `${student_access}/email-portal-information`;

//Student Reports Byor
export const report_byor_basic_select_items = `${base_admission_url}/api/student/report-byor/basic-select-items`
export const report_byor_demographic_select_items = `${base_admission_url}/api/student/report-byor/demographic-select-items`
export const report_byor_scores_select_items = `${base_admission_url}/api/student/report-byor/scores-select-items`
export const report_byor_academic_select_items = `${base_admission_url}/api/student/report-byor/academic-select-items`
export const report_byor_interest_select_items = `${base_admission_url}/api/student/report-byor/interest-select-items`
export const report_byor_interest_college_select_items = `${base_admission_url}/api/student/report-byor/interest-college-select-items`
export const report_byor_reports_select_items = `${base_admission_url}/api/student/report-byor/reports-select-items`
export const repot_byor_reports_user_defined_select_items = `${base_admission_url}/api/student/report-byor/reports-user-defined-select-items`
export const report_byor_scores_testscore_select_item = `${base_admission_url}/api/student/report-byor/scores-testscore-select-item`
// Admission Module Ends here

// Registration Module Starts here

export const grade_entry = `${base_admission_url}/api/academic/grades/entry`
export const grade_entry_dropdown = `${grade_entry}/select-items`
export const grade_entry_load = `${grade_entry}/load`
export const course_lookup = `${base_admission_url}/api/academic/grades/course-lookup`
export const authorise_grade = `${base_admission_url}/api/academic/grades/authorize`
export const authorise_grade_find = `${base_admission_url}/api/academic/grades/authorize/find`
export const authorise_resubmit = `${authorise_grade}/re-submit`
export const grades_catalogID = `${base_admission_url}/api/academic/grades/selected-grade-item`

//Academic Register
export const academic_register = `${base_admission_url}/api/academic/register`
export const student_alert = `${academic_register}/student-alerts`
export const program_popup_dropdown = `${academic_register}/sequenses`
export const offered_courses = `${academic_register}/offered-courses`
export const meeting_course_time = `${academic_register}/offer-meeting-times`
export const co_req_check = `${academic_register}/check-corequisites-validation`
export const refund_policy_check = `${academic_register}/check-refund-policy-validation`
export const repeat_charge_count_check = `${academic_register}/check-repeat-charge-count-validation`

//Registration Academic Register Starts Here
export const register_entry = `${base_admission_url}/api/academic/register`
export const register_dropdown = `${register_entry}/parameters-select-items`
export const register_parameters = `${register_entry}/load-info`
export const register_transcript = `${register_entry}/transcripts`
export const register_drop = `${register_entry}/drops`
export const register_wait = `${register_entry}/waitings`
export const register_studentstatus = `${register_entry}/student-status-select-items`
export const register_studentprogram = `${register_entry}/student-program-select-items`
export const register_blocksequence = `${register_entry}/sequenses`
export const register_transcriptcomments = `${register_entry}/student-status/transcript-comments`
export const register_studentstatusupdate = `${register_entry}/student-status`
export const register_studentprogramupdate = `${register_entry}/student-program`
export const register_offeredcourses = `${register_entry}/offered-courses`
export const register_student_program_dropdown = `${register_entry}/student-programs`
export const register_preReq_validation = `${register_entry}/check-prerequisites-validation`
export const register_check_repeat = `${register_entry}/check-repeats-validation`
export const register_save = `${register_entry}/save`
export const register_schedule_Conflicts = `${register_entry}/check-schedule-conflicts-validation`
export const register_tan_color_check = `${register_entry}/apply-tan-color-registered-course`

//Academic Transfer
export const academic_transfer = `${base_admission_url}/api/academic/transfer`
export const transferred_course_details = `${academic_transfer}/transferred-course-details`
export const transfer_get_select_items = `${academic_transfer}/select-items`
export const transfer_narrative_details = `${academic_transfer}/narrative-details`
export const save_academic_transfer = `${academic_transfer}/save-academic-transfer`
export const location_lookup_details = `${academic_transfer}/location-lookup-details`
export const grades_for_gradecatalog = `${academic_transfer}/grades-for-gradecatalog`
export const previous_courses_for_college = `${academic_transfer}/previous-courses-for-selectedcollege`
export const lookup_master_course = `${academic_transfer}/master-courses-details`
export const checkbox_selection = `${academic_transfer}/checkbox-selection-finalgrade`
export const delete_transfer = `${academic_transfer}/delete-academic-transfer`

// Academic Transcript
export const academic_transcript = `${base_admission_url}/api/academic/transcripts`
export const criteria_select_items = `${academic_transcript}/criteria-select-items`
export const student_select_items = `${academic_transcript}/student-selection-select-items`
export const print_select_items = `${academic_transcript}/print-select-items`
export const student_contacts_addresses = `${academic_transcript}/student-addresses-contacts`

//Offering Registration
export const setup_offering_dropdown = `${base_admission_url}/api/offering/setup/offerings/select-items`
export const setup_offering_generate = `${base_admission_url}/api/offering/setup/offerings/generate`

//Offering tution refunds fees

export const tution_refund_fees = `${base_admission_url}/api/offering/setup/tution-refunds/fees/save-registration-fees`
export const tution_refund_dropdown = `${base_admission_url}/api/offering/setup/tution-refunds/fees/parameters-select-items`

// Cross Listed Coursed
export const cross_listed_courses = `${base_admission_url}/api/offering/cross-listed-courses`
export const cross_listed_dropdown = `${cross_listed_courses}/select-items`
export const cross_listed_master = `${cross_listed_courses}/master-courses`
export const cross_listed_master_course = `${cross_listed_courses}/master-course`
export const cross_listed_course = `${cross_listed_courses}/course`

//Offeringsetuptutionrefunds
export const costcenter_selectdropdown = `${base_admission_url}/api/offering/setup/tution-refunds/cost-centers/parameters-select-items`
export const costcenter_find = `${base_admission_url}/api/offering/setup/tution-refunds/find`
export const costcenter_addtablerecord = `${base_admission_url}/api/offering/setup/tution-refunds/cost-centers/add-cost-table-record`
export const costcenter_addnewterm = `${base_admission_url}/api/offering/setup/tution-refunds/cost-centers/add-new-term`
export const costcenter_setupterm = `${base_admission_url}/api/offering/setup/tution-refunds/cost-centers/setup-term`
export const costtype_costinfo = `${base_admission_url}/api/offering/setup/tution-refunds/cost-types/cost-information`
export const costtype_paramselectitem = `${base_admission_url}/api/offering/setup/tution-refunds/cost-types/parameters-select-items`
export const costcenter_copy = `${base_admission_url}/api/offering/setup/tution-refunds/cost-centers/copy-cost-courses-to-term`
export const costtype_save = `${base_admission_url}/api/offering/setup/tution-refunds/cost-types/save-cost-details`
export const fees_info = `${base_admission_url}/api/offering/setup/tution-refunds/fees/information`
export const fees_paramselectitem = `${base_admission_url}/api/offering/setup/tution-refunds/fees/parameters-select-items`
export const fees_save = `${base_admission_url}/api/offering/setup/tution-refunds/fees/save-registration-fees`
export const fees_update = `${base_admission_url}/api/offering/setup/tution-refunds/fees/revise-registration-fees`
export const fees_delete = `${base_admission_url}/api/offering/setup/tution-refunds/fees/fees-setup`
export const refundgroups_info = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/information`
export const refundgroups_refundperiodinfo = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/refund-periods/information`
export const refundgroups_paramselectitem = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/parameters-select-items`
export const costtype_revise = `${base_admission_url}/api/offering/setup/tution-refunds/cost-types/revise-cost-details`
export const costtype_setup_delete = `${base_admission_url}/api/offering/setup/tution-refunds/cost-types/cost-type-setup`
export const costcenter_costsetup_delete = `${base_admission_url}/api/offering/setup/tution-refunds/cost-centers/cost-setup`
export const refundgroups_save = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/save-semester-refund`
export const refundgroups_revise = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/revise-semester-refund`
export const refundgroups_refundperiods_save = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/refund-periods/save-semester-period`
export const refundgroups_refundperiods_revise = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/refund-periods/revise-semester-period`
export const refundgroups_delete = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups`
export const refundPeriod_delete = `${base_admission_url}/api/offering/setup/tution-refunds/refund-groups/refund-periods`


//Academic Degree
export const academic_studentDegree = `${base_admission_url}/api/academic/degrees/student-degree`

//Master courses

export const master_course = `${base_admission_url}/api/master/courses/master-courses-details`
export const masterCourse_dropdown = `${base_admission_url}/api/master/courses/master-courses/select-items`
export const masterCourse_prequisite = `${base_admission_url}/api/master/courses/prerequisiteof-selected-master-courses`
export const master_course_delete = `${base_admission_url}/api/master/courses/master-courses`
export const allowed_grades_selectItems = `${base_admission_url}/api/master/courses/master-courses/allowed-grades/select-items`
export const allowed_grades_srMasterID = `${base_admission_url}/api/master/courses/master-courses/allowed-grades`
export const allowed_grades_update = `${base_admission_url}/api/master/courses/master-courses/allowed-grades-detail`
export const formula_dept_dropdown = `${base_admission_url}/api/master/courses/master-courses/formula-dept-select-items`
export const formula_id_dropdown = `${base_admission_url}/api/master/courses/master-courses/formula-id-select-items`
export const corequisite_formula = `${base_admission_url}/api/master/courses/master-courses/corequisite-formula`
export const corequisite_formula_update = `${base_admission_url}/api/master/courses/master-courses/corequisite-formula`
export const formula_type_dropdown = `${base_admission_url}/api/master/courses/master-courses/formula-type-select-items`
export const master_course_add = `${base_admission_url}/api/master/courses/master-courses-setup`
export const master_course_update = `${base_admission_url}/api/master/courses/master-courses-setup`
export const equivalent_search = `${base_admission_url}/api/master/courses/master-courses/equivalents-detail`
export const equivalent_delete = `${base_admission_url}/api/master/courses/master-courses/equivalents`
export const corequisite_lookup = `${base_admission_url}/api/master/courses/master-courses/requisite-of-courses`
export const equivalent_lookup = `${base_admission_url}/api/master/courses/master-courses/equivalent-of-courses`
export const add_equivalent = `${base_admission_url}/api/master/courses/master-courses/equivalents`
export const update_equivalent = `${base_admission_url}/api/master/courses/master-courses/equivalents`
export const PreRequisites_dropDown = `${base_admission_url}/api/master/courses/master-courses/pre-requisties-select-items`
export const get_course_equivalent = `${base_admission_url}/api/master/courses/master-courses/equivalents-select-value`
export const get_preRequisite = `${base_admission_url}/api/master/courses/master-courses/pre-requisite-formula`
export const preRequisite_update = `${base_admission_url}/api/master/courses/master-courses/pre-requisite-formula`
export const coRequisite_formula_delete = `${base_admission_url}/api/master/courses/master-courses/corequisite-formula`
export const preRequisite_formula_delete = `${base_admission_url}/api/master/courses/master-courses/pre-requisite-formula`
export const formula_key = `${base_admission_url}/api/master/courses/master-courses/formula-key`;
export const reset_allow_grades = `${base_admission_url}/api/master/courses/master-courses/allowed-grades/`;
//changed Degree Url
export const acadmic_degree_get = `${base_admission_url}/api/academic/degrees/student-degree-get`;
export const acadmic_degree_select = `${base_admission_url}/api/academic/degrees/student-degree-select/select-items`;

//Acadmic Degree
export const acadmic_degree_url = `${base_admission_url}/api/academic/degrees/student-degree`;
export const acadmic_degree_dropdown = `${acadmic_degree_url}/select-items`;
//Offerring Maintenance
export const offering_maintenance_dropdown = `${base_admission_url}/api/offering/maintenance/term-course-selection/select-items`;
export const offering_maintenance_course_offering_dropDown = `${base_admission_url}/api/offering/maintenance/select-items`;
export const degree_thesis_dropdown_url = `${base_admission_url}/api/academic/degrees/thesis-dissertation/select-items`;
export const academic_degree = `${base_admission_url}/api/academic/degrees/save-academic-degrees`;
export const degree_dropdown_url = `${base_admission_url}/api/academic/degrees/student-degree/select-items`
export const student_dissertation_member_save = `${base_admission_url}/api/academic/degrees/save-student-dissertation-member`
export const degree_check_dissertation_exist = `${base_admission_url}/api/academic/degrees/check-dissertation-exist`;
export const academic_thesis_faculty_lookup = `${base_admission_url}/api/academic/degrees/faculty-lookup-details`;
export const academic_thesis_commitee_member_list = `${base_admission_url}/api/academic/degrees/thesis-dissertation/committee-members-list`;
export const academic_thesis_dessertation_save = `${base_admission_url}/api/academic/degrees/save-student-dissertation`;
export const offering_maintenance_bookList = `${base_admission_url}/api/offering/maintenance/book-list`;
export const offering_maintenance_offering = `${base_admission_url}/api/offering/maintenance/term-course-selection`;
export const offering_maintenance_courseMaintenance = `${base_admission_url}/api/offering/maintenance`;
export const offering_maintenance_waitinglist = `${base_admission_url}/api/offering/maintenance/waiting-list`;
export const offering_maintenance_coursefee = `${base_admission_url}/api/offering/maintenance/course-fees`;
export const offering_maintenance_roomlookup = `${base_admission_url}/api/offering/maintenance/room-lookup`;
export const offering_maintenance_roomselect = `${base_admission_url}/api/offering/maintenance/room-lookup/select-items`;
export const offering_maintenance_courseselect = `${base_admission_url}/api/offering/maintenance/course-schedule/select-items`;
export const offering_maintenance_courseschesdule = `${base_admission_url}/api/offering/maintenance/course-schedule`;
export const offering_maintenance_faculty = `${base_admission_url}/api/offering/maintenance/schedule-faculty-assignment`
export const course_management_value = `${base_admission_url}/api/offering/maintenance/course-management-role`
export const offering_maintenance_meeting_room = `${base_admission_url}/api/offering/maintenance/meeting-room`
export const offering_maintenance_room = `${base_admission_url}/api/offering/maintenance/schedule-room-assignment`
export const offering_maintenance_roombuilding = `${base_admission_url}/api/offering/maintenance/room-lookup/building`
export const offering_maintenance_roomtype = `${base_admission_url}/api/offering/maintenance/room-lookup/roomtype`
export const offering_maintenance_facultyAssigned = `${base_admission_url}/api/offering/maintenance/faculty-assigned`
export const offering_maintenance_schedulefaculty = `${base_admission_url}/api/offering/maintenance/schedule-faculty-Assignment`
export const offering_maintenance_waitget = `${base_admission_url}/api/offering/maintenance/waiting-list`
export const offering_maintenance_courseget = `${base_admission_url}/api/offering/maintenance/course-fees`
export const offering_maintenance_bookput = `${base_admission_url}/api/offering/maintenance/book-list`
export const offering_maintenance_bookgrid = `${base_admission_url}/api/offering/maintenance/book-list`
export const academic_thesis_delete_commitee_member = `${base_admission_url}/api/academic/degrees/student-dissertation-member`;
export const offering_maintenance_coursefeelist = `${base_admission_url}/api/offering/maintenance/course-fees/select-items`
export const offering_maintenance_bookdelete = `${base_admission_url}/api/offering/maintenance/book-list`
export const offering_maintenance_waitdelete = `${base_admission_url}/api/offering/maintenance/waiting-list`
export const offering_maintenance_coursedelete = `${base_admission_url}/api/offering/maintenance/course-fees`
export const offering_maintenance_maintenanceput = `${base_admission_url}/api/offering/maintenance`
export const offering_maintenance_courseput = `${base_admission_url}/api/offering/maintenance/course-fees`
export const offering_maintenance_deletecourse = `${offering_maintenance_courseMaintenance}/delete-course`
export const offering_maintenance_timeslot = `${offering_maintenance_courseMaintenance}/schedule-slot-details`
export const offering_course_initial = `${offering_maintenance_maintenanceput}/course-maintenance`
export const offering_course_get_dates = `${base_admission_url}/api/offering/maintenance/course-maintenance`
export const duplicatefaculty_details = `${base_admission_url}/api/offering/maintenance/faculty-schedule-Conflict`
export const offering_maintenance_meeting_room_delete = `/api/offering/maintenance/room-schedule`
export const offering_maintenance_facultyAssigned_delete = `/api/offering/maintenance/faculty-assigned`
export const offering_maintenance_facultyAssigned_Add = `/api/offering/maintenance/faculty-assigned`
export const enableLms_select = `${base_admission_url}/api/offering/maintenance/integrationSyncWithLms/select-items`
export const check_IsLmsEnable = `${base_admission_url}/api/offering/maintenance/integration/checkIsLmsEnable`

//Academic maintainance 
export const maintainance_studentID_regStatus = `${base_admission_url}/api/academic/maintenance/transcript`
export const maintainance_courses_dropdown = `${maintainance_studentID_regStatus}/add/select-items`
export const maintainance_unofficial_studentID_regStatus = `${base_admission_url}/api/academic/maintenance/unofficial`
export const maintainance_unofficial_studentID = `${base_admission_url}/api/academic/maintenance/dropped`
export const maintenance_transcript_save = `${maintainance_studentID_regStatus}/save`;
export const maintenance_transcript_update = `${maintainance_studentID_regStatus}/update`;
export const maintenance_nontraditional = `${base_admission_url}/api/academic/maintenance/nontraditional`;
export const maintenance_catalog_course_lookup_details = `${base_admission_url}/api/academic/maintenance/catalog-course-lookup-details`;
export const maintenance_nontraditional_save = `${maintenance_nontraditional}/save`;
export const maintenance_nontraditional_update = `${maintenance_nontraditional}/update`;
export const maintenance_transcript_dalete = `${maintainance_studentID_regStatus}/delete`;
export const maintenance_nontraditional_registration_dropdown = `${base_admission_url}/api/academic/maintenance/nontraditional/add/select-items`;
export const maintenance_nontraditional_dalete = `${base_admission_url}/api/academic/maintenance/nontraditional/delete`;
export const maintainance_cohort_dropdown = `${base_admission_url}/api/academic/maintenance/cohort/select-items`;
export const maintenance_get_narrative_grade = `${base_admission_url}/api/academic/maintenance/narrative-grade`;
export const maintenance_get_rebuild_repeats = `${base_admission_url}/api/academic/maintenance/rebuildrepeat`;
export const maintenance_cohortoffering_lookup = `${base_admission_url}/api/academic/maintenance/cohort-offering-lookup-details`;
export const grades_for_gradecatalog_maintenance = `${base_admission_url}/api/academic/maintenance/grades-for-gradecatalog`
export const gradesGroups_maintenance = `${base_admission_url}/api/academic/maintenance/grades-basedOn-selectedCourse`


// Academic Schedule
export const academic_schedule = `${base_admission_url}/api/academic/schedule`
export const academic_schedule_get_select_item = `${academic_schedule}/select-items`

//Academic mass student registration 

export const mass_student_registration = `${base_admission_url}/api/academic/mass-student-registration`
export const registration_move = `${mass_student_registration}/move`
export const search_student_register = `${mass_student_registration}/student-search`
export const mass_student_registration_dropdown = `${search_student_register}/select-items`
export const test_scores_dropdown = `${search_student_register}/test-scores`
export const terms_dropdown = `${mass_student_registration}/terms`
export const get_students_move = `${registration_move}/students`
export const get_students_drop = `${mass_student_registration}/drop/students`
export const get_students_dropCourse = `${mass_student_registration}/drop/courses`
export const get_students_view = `${mass_student_registration}/view/students`
export const drop_students = `${mass_student_registration}/drop-student`
export const drop_course = `${mass_student_registration}/drop-course`
export const register_student = `${mass_student_registration}/register-student`
export const mass_student_check = `${mass_student_registration}/term-check`


//Degree Audit: Student Evaluation

export const student_evaluation_term_dropdown = `${base_admission_url}/api/degree-audit/student-evaluation/terms`
export const student_evaluation_load_program_list = `${base_admission_url}/api/degree-audit/student-evaluation/program-search`
export const student_evaluation_add_program = `${base_admission_url}/api/degree-audit/student-evaluation/audit-program`
export const student_evaluation_audit_program_list = `${base_admission_url}/api/degree-audit/student-evaluation/audit-programs`
export const student_evaluation_delete_audit_program = `${base_admission_url}/api/degree-audit/student-evaluation/audit-program`
export const student_evaluation_update_audit_program = `${base_admission_url}/api/degree-audit/student-evaluation/audit-program`
export const student_evaluation_programs_list = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-program`
export const student_evaluation_evaluate_program_details = `${base_admission_url}/api/degree-audit/student-evaluation/evaluate-program-details`
export const student_evaluation_program_dropdown = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-program/select-items`
export const student_evaluation_update_programs = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-program`
export const student_evaluation_requirement_list = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-requirement`
export const student_evaluation_group_list = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group`
export const student_evaluation_group_in_req_list = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group-in-requirement`
export const student_evaluation_add_requirement = `${base_admission_url}/api/degree-audit/student-evaluation/audit-requirement`
export const student_evaluation_delete_requirement = `${base_admission_url}/api/degree-audit/student-evaluation/audit-requirement`
export const student_evaluation_save_requirement = `${base_admission_url}/api/degree-audit/student-evaluation/audit-requirement`
export const student_evaluation_audit_group = `${base_admission_url}/api/degree-audit/student-evaluation/audit-group`
export const student_evaluation_add_sql = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/sql`
export const student_evaluation_add_sql_update = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/sql`
export const student_evaluation_delete_grp = `${base_admission_url}/api/degree-audit/student-evaluation/audit-group`
export const student_evaluation_add_course_list = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-course`
export const student_evaluation_course_in_group = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-course-in-group`
export const student_evaluation_audit_course_select = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-course/select-items`
export const student_evaluation_delete_course = `${base_admission_url}/api/degree-audit/student-evaluation/audit-course`
export const student_evaluation_add_course_page = `${base_admission_url}/api/degree-audit/student-evaluation/audit-course`
export const student_evaluation_academic_degree = `${base_admission_url}/api/degree-audit/student-evaluation/academic-degree`
export const student_evaluation_patch_course = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-course`
export const student_evaluation_patch_requirement = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-requirement`
export const student_evaluation_patch_group = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group`
export const student_evaluation_eval = `${base_admission_url}/api/degree-audit/student-evaluation/evaluate-program-details`
export const student_evaluation_req_formula_dropdown = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-requirement/formula/select-items`

export const student_evaluation_groupformula_dropdown = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/formula/select-items`
export const student_evaluation_groupdept_dropdown = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/formula/dept-select-items`
export const student_evaluation_grouptype_dropdown = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/formula/type-select-items`
export const student_evaluation_groupid_dropdown = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/formula/id-select-items`
export const student_evaluation_formula_key = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/formula/key`
export const student_evaluation_get_formula = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/formula`
export const student_evaluation_update_formula = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-group/formula`
export const student_evaluation_update_reqformula = `${base_admission_url}/api/degree-audit/student-evaluation/student-audit-requirement/formula`
// Print Offering
export const print_offering = `${base_admission_url}/api/offering/print-offering/select-items`
export const student_evaluation_save_group = `${base_admission_url}/api/degree-audit/student-evaluation/audit-group`
export const student_evaluation_save_course = `${base_admission_url}/api/degree-audit/student-evaluation/audit-course`

// Time Slot References
export const timeSlotReferences_data = `${base_admission_url}/api/offering/time-slot-references`
export const addTimeSlot_data = `${base_admission_url}/api/offering/save-timeslot-reference`
export const delete_timeslot = `${base_admission_url}/api/offering/time-slot`
export const time_slot_references_seletitems = `${base_admission_url}/api/offering/time-slot/select-items`

//Degree Audit Setup
export const get_audit_checkbox_config = `${base_admission_url}/api/degree-audit/degree-audit-setup/audit-configuration`
export const save_copy_audit_save = `${base_admission_url}/api/degree-audit/degree-audit-setup/move-audit-setup-data`
export const update_audit_checkbox_config = `${base_admission_url}/api/degree-audit/degree-audit-setup/audit-configuration`
export const get_load_popup_check = `${base_admission_url}/api/degree-audit/degree-audit-setup/audit-setup-data-load`
export const get_programs_list = `${base_admission_url}/api/degree-audit/degree-audit-setup/programs`
export const audit_addgroups = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups`
export const add_program = `${base_admission_url}/api/degree-audit/degree-audit-setup/programs`
export const add_requirement = `${base_admission_url}/api/degree-audit/degree-audit-setup/requirements`
export const get_requirement = `${base_admission_url}/api/degree-audit/degree-audit-setup/requirements`
export const delete_auditprogram = `${base_admission_url}/api/degree-audit/degree-audit-setup/programs`
export const get_groups = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups`
export const addcourse_selectitems = `${base_admission_url}/api/degree-audit/degree-audit-setup/courses/select-items`
export const addcourse_postapi = `${base_admission_url}/api/degree-audit/degree-audit-setup/courses`
export const course_getapi = `${base_admission_url}/api/degree-audit/degree-audit-setup/courses`
export const requirements_program = `${base_admission_url}/api/degree-audit/degree-audit-setup/requirements-in-program`
export const groups_post = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups`
export const addcourse_get = `${base_admission_url}/api/degree-audit/degree-audit-setup/course-in-group`
export const add_requirement_get = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups-in-requirement`
export const add_requirement_post = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups-in-requirement`
export const degree_audit_courses_patch = `${base_admission_url}/api/degree-audit/degree-audit-setup/courses`
export const get_group_in_requirement = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups-in-requirement`
export const add_gropup_in_requirement = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups-in-requirement`
export const degree_audit_groups_patch = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups`
export const degree_audit_course_in_grp_del = `${base_admission_url}/api/degree-audit/degree-audit-setup/course-in-group`
export const delete_auditreq = `${base_admission_url}/api/degree-audit/degree-audit-setup/requirements`
export const delete_auditreq_auditgrpreq = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups-in-requirement`
export const courses_delete = `${base_admission_url}/api/degree-audit/degree-audit-setup/courses`
export const degree_audit_setup_add_sql = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/sql`
export const degree_audit_setup_add_sql_update = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/sql`
export const courses_in_grp = `${base_admission_url}/api/degree-audit/degree-audit-setup/course-in-group`

export const degree_audit_update_reqformula = `${base_admission_url}/api/degree-audit/degree-audit-setup/requirements/formula`
// export const degree_audit_req_formula_dropdown = `${base_admission_url}/api/degree-audit/degree-audit-setup/requirements/formula/select-items`

export const degree_audit_groupformula_dropdown = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/formula/select-items`
export const degree_audit_groupdept_dropdown = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/formula/dept-select-items`
export const degree_audit_grouptype_dropdown = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/formula/type-select-items`
export const degree_audit_groupid_dropdown = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/formula/id-select-items`
export const degree_audit_formula_key = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/formula/key`
// export const degree_audit_get_formula = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/formula`
export const degree_audit_update_formula = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/formula`
export const degree_audit_grouptexas_dropdown = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/select-items`
export const degree_audit_group_check_existence = `${base_admission_url}/api/degree-audit/degree-audit-setup/groups/rcs-info`

//Schedule Registration
export const scheduleRegistration_term = `${base_admission_url}/api/schedule-registration/terms`
export const scheduleRegistration_search = `${base_admission_url}/api/schedule-registration/find`
export const addSchedule_dropDown = `${base_admission_url}/api/schedule-registration/college-level`
export const add_scheduleRegistration = `${base_admission_url}/api/schedule-registration`
export const delete_scheduleRegistration = `${base_admission_url}/api/schedule-registration`

//Attendance
export const couseOffering_selectItems = `${base_admission_url}/api/attendance/data-entry/parameters-select-items`
export const courseOffering_search = `${base_admission_url}/api/attendance/data-entry/find`
export const dataEntry_studentList = `${base_admission_url}/api/attendance/data-entry/students-information`
export const dataEntry_list = `${base_admission_url}/api/attendance/data-entry/students-attendance`
export const attendance_add = `${base_admission_url}/api/attendance/data-entry/student-attendance-status`
export const attendance_update = `${base_admission_url}/api/attendance/data-entry/student-attendance-status`
export const course_search = `${base_admission_url}/api/attendance/date-range-entry/courses`
export const dataRangeEntry_studentList = `${base_admission_url}/api/attendance/date-range-entry/students-attendance`
export const dataRangeEntry_studentlookup = `${base_admission_url}/api/attendance/date-range-entry/students-lookup`
export const dataRangeEntry_schedules = `${base_admission_url}/api/attendance/date-range-entry/room-schedules`
export const dataRangeEntry_delete = `${base_admission_url}/api/attendance/date-range-entry/student-attendance`
export const load_attendance = `${base_admission_url}/api/attendance/date-range-entry/load-attendance`
export const dataRangeEntry_post = `${base_admission_url}/api/attendance/date-range-entry/students-mass-attendance`


//Catalog Maintainance
export const catalogcourses_selectterm = `${base_admission_url}/api/catalog/maintenance/catalog-courses/select-items`
export const catalogcourses_catalog = `${base_admission_url}/api/catalog/maintenance/catalog`
export const catalogcourses_catalogcourses = `${base_admission_url}/api/catalog/maintenance/catalog`
export const catalogcourses_catalogpostdata = `${base_admission_url}/api/catalog/maintenance/catalog`
export const catalogcourses_catalogputdata = `${base_admission_url}/api/catalog/maintenance/catalog`
export const catalogcourses_lookup = `${base_admission_url}/api/catalog/maintenance/catalog-lookup-details`
export const catalogcourses_masterlookup = `${base_admission_url}/api/catalog/maintenance/master-course`
export const catalog_validation = `${base_admission_url}/api/catalog/maintenance/master-course`

//Copy Catalog 
export const copycatalog_entry = `${base_admission_url}/api/catalog/copy-catalog`
export const copycatalog_term = `${copycatalog_entry}/select-items`

// Billing: Maintanance
export const maintanance_transaction_dropdown = `${base_admission_url}/api/billing/maintenance/show-select-items`
export const maintanance_get_billing_ledger = `${base_admission_url}/api/billing/maintenance/student-billing-ledger`
export const maintanance_get_non_billing_ledger = `${base_admission_url}/api/billing/maintenance/student-non-billing-ledger`
export const maintanance_put_billing_void = `${base_admission_url}/api/billing/maintenance/void`
export const maintanance_get_billing_userdefined = `${base_admission_url}/api/billing/maintenance/student-user-defined`
export const maintanance_get_billing_userdefined_fields = `${base_admission_url}/billing/maintenance/user-defined-fields`
export const maintanance_get_billing_userdefined_dropdown = `${base_admission_url}/api/billing/maintenance/user-defined-select-items`
export const maintanance_put_billing_userdefined = `${base_admission_url}/api/billing/maintenance/student-user-defined`
export const maintanance_get_billing_ledger_dropdown = `${base_admission_url}/api/billing/maintenance/select-items`
export const maintanance_get_billing_transdoc_dropdown = `${base_admission_url}/api/billing/maintenance/billing-trans-docs`
export const maintanance_get_billing_non_transdoc_dropdown = `${base_admission_url}/api/billing/maintenance/non-billing-trans-docs`
export const maintanance_get_payment_plans = `${base_admission_url}/api/billing/maintenance/payment-plans`
export const maintanance_post_exchange_rates = `${base_admission_url}/api/billing/maintenance/exchange-rate`
export const maintanance_post_billing_ledger = `${base_admission_url}/api/billing/maintenance/ledger`
export const address_tab_post_copy_history = `${base_admission_url}/api/billing/maintenance/address-copy-to-history`
export const address_tab_put_address = `${base_admission_url}/api/billing/maintenance/student-billing-address`
export const address_tab_get_address = `${base_admission_url}/api/billing/maintenance/student-billing-address`
export const student_status_information = `${base_admission_url}/api/billing/maintenance/student-billing-status`
export const student_more_tab = `${base_admission_url}/api/billing/maintenance/more-select-items`
export const more_tab_userdefined_select = `${base_admission_url}/api/financial-aid/maintenance/more/user-defined-select-items`
export const userdefined_update = `${base_admission_url}/api/financial-aid/maintenance/more/user-defined`
export const get_userdefined_data = `${base_admission_url}/api/financial-aid/maintenance/more/user-defined`
export const get_next_reference = `${base_admission_url}/api/billing/batch/student/next-reference-num`


//Batches
export const batch_docsum_tab = `${base_admission_url}/api/billing/batch/doc-sum`
export const batch_nonStudent = `${base_admission_url}/api/billing/batch/non-student`
export const batch_selectIntems = `${base_admission_url}/api/billing/batch/student/select-items`
export const batch_summaryTab = `${base_admission_url}/api/billing/batch/summary`
export const parameter_selectItems = `${base_admission_url}/api/billing/batch/parameters-select-items`
export const batch_tab = `${base_admission_url}/api/billing/batch/list`
export const exchangeRate_data = `${base_admission_url}/api/billing/batch/non-student/exchange-rates`
export const add_batch = `${base_admission_url}/api/billing/batch/batch-master`
export const user_details = `${base_admission_url}/api/billing/batch/user-details`
export const student_batch = `${base_admission_url}/api/billing/batch/student/billingbatch`
export const search_transactionType = `${base_admission_url}/api/billing/batch/student/transaction-doc`
export const add_student_transaction = `${base_admission_url}/api/billing/batch/student/batch`
export const update_student_transaction = `${base_admission_url}/api/billing/batch/student/batch`
export const add_nonstudent_transaction = `${base_admission_url}/api/billing/batch/non-student/batch`
export const update_nonstudent_transaction = `${base_admission_url}/api/billing/batch/non-student/batch`
export const distribute_batch = `${base_admission_url}/api/billing/batch/distribute`
export const delete_batch = `${base_admission_url}/api/billing/batch/batch-detail`

export const address_tab_select_items = `${base_admission_url}/api/billing/maintenance/address-select-items`

// Billing: Authorize
export const authorize_names_selectitems = `${base_admission_url}/api/billing/authorize/names-select-items`
export const authorize_nameslist = `${base_admission_url}/api/billing/authorize/names-list`
export const authorize_results_process = `${base_admission_url}/api/billing/authorize/authorize-student`



// Billing: Cashier Entry
export const cashier_entry_selectitems = `${base_admission_url}/api/billing/cashier-entry/select-items`
export const batches_batchmaster = `${base_admission_url}/api/billing/cashier-entry/batches/batch-master`
export const batch_entries_list = `${base_admission_url}/api/billing/cashier-entry/batches/batch-entries-list`
export const balancetab_info = `${base_admission_url}/api/billing/cashier-entry/balance-tab-information`
export const authorize_info = `${base_admission_url}/api/billing/cashier-entry/authorize/registered-course-work-list`
export const batch_entry_select_items = `${base_admission_url}/api/billing/cashier-entry/batches/batch-entry/select-items`
export const authorize_student = `${base_admission_url}/api/billing/authorize/authorize-student`
export const cashier_entry_batch_master = `${base_admission_url}/api/billing/cashier-entry/batches/batch-master`
export const cashier_entry_batchentry_save = `${base_admission_url}/api/billing/cashier-entry/batches/batch-entry/save`
export const cashier_entry_batchentry_currency = `${base_admission_url}/api/billing/cashier-entry/batches/batch-entry/exchange-rate-info`
export const cashier_entry_batchentry_delete = `${base_admission_url}/api/billing/cashier-entry/batches/batch-entry`




//Billing-Accounting: post options-general ledger
export const post_options_general_ledger_select_items = `${base_admission_url}/api/billing/billing-accounting-post-options/general-ledger/select-items`
export const post_termID = `${base_admission_url}/api/billing/billing-accounting-post-options/general-ledger/check-post`
export const post_options_general_ledger_post_options = `${base_admission_url}/api/billing/billing-accounting-post-options/general-ledger/options`
export const general_ledger_post_glPostingID = `${base_admission_url}/api/billing/billing-accounting-post-options/general-ledger/post`

//Billing:Accounting
export const general_ledger_gl_output_type = `${base_admission_url}/api/billing/accounting/recreate/general-ledger/gl-output-type`
export const general_ledger_post = `${base_admission_url}/api/billing/billing-accounting-post-options/general-ledger/post`

//Billing: Accounting Payable
export const accounts_payable_select_items = `${base_admission_url}/api/billing/billing-accounting-post-options/accounts-payable/select-items`
export const accounts_payable_post_info_select_items = `${base_admission_url}/api/billing/billing-accounting-post-options/accounts-payable/post-info-select-items`
export const accounts_payable_post_info = `${base_admission_url}/api/billing/billing-accounting-post-options/accounts-payable/post-info`

//Billing: Recreate - Accounting Payable
export const recreate_accounts_payable_history = `${base_admission_url}/api/billing/accounting/recreate/accounts-payable/history`
export const recreate_accounts_payable_select_items = `${base_admission_url}/api/billing/accounting/recreate/accounts-payable/select-items`
export const recreate_accounts_payable_recreate_process = `${base_admission_url}/api/billing/accounting/recreate/accounts-payable/recreate-process`

//Billing:Recreate Genaral ledger
export const general_ledger_history = `${base_admission_url}/api/billing/accounting/recreate/general-ledger/history`
export const general_ledger_recreate = `${base_admission_url}/api/billing/accounting/recreate/general-ledger/recreate`


// Billing:PaymentPlan

export const payment_plans_grid = `${base_admission_url}/api/billing/payment-plans`
export const history_grid = `${base_admission_url}/api/billing/payment-plans/history`
export const payment_schedule_grid = `${base_admission_url}/api/billing/payment-plans/schedules`
export const payment_details_grid = `${base_admission_url}/api/billing/payment-plans/payment-details`
export const addpayment_selectitems = `${base_admission_url}/api/billing/payment-plans/maintenance/select-items`
export const payment_plan_type_details = `${base_admission_url}/api/billing/payment-plans/maintenance/payment-plan-type-details`
export const payment_plan_add = `${base_admission_url}/api/billing/payment-plans/add`
export const payment_plan_Delete = `${base_admission_url}/api/billing/payment-plans`


// Billing Payment Plans Setup
export const payment_plan_setup_grid_binding = `${base_admission_url}/api/billing/payment-plans-setup`
export const payment_plan_setup_post = `${base_admission_url}/api/billing/payment-plans-setup`
export const payment_plan_delete = `${base_admission_url}/api/billing/payment-plans-setup`
export const payment_plan_setup_select_items = `${base_admission_url}/api/billing/payment-plans-setup/select-items`
export const payment_plans_number_ofPayments_select_items = `${base_admission_url}/api/billing/payment-plans-setup/select-items/number-of-payments`

//Alter Plan
export const alterplan_criteria_select = `${base_admission_url}/api/billing/payment-plans/alter-plan/student-criteria/parameters-select-items`
export const planMap_data = `${base_admission_url}/api/billing/payment-plans/alter-plan/plan-map/list`
export const addplanmap_select = `${base_admission_url}/api/billing/payment-plans/alter-plan/plan-map/select-items`
export const criteria_data = `${base_admission_url}/api/billing/payment-plans/alter-plan/student-criteria/alter-list`
export const delete_plan = `${base_admission_url}/api/billing/payment-plans/alter-plan/plan-map/data`
export const add_alter_plan = `${base_admission_url}/api/billing/payment-plans/alter-plan/plan-map/data`
export const update_alter_plan = `${base_admission_url}/api/billing/payment-plans/alter-plan/plan-map/data`
export const delete_criteria = `${base_admission_url}/api/billing/payment-plans/alter-plan/student-criteria/payment-plan`
export const process_data = `${base_admission_url}/api/billing/payment-plans/alter-plan/process/list`

//change Address
export const change_address_copy = `${base_admission_url}/api/billing/change-address/address-list-select-items`
export const change_address_gridList = `${base_admission_url}/api/billing/change-address/address-list`
export const payment_plans_trans_doc = `${base_admission_url}/api/billing/payment-plans-setup/trans-docs`
export const payment_plans_trans_doc_post = `${base_admission_url}/api/billing/payment-plans-setup/trans-docs`
export const payment_plans_fee = `${base_admission_url}/api/billing/payment-plans-setup/fees`
export const payment_plans_fee_post = `${base_admission_url}/api/billing/payment-plans-setup/fees`
export const delete_address = `${base_admission_url}/api/billing/change-address/address-list`
export const post_copy = `${base_admission_url}/api/billing/change-address/address-list/copy`
export const add_address = `${base_admission_url}/api/billing/change-address/address-list`
export const change_address_edit_data = `${base_admission_url}/api/billing/change-address/address-list`

// FinancialAid: Yearly setup
export const financialAid_yearlySetup_PKGTerm = `${base_admission_url}/api/financial-aid/setup/yearly-setup/pkg-term`
export const financialAid_yearlySetup_PKGTerm_limit = `${base_admission_url}/api/financial-aid/setup/yearly-setup/pkg-term/limit`

//Academic Years
export const yearly_setup_acad_year = `${base_admission_url}/api/financial-aid/setup/yearly-setup/acad-year`
export const yearly_setup_acad_year_AddAcademicYear = `${base_admission_url}/api/financial-aid/setup/yearly-setup/acad-year`
export const yearly_setup_acad_year_AddAcademicYear_patch = `${base_admission_url}/api/financial-aid/setup/yearly-setup/acad-year`
export const yearly_setup_acad_year_AddAcademicYear_delete = `${base_admission_url}/api/financial-aid/setup/yearly-setup/acad-year`
//Financial Years
export const get_academicYear_fa_year = `${base_admission_url}/api/financial-aid/setup/yearly-setup/fa-year`
export const post_fa_year = `${base_admission_url}/api/financial-aid/setup/yearly-setup/fa-year`
export const patch_fa_year = `${base_admission_url}/api/financial-aid/setup/yearly-setup/fa-year`
export const delete_fa_year = `${base_admission_url}/api/financial-aid/setup/yearly-setup/fa-year`


//Financial Years: Yearly setup

export const financialAid_yearlySetup_distribution_term = `${base_admission_url}/api/financial-aid/setup/yearly-setup/terms-select-item`
export const financialAid_yearlySetup_awardType = `${base_admission_url}/api/financial-aid/setup/yearly-setup/award-type-select-item`
export const financialAid_yearlySetup_limit_delete = `${base_admission_url}/api/financial-aid/setup/yearly-setup/pkg-term/limit`
export const setup_financial_limits_term_calendar = `${base_admission_url}/api/financial-aid/setup/yearly-setup/financial-limits-term-calendar`
export const financial_lyearlySetup_term = `${base_admission_url}/api/financial-aid/setup/yearly-setup/terms`
export const financial_Setup_term_calendarId = `${base_admission_url}/api/financial-aid/setup/yearly-setup/term`
export const financial_Setup_term_limit = `${base_admission_url}/api/financial-aid/setup/yearly-setup/term-limit`
export const financial_Setup_budget_selectItems = `${base_admission_url}/api/financial-aid/setup/yearly-setup/budget/select-item`
export const financial_Setup_budget_cost_finacial = `${base_admission_url}/api/financial-aid/setup/yearly-setup/budget`
export const financial_Setup_put_term_limit = `${base_admission_url}/api/financial-aid/setup/yearly-setup/term-limit`





export const fa_year_limit = `${base_admission_url}/api/financial-aid/setup/yearly-setup/fa-year/limit`
export const acad_year_limit = `${base_admission_url}/api/financial-aid/setup/yearly-setup/acad-year-limit`
export const acad_year_limit_budget_post = `${base_admission_url}/api/financial-aid/setup/yearly-setup/budget`
export const acad_year_limit_budget_patch = `${base_admission_url}/api/financial-aid/setup/yearly-setup/budget`
export const acad_year_limit_budget_delete = `${base_admission_url}/api/financial-aid/setup/yearly-setup/budget`

//Award Reference
export const award_ref_selectitems = `${base_admission_url}/api/financial-aid/setup/award-reference/select-items`
export const award_ref_yearly_amounts_selectitems = `${base_admission_url}/api/financial-aid/setup/award-reference/amounts-yearly-cod/select-items`
export const award_ref_delete = `${base_admission_url}/api/financial-aid/setup/award-reference/award-reference`
export const award_ref_yearly_cod_delete = `${base_admission_url}/api/financial-aid/setup/award-reference/yearly-cod`
export const award_ref_default_amountdelete = `${base_admission_url}/api/financial-aid/setup/award-reference/default-award-amount`
export const award_ref_yearlycod_acadYear = `${base_admission_url}/api/financial-aid/setup/award-reference/yearly-cod/academic-year`
export const award_ref_award_type = `${base_admission_url}/api/financial-aid/setup/award-reference/check-award-type-exists`
export const award_ref_award_type_defaultawardAmount = `${base_admission_url}/api/financial-aid/setup/award-reference/default-award-amount`
export const award_ref_yearly_cod_parameter = `${base_admission_url}/api/financial-aid/setup/award-reference/yearly-cod-parameter`
export const update_fees = `${base_admission_url}/api/financial-aid/setup/award-reference/yearly-cod/update-fees`
export const award_ref_gpa_group_restrictions = `${base_admission_url}/api/financial-aid/setup/award-reference/gpa-group-restrictions`
export const award_ref_gpa_group_governmental_restrictions = `${base_admission_url}/api/financial-aid/setup/award-reference/governmental-sap`
export const award_ref_gpa_group_institutional_restrictions = `${base_admission_url}/api/financial-aid/setup/award-reference/institutional-sap`
export const award_ref_restrict_awards = `${base_admission_url}/api/financial-aid/setup/award-reference/restrict-awards`
export const award_ref_entry = `${base_admission_url}/api/financial-aid/setup/award-reference/award-ref-entry`

// FA Maintenance ISIR
export const fa_isir_base = `${base_admission_url}/api/financial-aid/maintenance/isir`
export const isir_select_items = `${fa_isir_base}/select-items`
export const isir_comment_codes = `${fa_isir_base}/comment-codes`
export const isir_set_comment_resolved = `${fa_isir_base}/set-comments-resolved`
export const isir_financial = `${fa_isir_base}/financial-isir`
export const isir_financial_custom = `${fa_isir_base}/financial-isar-custom`
export const isir_financial_grid_2_3 = `${fa_isir_base}/comment-codes-custom-fields`
export const isir_financial_grid1 = `${fa_isir_base}/financial-status/isir-list`
export const isir_custom_import = `${fa_isir_base}/custom-isir`
export const isir_financial_grid_new = `${fa_isir_base}/financial-status/isir-list-new`

// FinancialAid: Maintenance > status
export const status_gridList = `${base_admission_url}/api/financial-aid/maintenance/statuses`
export const mpn_gridList = `${base_admission_url}/api/financial-aid/maintenance/mpn`
export const counselings_gridList = `${base_admission_url}/api/financial-aid/maintenance/counselings`
export const creditapp_gridList = `${base_admission_url}/api/financial-aid/maintenance/credit-app`
export const awardusage_gridList = `${base_admission_url}/api/financial-aid/maintenance/award-usage`
export const informed_borrowing_gridList = `${base_admission_url}/api/financial-aid/maintenance/informed-borrowing-information`
export const borrower_select_item = `${base_admission_url}/api/financial-aid/maintenance/status/borrower/select-items`
export const get_financialStatus_details = `${base_admission_url}/api/financial-aid/maintenance/status/select-items`
export const save_financialStatus_details = `${base_admission_url}/api/financial-aid/maintenance/status`
export const update_financialStatus_details = `${base_admission_url}/api/financial-aid/maintenance/status`
export const delete_financialStatus_details = `${base_admission_url}/api/financial-aid/maintenance/status`
export const mpn_select_items = `${base_admission_url}/api/financial-aid/maintenance/mpn/select-items`
export const delete_financialStatus_mpn = `${base_admission_url}/api/financial-aid/maintenance/mpn`
export const save_mpn = `${base_admission_url}/api/financial-aid/maintenance/mpn`
export const update_mpn = `${base_admission_url}/api/financial-aid/maintenance/mpn`
export const save_exitcouseling = `${base_admission_url}/api/financial-aid/maintenance/counseling`
export const update_exitcouseling = `${base_admission_url}/api/financial-aid/maintenance/counseling`
export const save_creditplus = `${base_admission_url}/api/financial-aid/maintenance/credit-plus`
export const update_creditplus = `${base_admission_url}/api/financial-aid/maintenance/credit-plus`
export const counseling_select_items = `${base_admission_url}/api/financial-aid/maintenance/counseling/select-items`
export const delete_financialStatus_counseling = `${base_admission_url}/api/financial-aid/maintenance/counseling`
export const credit_select_item = `${base_admission_url}/api/financial-aid/maintenance/credit-plus/select-items`
export const delete_financialStatus_CreditPlus = `${base_admission_url}/api/financial-aid/maintenance/credit-plus`
export const get_academicyearDependency = `${base_admission_url}/api/financial-aid/maintenance/status/academic-year-dependencies`
export const get_fa_yearbudgets = `${base_admission_url}/api/financial-aid/maintenance/status/financial-aid-year-budgets`
export const get_costLabels = `${base_admission_url}/api/financial-aid/maintenance/status/cost-of-attendance/select-items`
export const copy_borrowerAwardPerType = `${base_admission_url}/api/financial-aid/maintenance/status/financial-borrower`


// FinancialAid: Maintenance > more
export const add_award_usage_limit = `${base_admission_url}/api/financial-aid/maintenance/award-usage-limit`
export const update_award_usage_limit = `${base_admission_url}/api/financial-aid/maintenance/award-usage-limit`
export const award_usage_select_item = `${base_admission_url}/api/financial-aid/maintenance/award-usage-limit/select-items`
export const delete_award_usage_limit = `${base_admission_url}/api/financial-aid/maintenance/award-usage-limit`


export const more_tab = `${base_admission_url}/api/financial-aid/maintenance/more/academic-progress`
export const more_tab_billing = `${base_admission_url}/api/financial-aid/maintenance/more/billing`
export const more_tab_address = `${base_admission_url}/api/financial-aid/maintenance/more/address`
export const more_tab_add_report = `${base_admission_url}/api/financial-aid/maintenance/more/reports/select-items`
export const more_tab_acadmecId = `${base_admission_url}/api/financial-aid/maintenance/more/reports/financial-aid-year`
export const more_tab_financial_awards = `${base_admission_url}/api/financial-aid/maintenance/awards/financial-awards`
export const award_tab = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-award-header`
export const award_select_items = `${base_admission_url}/api/financial-aid/maintenance/awards/financial-aid-awards/select-items`

export const loanExportHistoryGrid = `${base_admission_url}/api/financial-aid/maintenance/awards/loan-export-history`
export const codExportAwardsGrid = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-export-awards-history`
export const coddisbexporthistoryGrid = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-export-disb-history`

export const loan_Export_History_Grid = `${base_admission_url}/api/financial-aid/maintenance/awards/loan-export-history`
export const cod_Export_Awards_Grid = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-export-awards-history`
export const cod_disb_export_history_Grid = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-export-disb-history`
export const cod_disb_import_history_Grid = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-import-disb-history`
export const cod_import_award_history_Grid = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-import-awards-history`
export const loan_import_history = `${base_admission_url}/api/financial-aid/maintenance/awards/loan-import-history`
export const delete_awards_finincialawardId = `${base_admission_url}/api/financial-aid/maintenance/awards/financial-awards`
export const award_detail_post = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-award-header/detail`

export const award_type_details = `${base_admission_url}/api/financial-aid/maintenance/awards/financial-awards/type-details`
export const award_valid_limits = `${base_admission_url}/api/financial-aid/maintenance/awards/financial-awards/validate-limits`
export const add_awards = `${base_admission_url}/api/financial-aid/maintenance/awards/financial-awards`
export const cod_award_select_items = `${base_admission_url}/api/financial-aid/maintenance/awards/cod-award-header/select-items`


// Holds
export const business_holds = `${base_admission_url}/api/financial-aid/holds/business`
export const academic_holds = `${base_admission_url}/api/financial-aid/holds/academic`
export const holds_get_select_items = `${base_admission_url}/api/financial-aid/holds/business/select-items`
export const business_holds_delete = `${base_admission_url}/api/financial-aid/holds/business`
export const business_holds_post = `${base_admission_url}/api/financial-aid/holds/business`


//work study setup
export const workstudysetup_save = `${base_admission_url}/api/financial-aid/setup/work-study-setup`
export const setup_getData = `${base_admission_url}/api/financial-aid/setup/work-study-setup`

// Financial Aid Load Pell
export const load_pell_select_items = `${base_admission_url}/api/financial-aid/setup/load-pell/select-items`
export const load_pell_academic_year_select_items = `${base_admission_url}/api/financial-aid/setup/load-pell/academic-year-select-items`
export const load_pell_review_grid = `${base_admission_url}/api/financial-aid/setup/load-pell`

//Maintenance - Documents 

export const get_documents = `${base_admission_url}/api/financial-aid/maintenance/documents`
export const addDoc_select_items = `${base_admission_url}/api/financial-aid/maintenance/documents/select-items`
export const addDocument_finacial_select = `${base_admission_url}/api/financial-aid/maintenance/documents/financial-aid-year`
export const load_doc_seq = `${base_admission_url}/api/financial-aid/maintenance/documents/document-seq`
export const add_document = `${base_admission_url}/api/financial-aid/maintenance/documents/doc-track`
export const update_document = `${base_admission_url}/api/financial-aid/maintenance/documents/doc-track`
export const filtertoPackage_select = `${base_admission_url}/api/financial-aid/maintenance/isir/select-items`


export const load_pell_document_upload = `${base_admission_url}/api/financial-aid/setup/load-pell/pell-file`
export const load_pell_step_button = `${base_admission_url}/api/financial-aid/setup/load-pell/pell-schedule-file`


//finAid - yearly setup - isircc
export const yearlySetup_isircc_dropdown = `${base_admission_url}/api/financial-aid/setup/yearly-setup/isir-cc/layout-select-item`
export const yearlySetup_isircc_getapi = `${base_admission_url}/api/financial-aid/setup/yearly-setup/isir-cc`
export const yearlySetup_isircc_upload = `${base_admission_url}/api/financial-aid/setup/yearly-setup/isir-cc`

// FinAid-transfer

export const finAid_transfer = `${base_admission_url}/api/financial-aid/transfer/criteria/select-items`
export const criteria_prepare = `${base_admission_url}/api/financial-aid/transfer/prepare`
export const awardto_transfer = `${base_admission_url}/api/financial-aid/transfer/awards`
export const transfer_export = `${base_admission_url}/api/financial-aid/transfer/awards/export`
export const awardsFailed_ToTransfer = `${base_admission_url}/api/financial-aid/transfer`

//financial-aid > worki study maintenance
export const get_job_details = `${base_admission_url}/api/financial-aid/work-study/maintenance/jobs`
export const add_job_select = `${base_admission_url}/api/financial-aid/work-study/maintenance/jobs-add/select-items`
export const award_list = `${base_admission_url}/api/financial-aid/work-study/maintenance/assign-awards/awards-list`
export const jobs_DropDown = `${base_admission_url}/api/financial-aid/work-study/maintenance/jobs/select-items`
export const get_student_workers = `${base_admission_url}/api/financial-aid/work-study/maintenance/student-workers`
export const get_student_timesheet = `${base_admission_url}/api/financial-aid/work-study/maintenance/time-sheet`
export const delete_job = `${base_admission_url}/api/financial-aid/work-study/maintenance/jobs`
export const add_job = `${base_admission_url}/api/financial-aid/work-study/maintenance/jobs`
export const study_workers_selectItems = `${base_admission_url}/api/financial-aid/work-study/maintenance/student-workers/select-items`
export const financial_status_DropDown = `${base_admission_url}/api/financial-aid/work-study/maintenance/financial-status`
export const student_workers = `${base_admission_url}/api/financial-aid/work-study/maintenance/student-worker`
export const update_job = `${base_admission_url}/api/financial-aid/work-study/maintenance/jobs`
export const delete_worker = `${base_admission_url}/api/financial-aid/work-study/maintenance/student-workers`
export const timeSheet = `${base_admission_url}/api/financial-aid/work-study/maintenance/time-sheet`
export const add_award = `${base_admission_url}/api/financial-aid/work-study/maintenance/assign-awards`
export const delete_award = `${base_admission_url}/api/financial-aid/work-study/maintenance/assign-awards`
export const checkTimeSheetOverlap = `${base_admission_url}/api/financial-aid/work-study/maintenance/check-time-sheet-overlap`
export const checkIsDocumentSequenceComplete = `${base_admission_url}/api/financial-aid/work-study/maintenance/document-tracking-sequence-complete`



//financial-Aid -> student-notes
export const student_notes_getApi_tableData = `${base_admission_url}/api/financial-aid/student-notes`
export const student_notes_getDropDown = `${base_admission_url}/api/financial-aid/student-notes/select-item`
export const student_notes_deleteData = `${base_admission_url}/api/financial-aid/student-notes/note`
export const student_notes_postData = `${base_admission_url}/api/financial-aid/student-notes/note`
export const student_notes_getListData = `${base_admission_url}/api/financial-aid/student-notes/find-users`

export const student_notes_url = `${base_admission_url}/api/financial-aid/student-notes/lookup/select-item`
export const student_notes_search = `${base_admission_url}/api/financial-aid/student-notes/search`

// FA - Student Status
export const student_status = `${base_admission_url}/api/financial-aid/student-status/status`
export const student_status_program = `${base_admission_url}/api/financial-aid/student-status/programs`
export const get_extra_curricularActivty_selectItems = `${base_admission_url}/api/financial-aid/student-status/extra-curricular-select-item`
export const post_extra_curricularActivty = `${base_admission_url}/api/financial-aid/student-status/extra-curricular`
export const grid_extra_curricularActivty = `${base_admission_url}/api/financial-aid/student-status/extra-curricular`
export const user_defined_select_items = `${base_admission_url}/api/financial-aid/student-status/user-defined-select-items`
export const get_workstudy_maintenance_config = `${base_admission_url}/api/financial-aid/work-study/maintenance/configuration`

export const get_term_calendar_select_item = `${base_admission_url}/api/financial-aid/student-status/add-status/term-calendar-select-item`
export const get_add_status = `${base_admission_url}/api/financial-aid/student-status/add-status`
export const post_status = `${base_admission_url}/api/financial-aid/student-status/status`
export const get_user_defined = `${base_admission_url}/api/financial-aid/student-status/user-defined`
export const patch_user_defined = `${base_admission_url}/api/financial-aid/student-status/user-defined`

//Packaging > Single
export const single_base = `${base_admission_url}/api/financial-aid/packaging/single`
export const single_select_items = `${single_base}/select-items`
export const fin_aid_dropdown = `${single_base}/fin-aid-years-list`
export const post_single_entry = `${single_base}/single-distribution-entry`
export const single_dist_list = `${single_base}/single-distribution-list`
export const add_package = `${base_admission_url}/api/financial-aid/packaging/single/single-distribution-entry`
export const packaging_single_delete = `${base_admission_url}/api/financial-aid/packaging/single/packaging-single-setup`
export const loadCalendar = `${single_base}/setup/load-from-calender`
export const package_grid = `${base_admission_url}/api/financial-aid/packaging/single/package-award-list`
export const package_award_delete = `${base_admission_url}/api/financial-aid/packaging/single/package-award`
export const validate_percent = `${base_admission_url}/api/financial-aid/packaging/single/check-distribution-percent-limit`
export const package_process = `${single_base}/package/process`
export const package_awardType_awardSequence = `${single_base}/package/award-type-and-sequence/package-award`
export const package_pell = `${single_base}/package/read-pell`//{studentUID}/{financialAidYearID}
export const package_add_single = `${single_base}/package/add-single-award`
export const pellSetupGetInstructionalLengthsForTerm = `${single_base}/pell-setup/instructional-lengths-for-term`;
export const pellSetupSaveInstructionalLengthForTerm = `${single_base}/pell-setup/save-instructional-length-for-term`;
export const pellSetupDeleteInstructionalLengthForTerm = `${single_base}/pell-setup/delete-instructional-length-for-term`;

//Financial Aid - COD
export const get_MessageClass = `${base_admission_url}/api/financial-aid/cod/messages/select-items`
export const filter_record = `${base_admission_url}/api/financial-aid/cod/messages/filter-record-type-select-items`
export const messageClass_select = `${base_admission_url}/api/financial-aid/cod/messages/message-class-select-items`
export const import_select = `${base_admission_url}/api/financial-aid/cod/messages/import-date-select-items`
export const pgmr_read_msg = `${base_admission_url}/api/financial-aid/cod/messages/pgmr-read-message`
export const pgrc_read_msg = `${base_admission_url}/api/financial-aid/cod/messages/pgrc-read-message`
export const dsdf_read_msg = `${base_admission_url}/api/financial-aid/cod/messages/dsdf-read-message`
export const codrd_read_msg = `${base_admission_url}/api/financial-aid/cod/messages/codrd-read-message`
export const filter_msg = `${base_admission_url}/api/financial-aid/cod/messages/filter-message`
export const sync_cams_cod = `${base_admission_url}/api/financial-aid/cod/sync-cams-to-cod`
export const best_matching_award = `${base_admission_url}/api/financial-aid/cod/best-matching`
export const record_type = `${base_admission_url}/api/financial-aid/cod/messages/record-type-dependencies`


//Financial Aid - Budgets
export const select_budgets = `${base_admission_url}/api/financial-aid/packaging/budgets/select-items`
export const package_budgets = `${base_admission_url}/api/financial-aid/packaging/budgets`
export const package_budgets_academicYear = `${base_admission_url}/api/financial-aid/packaging/single/fin-aid-years-list`
export const package_budgets_costType = `${base_admission_url}/api/financial-aid/packaging/budget/select-items`
export const package_criteria = `${base_admission_url}/api/financial-aid/packaging/budgets/financial-priority-package-criteria`
export const package_budget_detail = `${base_admission_url}/api/financial-aid/packaging/financial-year-budgets`
export const package_budget_add = `${base_admission_url}/api/financial-aid/packaging/financial-priority-budget`
export const package_budget_delete = `${base_admission_url}/api/financial-aid/packaging/financial-priority-budget`
export const package_budget_copy = `${base_admission_url}/api/financial-aid/packaging/budgets/copy`
export const package_budget_initiate = `${base_admission_url}/api/financial-aid/packaging/budgets/initiate`
export const package_budget_studentSearch = `${base_admission_url}/api/financial-aid/packaging/budgets/students`
export const package_budget_studentDelete = `${base_admission_url}/api/financial-aid/packaging/budgets/students`
export const package_budget_process = `${base_admission_url}/api/financial-aid/packaging/budgets/process`
export const package_budget_clearSemaphore = `${base_admission_url}/api/financial-aid/packaging/budgets/clear-semaphore`


//Faculty Manager - User Admininstration
export const user_administration = `${base_admission_url}/api/manager/configuration/user-administration/users`
export const user_admin_dropdown = `${base_admission_url}/api/manager/configuration/user-administration/data-entry/parameters-select-items`
export const user_department = `${base_admission_url}/api/manager/configuration/user-administration/user-department`
export const user_admin_role = `${base_admission_url}/api/manager/configuration/user-administration/user-role`
export const user_admin_access = `${base_admission_url}/api/manager/configuration/user-administration/user-access`
export const get_userId = `${base_admission_url}/api/manager/configuration/user-administration/portal-user-name/id`
export const reset_password = `${base_admission_url}/api/manager/configuration/user-administration/update-password`

//Financial Aid - Package
export const package_package = `${base_admission_url}/api/financial-aid/packaging/package`
export const package_awards = `${base_admission_url}/api/financial-aid/packaging/package/awards`
export const package_add = `${base_admission_url}/api/financial-aid/packaging/package`
export const package_copy = `${base_admission_url}/api/financial-aid/packaging/package/copy`
export const awards_add = `${base_admission_url}/api/financial-aid/packaging/package/awards`
export const package_semaphore = `${base_admission_url}/api/financial-aid/packaging/package/clear-semaphore`
export const package_initiate = `${base_admission_url}/api/financial-aid/packaging/package/initiate`


//Financial Aid -Package Students Tab
export const package_students = `${base_admission_url}/api/financial-aid/packaging/package/students`
export const pacakage_students_delete = `${base_admission_url}/api/financial-aid/packaging/package/students`
export const prepare_award_methodologies = `${base_admission_url}/api/financial-aid/packaging/package/prepare-award-methodologies`
export const package_award_methodologies = `${base_admission_url}/api/financial-aid/packaging/package/award-methodologies`

//Financial Aid - Awards
export const awards_award_methodologies_delete = `${base_admission_url}/api/financial-aid/packaging/package/award-methodologies`
export const awards_prepare_post = `${base_admission_url}/api/financial-aid/packaging/package/prepare-package-awards`
export const awards_post = `${base_admission_url}/api/financial-aid/packaging/package/package-awards`
export const awards_delete = `${base_admission_url}/api/financial-aid/packaging/package/package-awards`





// Faculty manager - Test score table setup
export const select_items_table_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/select-items`
export const test_list_table_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/test-list`
export const score_list_table_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/score-list`
export const student_table_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/check-student`
export const delete_table_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/test`
export const post_table_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/test`
export const post_score_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/score`
export const delete_score_setup = `${base_admission_url}/api/manager/lookup-table-options/test-score-table-setup/score`

// Faculty Manager - Configuration
export const configuration_get = `${base_admission_url}/api/manager/configuration`
export const configuration_general_select = `${base_admission_url}/api/manager/configuration/general/select-items`
export const configuration_registration_select = `${base_admission_url}/api/manager/configuration/registration/select-items`
export const configuration_generalone_save = `${base_admission_url}/api/manager/configuration/general-one`
export const configuration_generaltwo_save = `${base_admission_url}/api/manager/configuration/general-two`
export const configuration_billing_select = `${base_admission_url}/api/manager/configuration/billing/select-items`
export const configuration_billing_post = `${base_admission_url}/api/manager/configuration/billing`
export const configuration_registration_save = `${base_admission_url}/api/manager/configuration/registration`
export const configuration_misc_select = `${base_admission_url}/api/manager/configuration/misc/select-items`
export const cofiguration_misc_Save = `${base_admission_url}/api/manager/configuration/misc`
export const configuration_financialaid_save = `${base_admission_url}/api/manager/configuration/financial-aid`

//Faclty Manager-Rebuld EnrollmentConut && grades && Rebuild-repeats
export const enrollmentCount_selectItem = `${base_admission_url}/api/manager/rebuild/registration/enrollment-count/select-item`
export const enrollement_postapi = `${base_admission_url}/api/manager/rebuild/registration/enrollment-count/process`
export const grades_getApi = `${base_admission_url}/api/manager/rebuild/registration/grades`
export const rebuild_repeats = `${base_admission_url}/api/manager/rebuild/registration/rebuild-repeat-process`
export const rebuild_repeats_policy = `${base_admission_url}/api/manager/rebuild/registration/repeat-policy`

// Manager - View Error Log
export const viewErrorLog = `${base_admission_url}/api/manager/view-error-log/error-log`
export const errorLogEmail = `${base_admission_url}/api/manager/view-error-log/error-log-mail`


//Financial Aid - Package
export const award_methodology_grid = `${base_admission_url}/api/financial-aid/packaging/package`
//Financial Aid-awardMethod
export const priortized_method = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/billing-sum-trans`

//faculty - manager > role admin
export const role_get = `${base_admission_url}/api/manager/configuration/role-administration/roles`
export const edit_role_get = `${base_admission_url}/api/manager/configuration/role-administration/menus`
export const user_in_role = `${base_admission_url}/api/manager/configuration/role-administration/user-in-role`
export const add_menu = `${base_admission_url}/api/manager/configuration/role-administration/menus`
export const update_menu = `${base_admission_url}/api/manager/configuration/role-administration/menus`


//faculty - manager > FP Admin
export const fp_role_get = `${base_admission_url}/api/manager/configuration/users/fp-admin-role-access/roles`

//faculty manager
export const faculty_manager_delete_student_dropdown = `${base_admission_url}/api/manager/student-utilities/select-items`
export const faculty_manager_delete_student = `${base_admission_url}/api/manager/student-utilities`
export const faculty_manager_delete_student_post = `${base_admission_url}/api/manager/student-utilities/student`


//faculty - manager > State Reports Lookup
export const state_glossary_table = `${base_admission_url}/api/manager/lookup-table-options/state-reports-lookup/glossary`
export const state_reports_detail_table = `${base_admission_url}/api/manager/lookup-table-options/state-reports-lookup/glossary-category`

// Financial Aid - Package Criteria
export const package_criteria_copy = `${base_admission_url}/api/financial-aid/packaging/package-criteria/copy`
export const package_criteria_search = `${base_admission_url}/api/financial-aid/packaging/package-criteria/find`
export const package_criteria_delete = `${base_admission_url}/api/financial-aid/packaging/package-criteria`
export const package_criteria_query_select = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query/select-items`

//Packaging > Lifetime Limits
export const lifetime_base = `${base_admission_url}/api/financial-aid/packaging/pioritized/lifetime-limits`
export const GET_lifetime_select = `${lifetime_base}/select-items`
export const GET_lifetime_fin_aid_years_list = `${lifetime_base}/fin-aid-years-list`
export const GET_lifetime_queries_list = `${lifetime_base}/setup/lifetime-queries-list`
export const GET_lifetime_awardtypes = `${lifetime_base}/setup/lifetime-awardtypes`
export const POST_lifetime_awardtypes = `${lifetime_base}/setup/lifetime-awardtype`
export const GET_financial_lifetime_list = `${lifetime_base}/update-tab/financial-lifetime-list`
export const financial_lifetime = `${lifetime_base}/update-tab/financial-lifetime`
export const lifetime_initiate = `${lifetime_base}/initiate`
export const POST_lifetime_table = `${lifetime_base}/update-tab/financial-lifetime-table`
export const POST_lifetime_clear_semaphore = `${lifetime_base}/update-tab/clear-semaphore`

export const package_criteria_get_operators = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query/select-items`
export const package_criteria_get_query_object = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query-object`
export const package_criteria_get_query_field = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query-object-fields`
export const package_criteria_get_value_lookup = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query-object-field/lookup`
export const package_criteria_get_show_table = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query/show`
export const package_criteria_advanced_test = `${base_admission_url}/api/financial-aid/packaging/package-criteria/advanced-query/test`
export const package_criteria_test = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query/test`
export const package_query_builder = `${base_admission_url}/api/financial-aid/packaging/package-criteria/query`

//faculty - manager > Email Templates
export const email_templates = `${base_admission_url}/api/manager/lookup-table-options/email-templates`
export const email_templates_selectItems = `${email_templates}/select-items`
export const email_templates_list = `${email_templates}/list`
export const email_templates_build = `${email_templates}/build`
export const email_templates_groups = `${email_templates}/groups`
export const email_templates_group = `${email_templates}/group`
export const email_templates_attachment = `${email_templates}/attachment`
export const email_templates_test = `${email_templates}/test`

//faculty - manager -> risk indicator setUp
export const faculty_manager_get_risk_type = `${base_admission_url}/api/manager/lookup-table-options/risk-indicator-setup/risk-type`
export const faculty_manager_get_risk_category = `${base_admission_url}/api/manager/lookup-table-options/risk-indicator-setup/risk-category`
export const faculty_manager_get_risk_status = `${base_admission_url}/api/manager/lookup-table-options/risk-indicator-setup/risk-status`
export const faculty_manager_get_risk_type_list = `${base_admission_url}/api/manager/lookup-table-options/risk-indicator-setup/risk-type-name`
export const faculty_manager_get_risk_category_types = `${base_admission_url}/api/manager/lookup-table-options/risk-indicator-setup/risk-category-types`





//Faculty Manager - Multicurrency
export const get_currencyTypes = `${base_admission_url}/api/manager/configuration/multi-currency/currency-types/defined-currency-types`
export const currencyType_url = `${base_admission_url}/api/manager/configuration/multi-currency/currency-types/defined-currency`
export const systemBaseCurrency = `${base_admission_url}/api/manager/multi-currency/config`
export const exchangeRates = `${base_admission_url}/api/manager/multi-currency/exchange-rates`
export const get_exchangeRatesDrpdwn = `${base_admission_url}/api/manager/multi-currency/data-entry/exchange-rates-select-items`

//Financial Aid - Package Process 
export const process_select_items = `${base_admission_url}/api/financial-aid/packaging/package/process/select-items`
export const process_limit = `${base_admission_url}/api/financial-aid/packaging/package/process/limit-processing`
export const process_unlockaward = `${base_admission_url}/api/financial-aid/packaging/package/process/unlock-awards`
export const process_package = `${base_admission_url}/api/financial-aid/packaging/package/process`

// FinancialAid Package-Award Methodology

export const award_methodology = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/select-items`
export const financial_award_id = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/fin-aid-years-list`
export const award_methodology_list = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/award-methodology-list`
export const delete_award_methodology = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology`
export const add_studentLoad = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/student-load-entry`
export const delete_award_methodology_table = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/award-methodology`
export const billing_sum_list = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/billing-sum-trans-list`
export const student_load = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/student-load-list`
export const remaining_need_list = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/remaining-need-list`
export const pell_schedule_list = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/amounts-based-list-sql`
export const add_remaining_need = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/remaining-need-entry`
export const copy_one = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/copy-one-award-methodology`
export const copy_all = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/copy-all-award-methodologies`

export const add_methodology = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/award-methodology-entry`
export const get_student_selection = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/student-selection-criteria-list`

//Lookup Table Option - User Defined setup
export const user_defined_setup_dropdown = `${base_admission_url}/api/manager/configuration/lookup-table-options/user-defined-field-setup/select-items`
export const user_defined_setup_table = `${base_admission_url}/api/manager/configuration/lookup-table-options/user-defined-field-setup/setup`


//Lookup Table Option-  DocTrack Setup
export const document_location = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/document-locations`
export const document_docs = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/documents`
export const doc_track_setup = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/basic`
export const document_select_item = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/documents/select-items`
export const sequence_groups = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/seq-group`
export const Seq_groups_selectitems = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/seq-group/select-items`
export const Sequences = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/sequences`
export const mapped_Sequences = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/mapped-sequence`
export const sequence_group_validate = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/seq-group/validate`

//Sequences tab

export const get_group_select_item = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/sequences/group-select-item`
export const get_sequence_select_item = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/sequences/select-items`
export const get_document_details = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/sequences/document-details`
export const get_document_list = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/sequences/document-select-item`
export const get_Location_Id = `${base_admission_url}/api/manager/lookup-table-options/doc-track-setup/sequences/location`



// Lookup Table Options > Grade groups
export const grade_groups = `${base_admission_url}/api/manager/lookup-table-options/grade-groups`
export const get_data = `${base_admission_url}/api/manager/lookup-table-options/grade`
export const grade_delete = `${base_admission_url}/api/manager/lookup-table-options/grade-group`
// Faculty & Manager County Setup
export const county_setup_county = `${base_admission_url}/api/manager/lookup-table-options/county-setup`
export const county_setup_select_items = `${county_setup_county}/select-items`
export const county_setup_search = `${county_setup_county}/find`
export const county_setup_code_types = `${county_setup_county}/county-code-types`
export const county_setup_setup_code = `${county_setup_county}/county-codes`
// Transaction document
export const tranc_doc_select = `${base_admission_url}/api/manager/configuration/lookup-table-options/transaction-documents/trans-doc-select-items`
export const add_tranc_doc_select = `${base_admission_url}/api/manager/configuration/lookup-table-options/transaction-documents/trans-doc-add-select-items`
export const grid_tranc_doc_select = `${base_admission_url}/api/manager/configuration/lookup-table-options/transaction-documents/trans-doc`
export const del_tranc_doc_select = `${base_admission_url}/api/manager/configuration/lookup-table-options/transaction-documents/trans-doc`
export const post_tranc_doc_select = `${base_admission_url}/api/manager/configuration/lookup-table-options/transaction-documents/trans-doc`
export const put_tranc_doc_select = `${base_admission_url}/api/manager/configuration/lookup-table-options/transaction-documents/trans-doc`

// Lookup Table Option - Portal Url Ref
export const get_portalTypes = `${base_admission_url}/api/manager/configuration/lookup-table-options/portal-url-ref/portal-url-ref-select-items`
export const portal_url = `${base_admission_url}/api/manager/configuration/lookup-table-options/portal-url-ref/urls`

// Lookup Table Option - Term-Calendar
export const termcalendar_entry = `${base_admission_url}/api/manager/lookup-table-options/term-calendar`
export const termcalendar_selectitems = `${termcalendar_entry}/select-items`
export const termcalendar_terms = `${termcalendar_entry}/terms`
export const termcalendar_subterms = `${termcalendar_entry}/term-child-list`
export const termcalendar_add = `${termcalendar_entry}/term-and-subterm`

// Lookup table options -Institutional sap
export const institutional_sap_get = `${base_admission_url}/api/manager/configuration/lookup-table-options/institutional-sap`
export const institutional_sap_delete = `${base_admission_url}/api/manager/configuration/lookup-table-options/institutional-sap`
export const institutional_sap_post = `${base_admission_url}/api/manager/configuration/lookup-table-options/institutional-sap`
export const institutional_sap_put = `${base_admission_url}/api/manager/configuration/lookup-table-options/institutional-sap`

//faculty - manager > Custom Grades
export const custom_grades = `${base_admission_url}/api/manager/lookup-table-options/custom-grade`
export const custom_grades_selectItems = `${base_admission_url}/api/manager/lookup-table-options/custom-grade/select-items`

// Lookup table options - Location setup
export const location_setup = `${base_admission_url}/api/manager/lookup-table-options/location-setup`
export const locationsetup_selectitems = `${location_setup}/select-items`
export const locationsetup_find = `${location_setup}/find`
export const locationsetup_county = `${location_setup}/county`

// SemaphoresRegistration
export const reset_get_grid = `${base_admission_url}/api/manager/semaphores/registration/reset`
//Portal News 
export const portal_news = `${base_admission_url}/api/manager/cams-portal/portal-news`
export const portal_news_search = `${portal_news}/news`
export const portal_news_image = `${portal_news}/news-image`
export const portal_news_selectItems = `${portal_news_search}/select-items`

// System Manager: portal payment
export const portal_payment_dropdown = `${base_admission_url}/api/manager/configuration/portal-payment/select-items`
export const portal_payment_trans_dropdown = `${base_admission_url}/api/manager/configuration/portal-payment/trans-docs/select-items`
export const portal_payment = `${base_admission_url}/api/manager/configuration/portal-payment`
export const portal_payment_copy = `${portal_payment}/payment-parmeters/copy`
export const doc_description = `${base_admission_url}/api/manager/configuration/portal-payment/trans-docs-desc`

//Faculty Portal Configuration
export const faculty_config = `${base_admission_url}/api/manager/cams-portal/faculty-configuration`
export const faculty_selectitems = `${faculty_config}/select-items`
export const faculty_cams_users = `${faculty_config}/available-cams-users`
export const faculty_cams_gradesubmit = `${faculty_config}/grade-submit-alert-user-list`
export const faculty_save = `${faculty_config}/faculty-configuration`

//portal - IP Lockout
export const ipLockout_select = `${base_admission_url}/api/manager/portal-configuration/data-entry/ip-lockout-select-items`
export const ip_lockout_data = `${base_admission_url}/api/manager/portal-configuration/ip-lockout`

//Misc Configuration

export const misc_configuration_items = `${base_admission_url}/api/manager/cams-portal/misc-configuration`

//Tools News Feed
export const news_feed = `${base_admission_url}/api/tools/news-feed`
export const news_feed_dropdown = `${news_feed}/select-items`

//Student Portal Configuration

export const student_Items_get = `${base_admission_url}/api/manager/cams-portal/student-configuration`
export const student_selectItems = `${student_Items_get}/select-items`

//Lookup-Table-options > Governmental SAP
export const governmental_sap_grid = `${base_admission_url}/api/manager/lookup-table/governmental-sap/governmental-sap`


//Graduate Application
export const graduate_application_select_items = `${base_admission_url}/api/manager/portal-configuration/graduate-application/select-items`
export const graduate_application_configuration = `${base_admission_url}/api/manager/portal-configuration/graduate-application/configuration`
export const graduate_application_configuration_setup = `${base_admission_url}/api/manager/portal-configuration/graduate-application/configuration-setup`

//Tools-CRS Evaluation Setup
export const crs_eval_master = `${base_admission_url}/api/tools/crs-eval-master`
export const crs_eval_master_question = `${crs_eval_master}/question`
export const crs_eval_master_question_Dropdown = `${crs_eval_master}/select-items`
export const crs_eval_master_mcitem = `${crs_eval_master}/mc-items`
export const crs_link = `${base_admission_url}/api/tools/crs-eval-master/link-response`


// Tools > Sudent Activity
export const student_activity_base = `${base_admission_url}/api/tools/processes/admissions/student-activity`
export const tools_student_activity_dropdown = `${student_activity_base}/criteria/select-items`
export const tools_student_activity_highschool_data = `${student_activity_base}/criteria/high-school`
export const tools_student_activity_data = `${student_activity_base}/students`
export const tools_student_dropdown = `${student_activity_base}/activity/select-items`
export const tools_initiate = `${student_activity_base}/criteria/initiate`
export const clear_semaphore_student = `${student_activity_base}/clear-semaphore`
export const tools_student_activity_highschool_Criteria = `${student_activity_base}/criteria/high-school/code-types`
export const tools_student_activity_college_search = `${student_activity_base}/criteria/college`
export const tools_student_activity_org_search = `${student_activity_base}/criteria/organization`
export const tools_student_activity_org_dropdown = `${student_activity_base}/criteria/organization/select-items`
export const add_activity = `${base_admission_url}/api/tools/processes/admissions/student-activity/activity`

// Tools > processes > billing-module > billing-processes
export const billing_processes = `${base_admission_url}/api/tools/processes/billing-module/billing-processes`
export const interest_selectitems = `${billing_processes}/interest/select-items`
export const interesttab_save = `${billing_processes}/interest`
export const interesttab_export = `${billing_processes}/interest/export`
export const refund_selectitems = `${billing_processes}/refunds/select-items`
export const refundtab_save = `${billing_processes}/refunds`


// Tools > processes > billing-module > LatePaymentFees
export const latefees = `${base_admission_url}/api/tools/processes/billing-module/late-payment-fees`
export const latefees_selectitems = `${latefees}/options/select-items`
export const fines_selectitems = `${latefees}/fines/select-items`
export const fines_defaultvalues = `${latefees}/fines/default-values`
export const fines_save = `${latefees}/fines`
export const set_semaphore = `${latefees}/check-set-semaphore`
export const clear_semaphore = `${latefees}/clear-semaphore`




//Tools > registration-module > transcript batch
export const tools_TranscriptBatches_reportType = `${base_admission_url}/api/tools/processes/registration/transcript-batches/batch-list/select-items`
export const tools_TranscriptBatches_batch_data = `${base_admission_url}/api/tools/processes/registration/transcript-batches/batch-list`




// Tools - SAP Changes
export const sap_select_items = `${base_admission_url}/api/tools/processes/registration/data-entry/reg-sap-change-def-select-items`
export const sap_setup = `${base_admission_url}/api/tools/processes/registration/reg-sap-change-def`
export const run_order = `${base_admission_url}/api/tools/processes/registration/reg-sap-change-def-order`
export const run_order_verify = `${base_admission_url}/api/tools/processes/registration/reg-sap-change-def-verify`
export const run_order_select_items = `${base_admission_url}/api/tools/processes/registration/data-entry/rule-select-items`
export const run_order_process = `${base_admission_url}/api/tools/processes/registration/reg-sap-change-def-process`
export const run_order_verify_grid = `${base_admission_url}/api/tools/processes/registration/reg-sap-change-def-verify-data`
export const run_order_verify_export = `${base_admission_url}/api/tools/processes/registration/reg-sap-change-def-verify-export`

//Tools - Generate Exam ID's
export const generate_id = `${base_admission_url}/api/tools/processes/registration-module/generate-exam/generate-exam-id`
export const generate_load = `${base_admission_url}/api/tools/processes/registration-module/generate-exam/load-student-exam`

//system manager - Application Configuration
export const tools_appication_configuration_data = `${base_admission_url}/api/manager/application-portal-configuration/select-items`
export const tools_appication_test_config = `${base_admission_url}/api/manager/application-portal-configuration/test-configuration`
export const tools_appication_test_program = `${base_admission_url}/api/manager/application-portal-configuration/configuration-test`
export const tools_appication_portal_config_default = `${base_admission_url}/api/manager/application-portal-configuration/portal-config-default`




// Tools - Import - COD Import
export const tools_CODImport_student_data = `${base_admission_url}/api/tools/import/financial-aid/cod-import/students`
export const tools_CODImport_upload = `${base_admission_url}/api/tools/import/financial-aid/cod-import`
export const tools_CODImport_summary = `${base_admission_url}/api/tools/import/financial-aid/cod-import/summary-reported`
export const tools_CODImport_responce = `${base_admission_url}/api/tools/import/financial-aid/cod-import/responses`
export const tools_CODImport_award = `${base_admission_url}/api/tools/import/financial-aid/cod-import/awards`
export const tools_CODImport_disburse = `${base_admission_url}/api/tools/import/financial-aid/cod-import/disbursements`
export const tools_CODImport_counseling_import = `${base_admission_url}/api/tools/import/financial-aid/cod-import/import-counselling`
export const tools_CODImport_import_mpn = `${base_admission_url}/api/tools/import/financial-aid/cod-import/awards/import-mpn`
export const tools_CODImport_update_contact_info = `${base_admission_url}/api/tools/import/financial-aid/cod-import/awards/update-contact-info`
export const tools_CODImport_import_credit_plus_app_info = `${base_admission_url}/api/tools/import/financial-aid/cod-import/awards/import-credit-plus-app-info`
export const tools_CODImport_import_informed_borrower_info = `${base_admission_url}/api/tools/import/financial-aid/cod-import/awards/import-informed-borrower-info`
export const tools_CODImport_import_update_award_status = `${base_admission_url}/api/tools/import/financial-aid/cod-import/disbursements/update-award-status`
export const tools_CODImport_import_update_award_usage_limits = `${base_admission_url}/api/tools/import/financial-aid/cod-import/disbursements/update-award-usage-limits`






//Tools -DockTrack AutoLoad
export const custom_select_items = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/custom/select-items`
export const custom_codelist_custom_isir = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/custom/codes-list-custom-isir`
export const custom_docload_list = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/custom/doc-load-list`
export const custom_custom_isir = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/custom/custom-isir`
export const custom_import_document_load = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/custom-and-standard/import-document-load`
export const test_result_list = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/standard/use-sql/test-results-list`
export const yearIndicator_select = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/yearIndicator/select-items`

//ISIR-Direct
export const isir_direct = `${base_admission_url}/api/tools/import/financial-aid/isir-direct`
export const isir_direct_select_items = `${isir_direct}/select-items`
export const sql_query_list = `${isir_direct}/criteria/batch-assign-fin-aid-years/sql-queries-list`
export const college_fice_code_list = `${isir_direct}/criteria/college-fice-codes-list`
export const college_fice_code = `${isir_direct}/college-fice-code`
export const parse_document = `${isir_direct}/parse-document`
export const records_active = `${isir_direct}/isir-records/set-all-active-inactive`
export const holding_records_active = `${isir_direct}/holding-records/set-all-active-inactive`
export const add_to_batch = `${isir_direct}/holding-records/add-to-batch`
export const isir_direct_import = `${isir_direct}/import/ede-isir-import`
export const upload_document = `${base_admission_url}/api/tools/import/financial-aid/isir-direct/upload`



export const custom_dockTrack_isir = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/custom/doctrack-isir-import`
export const custom_isir_import_load = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/custom-and-standarad/isir-import-load`
export const codes_list_standard_isir = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/standard/codes-list-standard-isir`
export const standard_doc_load_list = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/standard/doc-load-list`
export const codes_standard_isir = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/standard/codes-standard-isir`
export const isir_standard = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/standard/doctrack-isir-standard-import`
export const copy_academicyear = `${base_admission_url}/api/tools/import/financial-aid/doc-track-autoload/standard/copy-academic-year`


export const tools_setup_select_items = `${base_admission_url}/api/tools/processes/registration/status-changes/setup/select-items`
export const status_Changes_setup = `${base_admission_url}/api/tools/processes/registration/status-changes/setup`
export const status_Changes_setup_grade = `${base_admission_url}/api/tools/processes/registration/status-changes/setup/grade`
export const status_Changes_run_process = `${base_admission_url}/api/tools/processes/registration/status-changes/run/process`
export const status_Changes_run_verify = `${base_admission_url}/api/tools/processes/registration/status-changes/run/verify`


//Tools > Picture Access
export const student_picture_upload = `${base_admission_url}/api/tools/picture-access/student-picture-upload/details`
export const faculty_picture_upload = `${base_admission_url}/api/tools/picture-access/faculty-picture-upload/details`
export const faculty_pictures_info = `${base_admission_url}/api/tools/picture-access/faculty-picture-upload/faculty-pictures-info`
export const student_pictures_info = `${base_admission_url}/api/tools/picture-access/student-picture-upload/student-pictures-info`


// System Manager - Semaphores
export const semaphores = `${base_admission_url}/api/manager/semaphore`

//Tools-Prospect Activity



export const prospect_activity_select = `${base_admission_url}/api/tools/processes/admissions/prospect-activity/activity/select-items`
export const prospect_base = `${base_admission_url}/api/tools/processes/admissions/prospect-activity`
export const prospect_select_items = `${prospect_base}/criteria/select-items`
export const prospect_test = `${prospect_base}/criteria/tests`
export const prospect_scores = `${prospect_base}/criteria/scores`
export const prospect_criteria = `${prospect_base}/criteria/initiate`
export const prospect_prospectgrid = `${prospect_base}/prospects`
export const prospect_semaphore = `${base_admission_url}/api/tools/processes/admissions/prospect-activity/clear-semaphore`
export const post_prospect_activity = `${prospect_base}/activity`

//Mass Updatebase_admission_url
export const mass_update = `${base_admission_url}/api/tools/processes/registration/mass-update-status/mass-update`
export const mass_update_export = `${base_admission_url}/api/tools/processes/registration/mass-update-status/mass-update-export`




// Tools-COD Export 
export const select_criteria = `${base_admission_url}/api/tools/export/financial-aid/cod-export/select-items`
export const cod_summary = `${base_admission_url}/api/tools/export/financial-aid/cod-export/cod-summary`
export const cod_loan_info = `${base_admission_url}/api/tools/export/financial-aid/cod-export/cod-status`
export const cod_awards = `${base_admission_url}/api/tools/export/financial-aid/cod-export/cod-awards`
export const cod_loan = `${base_admission_url}/api/tools/export/financial-aid/cod-export/cod-loan`
export const cod_award_del = `${base_admission_url}/api/tools/export/financial-aid/cod-export`
export const cod_award_put = `${base_admission_url}/api/tools/export/financial-aid/cod-export/awards`
export const cod_criteria_get = `${base_admission_url}/api/tools/export/financial-aid/cod-export/cod-setup`
export const cod_initiate_post = `${base_admission_url}/api/tools/export/financial-aid/cod-export/initiate`
export const cod_precheck_validate = `${base_admission_url}/api/tools/export/financial-aid/cod-export/pre-check-validate`
export const build_data_export = `${base_admission_url}/api/tools/export/financial-aid/cod-export/build-data-for-export`
export const file_validate_xml = `${base_admission_url}/api/tools/export/financial-aid/cod-export/build-file`

// Tools - Merge Offering
export const merge_offerings = `${base_admission_url}/api/tools/processes/registration-module/merge-offering/search`
export const merge_offers = `${base_admission_url}/api/tools/processes/registration-module/merge-offering/process-merge-offering`

//Tools > Export > Financial Aid > NSLC Export
export const nslc_export = `${base_admission_url}/api/tools/export/financial-aid/nslc`
export const nslc_select_items = `${base_admission_url}/api/tools/export/financial-aid/data-entry/nslc-select-items`
export const nslc_initialise = `${base_admission_url}/api/tools/export/financial-aid/nslc-data-initialize`
export const nslc_dataExport = `${base_admission_url}/api/tools/export/financial-aid/nslc-data-export`
export const nslc_save = `${base_admission_url}/api/tools/export/financial-aid/nslc-data-save`

//Tools > Export > Financial Aid > Powerfaids
export const powerfaids_selectItems = `${base_admission_url}/api/tools/export/financial-aid/power-faids/criteria-select-items`
export const powerfaids_initiate = `${base_admission_url}/api/tools/export/financial-aid/power-faids/initiate`
export const powerfaids_records = `${base_admission_url}/api/tools/export/financial-aid/power-faids/records`
export const powerfaids_records_selectItems = `${base_admission_url}/api/tools/export/financial-aid/power-faids/records/select-items`
export const powerfaids_records_initiate = `${base_admission_url}/api/tools/export/financial-aid/power-faids/records/initiate`
export const powerfaids_records_export = `${base_admission_url}/api/tools/export/financial-aid/power-faids/export`
export const powerfaids_score_selectitems = `${base_admission_url}/api/tools/export/financial-aid/power-faids/score`

//Tools> Export> SpeedeCollege transcript
export const source_college = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/source-college`
export const select_items = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/source-college/select-items`
export const doc_items = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede/select-items`
export const doc_update = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-document`
export const doc_Id = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede`
export const speede_courses_Items = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-courses/select-items`
export const speede_course_grid = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-courses`
export const speede_process1 = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-courses/course-credit-basis`
export const speede_process2 = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-courses/course-credit-unit`
export const speede_process3 = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-courses/course-credit-level`
export const speede_process4 = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-courses/grade-scale-code`
export const set_default = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-courses/set-default`
export const test_grid = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-tests`
export const speede_exportItems = `${base_admission_url}/api/tools/export/registration/data-entry/speede-college-transcript-select-items`
export const speede_exportget = `${base_admission_url}/api/tools/export/registration/speede-college-transcript-export`
export const speede_export_data = `${base_admission_url}/api/tools/export/registration/speede-college-transcript-export-data`
export const speede_test_items = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-tests`
export const course_editpopup = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede-course`
export const get_document_Id = `${base_admission_url}/api/tools/export/registration/speede-college-transcript/speede`



//Tools > Processes > Registration > Mass Add Audit
export const massAudit = `${base_admission_url}/api/tools/processes/registration/mass-add-audit`
export const massAudit_criteria = `${massAudit}/criteria`
export const massAudit_audit = `${massAudit}/audit`
export const massAudit_criteria_dropDown = `${massAudit_criteria}/select-items`
export const massAudit_criteria_initiate = `${massAudit_criteria}/initiate`
export const massAudit_audit_dropDown = `${massAudit_audit}/select-items`
export const massAddAudit_student = `${massAudit}/student`
export const massAddAudit_studentWithAudit = `${massAudit}/student-with-audit`
export const massAddAudit_process = `${massAudit}/process`



// Tools> R2T4

export const select_items_r2t4 = `${base_admission_url}/api/tools/processes/financial-aid/r2t4/select-items`
export const students_r2t4 = `${base_admission_url}/api/tools/processes/financial-aid/r2t4/students`
export const process_r2t4 = `${base_admission_url}/api/tools/processes/financial-aid/r2t4/process-student`
export const exception_student = `${base_admission_url}/api/tools/processes/financial-aid/r2t4/process-exception-student`

//Tools>Export>FAID>Report
export const faid_item = `${base_admission_url}/api/tools/export/financial-aid/gainful-employment/select-items`

export const load_data = `${base_admission_url}/api/tools/export/financial-aid/gainful-employment/load-data`
export const export_data = `${base_admission_url}/api/tools/export/financial-aid/gainful-employment/export-data`

//Tools - No Show Award Removal
export const no_show_remove_select_items = `${base_admission_url}/api/tools/processes/financial-aid/no-show-remove/criteria/select-items`
export const no_show_remove_criteria = `${base_admission_url}/api/tools/processes/financial-aid/no-show-remove/criteria`
export const no_show_remove_awards = `${base_admission_url}/api/tools/processes/financial-aid/no-show-remove/awards`
export const no_show_remove_process = `${base_admission_url}/api/tools/processes/financial-aid/no-show-remove/process`



//Tools > Export > Registration > Degree Verfiy
export const degreeVerify = `${base_admission_url}/api/tools/export/registration/degree-verify`
export const degreeVerify_criteria = `${degreeVerify}/criteria`
export const degreeVerify_criteria_DropDown = `${degreeVerify_criteria}/select-items`
export const degreeVerify_getHeader = `${degreeVerify}/header`

export const degreeVerify_students = `${degreeVerify}/students`
//Tools > Import > FinancialAid > Powerfaids
export const import_powerfaids = `${base_admission_url}/api/tools/import/financial-aid/powerfaids/import`
export const import_powerfaids_select = `${import_powerfaids}/select-items`
export const import_powerfaids_awards = `${base_admission_url}/api/tools/import/financial-aid/powerfaids/award/awards-to-import`
export const import_powerfaids_exception = `${base_admission_url}/api/tools/import/financial-aid/powerfaids/award/exceptions`
export const import_powerfaids_initialise = `${base_admission_url}/api/tools/import/financial-aid/powerfaids/criteria`

//Tools > Import >Financial Aid > Cod Message

export const select_items_message = `${base_admission_url}/api/tools/import/financialAid/cod-message/last-imported-date/select-items`
export const get_message_import = `${base_admission_url}/api/tools/import/financialAid/cod-message/last-imported-date`
export const post_message_import = `${base_admission_url}/api/tools/import/financialAid/cod-message/codmessage-import`


//Tools > Import > Registration > Speede College Transcript
export const speede_college_transcripts_select_items = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/select-items`
export const speede_college_transcripts_query_import = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/query-import`
export const speede_college_transcripts_load_document = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/load-document`
export const speede_college_transcripts_student_adress = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/student-address`
export const speede_college_transcripts_student_degree = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/student-degree`
export const speede_college_transcripts_import_file = `${base_admission_url}/api/tools/export/texas-state-report-tfads/import-file`

export const sessions = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/academics/sessions`
export const academic_courses = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/academics/courses`
export const academic_courses_parameters = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/academics/courses/parameters`
export const sessions_select_items = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/academics/sessions/select-items`
export const courses_select_items = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/academics/courses/select-items`

export const speede_college_transcripts_tests = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/tests`
export const speede_college_transcripts_test_name_select_item = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/tests/test-name-select-item`
export const speede_college_transcripts_tests_update = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/tests/information`
export const speede_college_transcripts_high_school_lookup = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/high-school-lookup`
export const speede_college_transcripts_import = `${base_admission_url}/api/tools/import/registration/speede-college-transcript/import`


//Tools > Global Search
export const global_search_select_items = `${base_admission_url}/api/tools/global-search/select-items`
export const global_search = `${base_admission_url}/api/tools/global-search`
export const global_search_generate = `${global_search}/generate`

//Texas Reports> Student Schedule

export const student_schedule_select_items = `${base_admission_url}/api/tools/export/texas-state-report-cbm00s/select-items`
export const stduent_schedule = `${base_admission_url}/api/tools/export/texas-state-report-cbm00s/holdings`
export const student_report_data = `${stduent_schedule}`
export const fixed_length_data = `${stduent_schedule}/fixed-length`
export const file_export = `${stduent_schedule}/fixed-length/export`
export const edit_schedule_select_items = `${base_admission_url}/api/tools/export/texas-state-report-cbm00s/holding/select-items`
export const update_schedule = `${base_admission_url}/api/tools/export/texas-state-report-cbm00s/holding`
//tools > recommended course sequence
export const recommended_course_select_items = `${base_admission_url}/api/tools/export/texas-state-reports/recommended-course-sequence/select-items`
export const recommended_course_get_initial_sequence = `${base_admission_url}/api/tools/export/texas-state-reports/recommended-course-sequence/initialize-sequence`
export const recommended_course_get_initial_group = `${base_admission_url}/api/tools/export/texas-state-reports/recommended-course-sequence/initialize-group`
export const recommended_course_get_initial_sequence_list = `${base_admission_url}/api/tools/export/texas-state-reports/recommended-course-sequence/list`
export const recommended_course_get_initial_group_list = `${base_admission_url}/api/tools/export/texas-state-reports/recommended-course-sequence/group-list`

export const recommended_course_export = `${base_admission_url}/api/tools/export/texas-state-reports/recommended-course-sequence/export-data`
export const recommended_course_contiue_export = `${base_admission_url}/api/tools/export/texas-state-reports/recommended-course-sequence/export`






//tools > KHEDS - Report Exports
export const KHEDS_state_report_select_items = `${base_admission_url}/api/tools/export/kansas-state-report/select-items`
export const KHEDS_term_period_select_items = `${base_admission_url}/api/tools/export/kansas-state-report/term-period-select-item`
export const KHEDS_get_report = `${base_admission_url}/api/tools/export/kansas-state-report/report`
export const KHEDS_get_file = `${base_admission_url}/api/tools/export/kansas-state-report/export`



//Tools>CBMI009 Graduation
export const cbm009_graduation = `${base_admission_url}/api/tools/export/texas-state-reports/cbm009-graduation`
export const graduation_post = `${cbm009_graduation}/parameters`
export const create_file = `${cbm009_graduation}/export`
export const graduation_get = `${cbm009_graduation}/data`

//Tools>Import>Adm>TestScoreMapping
export const testmap_Grid = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/mapping-group-list`
export const addtest_score_select = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/test-scores`
export const testScore_grid = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/mapped-test-scores`
export const post_score_delete_id = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/mapped-test-score`
export const test_score_dropdown_data = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/mapped-test-scores`
export const post_group_delete_id_grid = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/test-mapping-group`
export const test_select = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/available-tests-list`
export const upload_csv = `${base_admission_url}/api/tools/import/admissions/test-score-mapping/load-field-collection`

//Tools>Import>Adm>TestScoreImport
export const testImport_select_item = `${base_admission_url}/api/tools/import/admissions/test-scores/select-items`
export const testImport_add = `${base_admission_url}/api/tools/import/admissions/test-scores/import`
export const get_new_record = `${base_admission_url}/api/tools/import/admissions/test-scores/students/new`
export const get_exact_record = `${base_admission_url}/api/tools/import/admissions/test-scores/students/exact`
export const get_part_exact_record = `${base_admission_url}/api/tools/import/admissions/test-scores/students/partexact`
export const get_fuzzy_exact_record = `${base_admission_url}/api/tools/import/admissions/test-scores/students/fuzzy`
export const get_review_process = `${base_admission_url}/api/tools/import/admissions/test-scores/review-students`
export const finsih_import = `${base_admission_url}/api/tools/import/admissions/test-scores/finish-import`
export const clear_semaphore_testScore = `${base_admission_url}/api/tools/import/admissions/test-scores/clear-semaphore`
export const stored_procedures_list = `${base_admission_url}/api/tools/import/admissions/test-scores/stored-procedures-list`
export const uncheck_first_grid = `${base_admission_url}/api/tools/import/admissions/test-scores/exclude`
export const uncheck_second_grid_exact = `${base_admission_url}/api/tools/import/admissions/test-scores/un-link-student`
export const check_second_grid_exact = `${base_admission_url}/api/tools/import/admissions/test-scores/link-student`
export const check_first_grid = `${base_admission_url}/api/tools/import/admissions/test-scores/include`
export const map_second_grid = `${base_admission_url}/api/tools/import/admissions/test-scores/students/matched`
//Prospect-Student-Import
export const prospect_student_import_select_item = `${base_admission_url}/api/tools/import/admissions/prospect-student/select-items`
export const prospect_student_import_generic_job = `${base_admission_url}/api/tools/import/admissions/prospect-student/generic-job`
export const prospect_student_import_match_record = `${base_admission_url}/api/tools/import/admissions/prospect-student/match-record`
export const prospect_student_import_review_process = `${base_admission_url}/api/tools/import/admissions/prospect-student/review-process`
export const prospect_student_import_finish = `${base_admission_url}/api/tools/import/admissions/prospect-student`


//Tools>Export>TexasStateReport>CensusStudentSchedule
export const term_select_items = `${base_admission_url}/api/tools/export/texas-state-report-cbm0cs/data-entry/term-select-items`
export const holding_data = `${base_admission_url}/api/tools/export/texas-state-report-cbm0cs/holding-data`
export const holding_select_items = `${base_admission_url}/api/tools/export/texas-state-report-cbm0cs/data-entry/holding-select-items`
export const fixed_length = `${base_admission_url}/api/tools/export/texas-state-report-cbm0cs/holding-data/fixed-length`
export const fixed_length_export = `${base_admission_url}/api/tools/export/texas-state-report-cbm0cs/holding-data/fixed-length/export`

//Tools>ExportNYS Sysris
export const newyork_select_items = `${base_admission_url}/api/tools/export/new-york-state-reports/select-items`
export const newyork_contact_grid = `${base_admission_url}/api/tools/export/new-york-state-reports/contacts`
export const export_file = `${base_admission_url}/api/tools/export/new-york-state-reports/export-nysr`

//  Tools - Export - Texas State Reports - CBM0C1 Student Census
export const student_census_select = `${base_admission_url}/api/tools/export/texas-state-reports/cbm0c1-student-census/parameters/select-items`
export const student_census_term_change = `${base_admission_url}/api/tools/export/texas-state-reports/cbm0c1-student-census/term-change-detail`

//Tools>Export>TexasStateReport>CBM00CCE classes
export const parameter_select_items = `${base_admission_url}/api/tools/export/texas-state-reports/cbm00cce-classes/parameters-select-items`
export const parameter_refresh_data = `${base_admission_url}/api/tools/export/texas-state-reports/cbm00cce-classes/parameters-refresh-data`
export const classes_data = `${base_admission_url}/api/tools/export/texas-state-reports/cbm00cce-classes/data`
export const classes_export = `${base_admission_url}/api/tools/export/texas-state-reports/cbm00cce-classes/export`

export const student_census_data = `${base_admission_url}/api/tools/export/texas-state-reports/cbm0c1-student-census/data`
export const student_census_fixed_length = `${base_admission_url}/api/tools/export/texas-state-reports/cbm0c1-student-census/fixed-length`
export const student_census_parameters = `${base_admission_url}/api/tools/export/texas-state-reports/cbm0c1-student-census/parameters`

// Tools - Import - Canadian Imports - ASN Import
export const asn_import = `${base_admission_url}/api/tools/import/canadian/asn-import/initiate-data`
export const asn_import_data = `${base_admission_url}/api/tools/import/canadian/asn-import/import-data`

//Tools > Import > Admission > Apply Texas Import
export const import_file = `${base_admission_url}/api/tools/import/admissions/texas-import/import-file`

//Tools>CBM002 Success Initiative
export const cbm002_successinit = `${base_admission_url}/api/tools/export/texas-state-report-cbm002`
export const criteria_selectitems = `${cbm002_successinit}/criteria/select-items`
export const subjects_types_selectitems = `${cbm002_successinit}/subjects-types/select-items`
export const course_types_selectitems = `${cbm002_successinit}/course-types/select-items`
export const get_subjects_types = `${cbm002_successinit}/subjects-types`
export const get_course_types = `${cbm002_successinit}/course-types`
export const criteria_update = `${cbm002_successinit}/criteria/update-parameters`
export const scores_selectitems = `${cbm002_successinit}/scores/select-items`
export const scores_requirements = `${cbm002_successinit}/scores/requirements`
export const tests_scores = `${cbm002_successinit}/scores/tests-scores`
export const data_export = `${cbm002_successinit}/data-export`
export const data_export_file = `${cbm002_successinit}/export`
export const data_export_delete = `${cbm002_successinit}/delete`

// Faculty Manager - Transformation Templates
export const baseTransformationTemplateUrl = `${base_admission_url}/api/manager/import-export/transformation-template`;
export const transformationTemplatesUrl = `${baseTransformationTemplateUrl}/templates`;
export const transformationTemplatesUrlFortemplateDropdown = `${baseTransformationTemplateUrl}/select-items`;
export const studentTemplateSelection = `${baseTransformationTemplateUrl}/templates`;
export const transformationTemplateUrl = `${baseTransformationTemplateUrl}/template`;
export const transformationTemplateTypeDropdownUrl = `${baseTransformationTemplateUrl}/template-type-select-items`;
export const getStudentTranscriptDoubleColumnData = `${base_admission_url}/api/academic/student-transcript/students-transcript-doubleColumn-report`;
export const getStudentIdsFromStudentSelection = `${base_admission_url}/api/academic/student-transcript/select-students-for-transcript`;
export const advisor_getStudentTranscriptDoubleColumnData = `${base_admission_url}/api/academic/student-transcript/advisor-transcript-report`;
// Faculty Manager - Faculty - maintenance
export const faculty_url = `${base_admission_url}/api/manager/faculty-maintenance`;
export const faculty_searchData = `${faculty_url}/faculty`;
export const faculty_dropdown = `${faculty_url}/faculty/select-items`;
export const portal_dropdown = `${faculty_url}/portal/select-items`;
export const faculty_address = `${faculty_url}/address`;
export const faculty_details = `${faculty_url}/facultyDetails`;
export const faculty_portal = `${faculty_url}/portal`;
export const faculty_portalupdate = `${faculty_url}/faculty-portal`;
export const faculty_access_emailInfo = `${faculty_url}/faculty/email-portal-information`;
export const faculty_access_reset_password = `${faculty_url}/faculty/reset-password`;



// Tools - Export - Texas State Reports - CBM0E1 End Sem Students
export const end_sem_students = `${base_admission_url}/api/tools/export/texas-state-report-cbm0e1/end-sem-student/select-items`
export const end_sem_students_refresh_data = `${base_admission_url}/api/tools/export/texas-state-report-cbm0e1/end-sem-student/refresh-data`
export const end_sem_students_data = `${base_admission_url}/api/tools/export/texas-state-report-cbm0e1/end-sem-student/data`
export const end_sem_students_export = `${base_admission_url}/api/tools/export/texas-state-report-cbm0e1/end-sem-student/export`

// Tools-Export-Texas state reports - DB Systems
export const select_db = `${base_admission_url}/api/tools/export/texas-state-report-tfads/select-items`
export const data_get = `${base_admission_url}/api/tools/export/texas-state-report-tfads/data`
export const export_get = `${base_admission_url}/api/tools/export/texas-state-report-tfads/export`
export const refresh_data = `${base_admission_url}/api/tools/export/texas-state-report-tfads/refresh-data`
export const export_csv_data = `${base_admission_url}/api/tools/export/texas-state-report-tfads/export-csv`
export const texas_state_report_tfads = `${base_admission_url}/api/tools/export/texas-state-report-tfads`
export const export_fixed_length = `${base_admission_url}/api/tools/export/texas-state-report-tfads/fixed-length`





//Tools>Export>Texas State Reports> CBM00A-CE-Students
export const cbm_ce_students = `${base_admission_url}/api/tools/export/texas-state-reports/cbm00A-cestudents`
export const cbm_ce_students_parameter = `${cbm_ce_students}/parameters`
export const cbm_ce_students_data = `${cbm_ce_students}/data`
export const cbm_ce_students_export = `${cbm_ce_students}/export`

//Tools > Import > Billing > Import
export const billing_import_initiate = `${base_admission_url}/api/tools/import/billing/billing-import/initiate`
export const clear_semaphore_import = `${base_admission_url}/api/tools/import/billing/billing-import/clear-semaphore`
export const file_validation = `${base_admission_url}/api/tools/import/billing/billing-import/file-validation`
export const transaction_import = `${base_admission_url}/api/tools/import/billing/billing-import/transactions-to-import`
export const exception_data = `${base_admission_url}/api/tools/import/billing/billing-import/exceptions`
export const import_data = `${base_admission_url}/api/tools/import/billing/billing-import/import`


export const reference_table = `${base_admission_url}/api/manager/lookup-table-options/table-maintenance/reference-tables`

export const glossary_table = `${base_admission_url}/api/manager/lookup-table-options/table-maintenance/glossary-table/glossary-catagories`
export const glossary_category = `${base_admission_url}/api/manager/lookup-table-options/table-maintenance/elements-category`

export const activityLookup_table = `${reference_table}/activity-lookup`


//Tools>Import > Financial Aid > EDE Awards
export const ede_awards = `${base_admission_url}/api/tools/import/financial-aid/ede-awards`
export const ede_awards_criteria = `${ede_awards}/criteria`
export const ede_awards_import = `${ede_awards}/import`
export const ede_awards_criteria_selectItems = `${ede_awards_criteria}/select-items`
export const ede_awards_criteria_finAidYrs = `${ede_awards_criteria}/financial-aid-years`
export const ede_awards_criteria_finCalenders = `${ede_awards_criteria}/financial-calendars`
export const ede_awards_criteria_ClearSemphore = `${ede_awards_criteria}/clear-semaphore`
export const ede_awards_criteria_initiate = `${ede_awards_criteria}/initiate`
export const ede_awards_list = `${ede_awards}/list`
export const ede_awards_distribution = `${ede_awards}/distribution`
export const ede_awards_import_selectItems = `${ede_awards_import}/select-items`
export const ede_awards_import_awardStatusMapping = `${ede_awards_import}/award-status-mapping`

// Tools > Export > Texas State Reports > CBM008 Faculty
export const cbm008_selectitems = `${base_admission_url}/api/tools/export/texas-state-report-cbm008/select-items`
export const cbm008_refresh_data = `${base_admission_url}/api/tools/export/texas-state-report-cbm008/cmboo8-data`
export const cbm008_fixed_length = `${cbm008_refresh_data}/fixed-length`
export const cbm008_fixed_length_export = `${cbm008_fixed_length}/export`

//Tools > Import > Admission > Bulk Import
export const bulk_import = `${base_admission_url}/api/tools/import/admissions/bulk-prospect-import/bulk-import`

//CAMS Manager>refrenceTable>GridGroup9
export const major_option_one = `${base_admission_url}/api/manager/lookup-table-options/major-option-one`
export const major_option_two = `${base_admission_url}/api/manager/lookup-table-options/major-option-two`

//System Manager > Reference table > CommunicationCost
export const commcost_Select_Items = `${base_admission_url}/api/manager/lookup-table-options/table-maintenance/reference-tables/comm-cost/select-items`
export const commcost_data = `${base_admission_url}/api/manager/lookup-table-options/table-maintenance/reference-tables/comm-cost`
export const costcenter_Select_items = `${base_admission_url}/api/manager/lookup-table-options/table-maintenance/reference-tables/cost-center/select-items`

//Password-flow
export const change_password = `${base_admission_url}/api/manager/configuration/user-administration/change-password`
export const expire_password = `${base_admission_url}/api/Login/ChangePassword/change-password`

export const menu_listByOrder = `${base_admission_url}/api/manager/configuration/user-administration/menu-list-by-order`
export const side_BarMenu = `${base_admission_url}/api/manager/configuration/user-administration/sidebar-menu`
export const user_Role_Order = `${base_admission_url}/api/manager/configuration/user-administration/user-role-order`
export const edit_role_get_updated = `${base_admission_url}/api/manager/configuration/role-administration/menus-elements`
export const user_search_role = `${base_admission_url}/api/manager/configuration/user-administration/search-user-role-order`
export const search_menu_elements = `${base_admission_url}/api/manager/configuration/role-administration/search-menus-elements`

//PowerBI-GET
export const get_Urls = `${base_admission_url}/api/manager/configuration/user-administration/power-bi-url`

//System Manager > Report Configuration
export const report_config_select = `${base_admission_url}/api/manager/report-configuration/select-items`
export const report_config_list = `${base_admission_url}/api/manager/report-configuration/list`
export const report_config = `${base_admission_url}/api/manager/report-configuration`
//Adimission >Prospect>prospect Notes
export const prospect_notes_grid = `${base_admission_url}/api/prospect/prospect-notes`

//Health DB check
export const login_hc = `${base_admission_url}/api/login/health-check`;
export const admission_hc = `${base_admission_url}/api/admissions/health-check`;
export const billing_hc = `${base_admission_url}/api/billing/health-check`;
export const financial_aid_hc = `${base_admission_url}/api/financial-aid/health-check`;
export const manager_hc = `${base_admission_url}/api/manager/health-check`;
export const registration_hc = `${base_admission_url}/api/registration/health-check`;
export const tools_hc = `${base_admission_url}/api/tools/health-check`;

//sidebar report menu
export const reportMenu = `${base_admission_url}/api/manager/configuration/user-administration/report-sidebar-menu`;
export const dataQueryDropdown = `${base_admission_url}/api/tools/data-query/select-items`;
export const dataQuery = `${base_admission_url}/api/tools/data-query/list`;

export const setting_pwd = `${base_admission_url}/api/manager/configuration/settings`
export const faculty_assigment = `${base_admission_url}/api/offering/maintenance/schedule-faculty`
export const T2202_dropdown = `${base_admission_url}/api/billing/T2202A-report/select-items`;
export const print_history_GL = `${base_admission_url}/api/billing/accounting/recreate/general-ledger/history/print`;
export const T2202_Record = `${base_admission_url}/api/billing/T2202A-report/records`;
export const T2202_Record_Print = `${base_admission_url}/api/billing/T2202A-report/t2202a-report`
export const export_t2202 = `${base_admission_url}/api/billing/T2202A-report/export-student-portal`

//T4AReport
export const T4A_report = `${base_admission_url}/api/billing/T4A-report`;
export const T4A_report_dropdown = `${T4A_report}/select-items`;
export const T4A_report_initiate = `${T4A_report}/initiate-T4AForm`
export const T4A_recordset_portal = `${T4A_report}/recordset-portal`

//1098T Form
export const form1098T_dropdown = `${base_admission_url}/api/billing/1098TForm/select-items`;
export const form1098T_standard_report = `${base_admission_url}/api/billing/1098TForm/1098Form-Standard-Report`;

//Registration Academic reports
export const attendance_report_dropdown = `${base_admission_url}/api/attendance/reports/byor/select-items`;
export const attendance_report_adbs = `${base_admission_url}/api/attendance/reports/byor/attendance-detail-by-student`;
export const attendace_report_adbc = `${base_admission_url}/api/attendance/reports/byor/attendance-detail-by-course`;
export const attendance_report_asbcch = `${base_admission_url}/api/attendance/reports/byor/attendance-summary-by-course-completion-hours`;
export const attendace_report_asbsch = `${base_admission_url}/api/attendance/reports/byor/attendance-summary-by-student-completion-hours`;
export const attendance_report_ans = `${base_admission_url}/api/attendance/reports/byor/attendance-no-show`;
export const attendace_report_ach = `${base_admission_url}/api/attendance/reports/byor/attendance-completion-hours`;
export const attendace_report_adbcch = `${base_admission_url}/api/attendance/reports/byor/attendance-detail-by-course-completion-hours`;
export const attendace_report_adbsch = `${base_admission_url}/api/attendance/reports/byor/attendance-detail-by-student-completion-hours`;
export const attendance_report_asbs = `${base_admission_url}/api/attendance/reports/byor/student/attendance-summary`;
export const attendace_report_asbc = `${base_admission_url}/api/attendance/reports/byor/course/attendance-summary`;

//enrollment
export const enrollment_dropdown = `${base_admission_url}/api/financial-aid/report/status-load/enrollment/select-items`;
export const multi_student_dropdown = `${base_admission_url}/api/financial-aid/reports/award-letters/multi-students/select-items`;
export const student_status_dropdown = `${base_admission_url}/api/financial-aid/reports/award-letters/student-status/select-items`;
export const reports_dropdown = `${base_admission_url}/api/financial-aid/reports/award-letters/reports/select-items`;
export const enrollment_detail = `${base_admission_url}/api/financial-aid/report/status-load/enrollment/detail`;

//awardLetters
export const award_criteria_dropdown = `${base_admission_url}/api/financial-aid/reports/award-letters/award-criteria/select-items`;
export const finAcademicYear_dropdown = `${base_admission_url}/api/financial-aid/reports/award-letters/award-criteria/fin-aid-years-list`;
export const getReportData = `${base_admission_url}/api/financial-aid/reports/award-letters/columns-by-package-report`;
export const mcColumnsByPackageReport = `${base_admission_url}/api/financial-aid/reports/award-letters/mc-columns-by-package-report`;
export const standardByPackageReport = `${base_admission_url}/api/financial-aid/reports/award-letters/standard-by-package-report`;
export const mcStandardByPackageReport = `${base_admission_url}/api/financial-aid/reports/award-letters/mc-standard-by-package-report`;
export const standardByAward = `${base_admission_url}/api/financial-aid/reports/award-letters/standard-by-award-report`;
export const mcStandardByAward = `${base_admission_url}/api/financial-aid/reports/award-letters/standard-by-award-report`;


//Tools Report
export const tools_validate_reports = `${base_admission_url}/api/tools/processes/registration/transcript-batches/Validate-Report`;
export const tools_transcript_batches = `${base_admission_url}/api/tools/processes/registration/transcript-batches/Transcript-Batch`;

//1098tT Form
export const reports_records = `${base_admission_url}/api/billing/1098TForm/1098Form-records`;
export const reports_add_record = `${base_admission_url}/api/billing/1098TForm`;
export const criteria_initiate = `${base_admission_url}/api/billing/1098TForm/initiate`;

//1098T Export
export const header_select_Items = `${base_admission_url}/api/billing/report/t1098-export/select-items`;
export const table_typeB = `${base_admission_url}/api/billing/report/t1098-export/bind/type-b`;
export const header_defaultValue = `${base_admission_url}/api/billing/report/t1098-export/default-values`;
export const header_initiate = `${base_admission_url}/api/billing/report/t1098-export/initiate`;
export const table_typeC = `${base_admission_url}/api/billing/report/t1098-export/bind/type-c`;
export const table_typeA = `${base_admission_url}/api/billing/report/t1098-export/bind/type-a`;
export const export_downloadFile = `${base_admission_url}/api/billing/report/t1098-export/export`;
export const form1098T_VL = `${base_admission_url}/api/billing/1098TForm/1098Form-Varification-Report`;

//SSN Migration
export const migration_get = `${base_admission_url}/api/ssn/migration/get/details`;
export const migration_process = `${base_admission_url}/api/ssn/migration/table-data`;

//Publisher Event Setup
export const lookup_get = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/table-list`;
export const table_get = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/enabled-data`;
export const table_delete = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/enabled-entity`;
export const landing_search = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/enabled-data-search`;
export const entity_search = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/entity-fields`;
export const properties_grid = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/enabled-fields`;
export const publisher_save = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/enabled-data`;
export const properties_search = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/entity-fields/search`;
export const integration_search = `${base_admission_url}/api/azure-integration/publisher-event-setup/integration-pub-settings/topic-list`;
export const integration_add = `${base_admission_url}/api/azure-integration/publisher-event-setup/integration-pub-settings`;
export const integration_delete = `${base_admission_url}/api/azure-integration/publisher-event-setup/integration-pub-settings`;
export const properties_delete = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/enabled-entity/attribute-delete`;
export const integration_get = `${base_admission_url}/api/azure-integration/publisher-event-setup/integration-pub-settings`;
export const prop_key_check = `${base_admission_url}/api/azure-integration/publisher-event-setup/cdc/primary-validation`;
//Grade cards
export const criteria_select = `${base_admission_url}/api/reports/grade-reports/grade-cards/criteria/select-items`;
export const multiStudent_select = `${base_admission_url}/api/reports/grade-reports/grade-cards/multi-students/select-items`;
export const criteria_default = `${base_admission_url}/api/reports/grade-reports/grade-cards/criteria/default-values`;
export const criteria_default_put = `${base_admission_url}/api/reports/grade-reports/grade-cards/criteria/default-value`;
export const print_select = `${base_admission_url}/api/reports/grade-reports/grade-cards/print/select-items`;
export const standard_full_page = `${base_admission_url}/api/reports/grade-reports/grade-cards/high-standard-advisors`;
export const hope_full_page = `${base_admission_url}/api/reports/grade-reports/grade-cards/print/hope`;
export const number_full_page = `${base_admission_url}/api/reports/grade-reports/grade-cards/print/number-full`;
export const advisor_full_page = `${base_admission_url}/api/reports/grade-reports/grade-cards/advisor-full-page`;
export const highschool_full_page = `${base_admission_url}/api/reports/grade-reports/grade-cards/high-school-full-page`;
export const hope_advisors = `${base_admission_url}/api/reports/grade-reports/grade-cards/print/hope-advisors`;
//Schedule
export const standard_by_advisor = `${base_admission_url}/api/academic/schedule/standard-by-advisor`;
export const standard_by_advisor_book = `${base_admission_url}/api/academic/schedule/standard-by-advisor-with-book`;
export const activity_by_student = `${base_admission_url}/api/academic/schedule/activity-by-student`;
export const condensed_by_advisor = `${base_admission_url}/api/academic/schedule/condensed-by-advisor`;
export const standard_by_student = `${base_admission_url}/api/academic/schedule/standard-by-student`;
export const standard_by_student_book = `${base_admission_url}/api/academic/schedule/standard-by-student-with-book`;
export const condensed_by_student = `${base_admission_url}/api/academic/schedule/condensed-by-student`;
export const condensed_by_student_waitingList = `${base_admission_url}/api/academic/schedule/condensed-by-student-writingList`;
export const matrix = `${base_admission_url}/api/academic/schedule/matrix`;
//Print Offering
export const courseList = `${base_admission_url}/api/offering/print-offering/course-list`;
export const courseListbyAccessCampus = `${base_admission_url}/api/offering/print-offering/course-list/access-campus`;
export const courseListWBooks = `${base_admission_url}/api/offering/print-offering/course-list/books`;
export const courseListWHours = `${base_admission_url}/api/offering/print-offering/course-list/hours`;
export const courseListByDivision = `${base_admission_url}/api/offering/print-offering/course-list/division`;
export const courseListByFaculty = `${base_admission_url}/api/offering/print-offering/course-list/faculty`;
export const courseListByLocation = `${base_admission_url}/api/offering/print-offering/course-list/location`;
export const courseListbyUserDefined = `${base_admission_url}/api/offering/print-offering/course-list/user-defined-group`;
export const waitingListbyStudent = `${base_admission_url}/api/offering/print-offering/waiting-list-by-student`;
export const courseListByWaitingList = `${base_admission_url}/api/offering/print-offering/waiting-list`;
export const room_conflict = `${base_admission_url}/api/offering/print-offering/room-conflict`;
export const room_utilization = `${base_admission_url}/api/offering/print-offering/room-utilization`;
export const enrollment_Summary = `${base_admission_url}/api/offering/print-offering/enrollment-summary`;
export const course_evaluation = `${base_admission_url}/api/offering/print-offering/course-evaluation`;
//Course Management Roles
export const roles_get = `${base_admission_url}/api/manager/cams-portal/course-management-role/roles`;
export const course_info = `${base_admission_url}/api/manager/cams-portal/course-management-role/course-info-for-role`;
export const role_add = `${base_admission_url}/api/manager/cams-portal/course-management-role/add`;
export const course_Info_get = `${base_admission_url}/api/manager/cams-portal/course-management-role/course-info-on-edit`;
export const role_update = `${base_admission_url}/api/manager/cams-portal/course-management-role/update`;
export const faculty_role_save = `${base_admission_url}/api/manager/cams-portal/course-management-role/faculty-role`;
export const faculty_get = `${base_admission_url}/api/manager/cams-portal/course-management-role/faculty-role/select-items`
export const faculty_search = `${base_admission_url}/api/manager/cams-portal/course-management-role/faculty`
//Statements
export const options_get = `${base_admission_url}/api/billing/report/statementreport/statement-select-items`;
export const message_get = `${base_admission_url}/api/billing/report/statementreport/transcript-comment`;
export const standard_report = `${base_admission_url}/api/billing/report/statementreport/standard`;
export const standard_Basic_report = `${base_admission_url}/api/billing/report/statementreport/combined-with-detailed-schedules`;
export const standard_Detail = `${base_admission_url}/api/billing/report/statementreport/standard-detail`;
export const standard_Aging = `${base_admission_url}/api/billing/report/statementreport/standard-aging`;
export const pastDue_Letter = `${base_admission_url}/api/billing/report/statementreport/past-due-letter`;
export const basic_Aging = `${base_admission_url}/api/billing/report/statementreport/standard-basic-aging`;
export const detail_Aging = `${base_admission_url}/api/billing/report/statementreport/standard-detail-aging`;
//Enrollment Verification
export const enrollment_verification_get_select_item = `${base_admission_url}/api/report/enrollmentVerification/select-items`;
export const enrollment_verification_standard_report = `${base_admission_url}/api/report/enrollmentVerification/standard`;
//Transfer Report
export const transfer_report_selectitems = `${base_admission_url}/api/academic/transfer/print/select-item`;
export const transfer_Standardreport = `${base_admission_url}/api/academic/transfer/report/standard`;
export const degreeAudit_report = `${base_admission_url}/api/degree-audit/student-evaluation/audit-degree-report`;
//DocumentTracking- Academic Year
export const get_academicYear = `${base_admission_url}/api/financial-aid/maintenance/documents/academicYear/select-items`;
export const get_financialAidYear = `${base_admission_url}/api/financial-aid/maintenance/documents/financial-aid-year`;
export const edit_financialAidYear = `${base_admission_url}/api/financial-aid/maintenance/documents/fin-year`;
// ADD PELL
export const add_pell = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/amounts-based-pell`;
export const delete_pell = `${base_admission_url}/api/financial-aid/packaging/pioritized/award-methodology/amount-based-sql`;
//Housing Module Forms List and Search

//Housing Module Add Forms
export const formsAPIs = {
  add:`${base_admission_url}/api/tools/forms/add`,
  search: `${base_admission_url}/api/tools/forms/search`,
  update: `${base_admission_url}/api/tools/forms`, /*{formId}*/
  delete: `${base_admission_url}/api/tools/forms`,/*{formId}*/
  get: `${base_admission_url}/api/tools/forms`, /*{formId}*/
  checkName: `${base_admission_url}/api/tools/forms/check-name`, /*{formname}*/
    responses: `${base_admission_url}/api/tools/forms/responses`, /* {formid} */
    response: `${base_admission_url}/api/tools/forms/response`, /* {formid}/{resultid} */
}

export const fileAPIs = {
    file:`${base_admission_url}/api/file`, /*querystring [path] fileinfo*/
}
//Configuration Integration Tab
export const Integration_Select = `${base_admission_url}/api/manager/configuration/integration/select-items`;
export const save_Integration = `${base_admission_url}/api/manager/configuration/integrationLMS/saveIntegrationLms`;

//Amenity
export const housingAPIs = {
    amenity: {
        add: `${base_admission_url}/api/amenities/add`,
        search: `${base_admission_url}/api/amenities/search`,
        get: `${base_admission_url}/api/amenities`, /* amenityId */
        delete: `${base_admission_url}/api/amenities`, /* amenityId */
        update: `${base_admission_url}/api/amenities`, /* amenityId */
        categories: `${base_admission_url}/api/amenities/categories`,
    },
    property: {
        search: `${base_admission_url}/api/student-life/housing/properties/search`,
        searchDropDown: `${base_admission_url}/api/student-life/housing/properties/search/select-items`,
        addStep1: `${base_admission_url}/api/student-life/housing/properties/step1/add`,
        step1DropDown: `${base_admission_url}/api/student-life/housing/properties/step1/select-items`,
        updateStep1: `${base_admission_url}/api/student-life/housing/properties/step1`,
        step1PrePopulated: `${base_admission_url}/api/student-life/housing/properties/step2/pre-populate-select-items`,
        step3DropDown: `${base_admission_url}/api/student-life/housing/properties/step3/select-items`,
        floorDetails:`${base_admission_url}/api/student-life/housing/properties/step3/floor-details`,
        prePopulateSelectItems:`${base_admission_url}/api/student-life/housing/properties/step3/pre-populate-select-items`,
        addStep2: `${base_admission_url}/api/student-life/housing/properties/step2/add-floor`,
        updateStep2: `${base_admission_url}/api/student-life/housing/properties/step2/update-floor`,
        getStep1Details: `${base_admission_url}/api/student-life/housing/properties/step1-property-details`,
        getStep2Details: `${base_admission_url}/api/student-life/housing/properties/step2-property-details`,
        getStep3Details: `${base_admission_url}/api/student-life/housing/properties/step3-property-details`,
        addRooms:`${base_admission_url}/api/student-life/housing/properties/step3/add-rooms`,
        updateRooms:`${base_admission_url}/api/student-life/housing/properties/step3/update-rooms`,
        addUnits:`${base_admission_url}/api/student-life/housing/properties/step3/add-units`,
        updateUnits:`${base_admission_url}/api/student-life/housing/properties/step3/update-units`,
        deleteProperties: `${base_admission_url}/api/student-life/housing/properties`,
    }
};
