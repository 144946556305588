import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';

/**
 * @param {{
 * label: string,
 * labelId: string, 
 * formikValue: Object, 
 * formikChange: Function, 
 * disabled:boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean,
 * checked: Boolean,
 * addCheckBoxClass: String 'Add custom class to adjust checkbox'
 * }} props 
 */


export const SwitchInput = (props) => {
    return (
        <FormControlLabel control={<Switch color="primary"/>}  />
    )
}
