import React, { useState, useEffect } from "react";
import PopupHeader from "../../../../pages/common-page/popupHeader";
import { Button, Card, CardContent, TextareaAutosize, Grid, } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormGroup, ButtonWrapperFilter, useStyles, Splitter, ButtonWrapper, } from "../../../../styles/common-styles";
import { SelectInput, TextInput, DateInput, CheckBoxInput, TextLookUpInput, RadioInput } from "../../../../components/Forms";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import { toastMessage } from '../../../../utils/Message/toasts';
import { useTranslation } from "react-i18next";
import { parseNumber } from '../../../../helper';
import currencyPipe from '../../../../helper/currency'
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { financialAid_finacialaid } from "../../../../services/navigation_folder/financialAid";
import AddCostOfAttendence from "../maintenanceAidTab/costOfAttendence"
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import StudentContactLookup from "./studentContactLookup";
import AddBorrowerDetails from "./addBorrowerDetails";
import AddCounsellingDefaultBorrower from "./addcounsellingdefaultborrower";
import AddMPNDefaultBorrower from "./addmpndefaultborrower";
import { getApi, postApi, putApi, deleteApi } from '../../../../services/apiBase';
import { get_financialStatus_details, save_financialStatus_details, update_financialStatus_details, get_academicyearDependency, get_fa_yearbudgets, mpn_gridList, counselings_gridList, delete_financialStatus_counseling, delete_financialStatus_mpn, copy_borrowerAwardPerType } from "../../../../services/apiURL";
import GenericModal from '../../../../components/Modal/GenericModal';
import InfoMessage from "../../../../utils/Message/info-message";
import { scrollIntoViewHelper } from "../../../../helper";
import { useSelector } from "react-redux";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";




const AddFinancialAidStatusDetails = ({ addDatas, onClose, selectedSelected, selectedStatusRow }) => {

    const [show, toggleShow] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showAddData, setShowAddData] = useState(false);
    const [showAddTransDoc, setShowAddTransDoc] = useState(false);
    const [showBorrowerDetails, setShowBorrowerDetailsc] = useState(false);
    const [showAddMPN, setShowAddMPN] = useState(false);
    const [showAddCounselling, setShowAddCounselling] = useState(false);
    const [showDynamicTableusagelimits, setShowDynamicTableusagelimits] = useState(true);
    const [showStudentContactLookup, setShowStudentContactLookup] = useState(false);
    const [formValues, setFormValues] = useState(initialValues);
    const [counselingRow, setCounselingRow] = useState([]);
    const [MpnRow, setMpnRow] = useState([]);
    const [selectBorrower, setSelectBorrower] = useState('');
    const [selectedCounselingRow, setSelectedCounselingRow] = useState('');
    const [selectedCODRow, setSelectedCODRow] = useState('');
    const [selectedCOARow, setSelectedCOARow] = useState('');
    const [BorrowerRow, setBorrowerRow] = useState([])
    const [BarrowerID, setBarrowerID] = useState(null)
    const [COARow, setCOARow] = useState([])
    const [isLoading, setIsLoadingCODRow] = useState(false);
    const [textData, setTextData] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [coaIndex, setCoaIndex] = useState(0);
    const [borrowIndex, setBorrowIndex] = useState(0);
    const [mpnIndex, setMpnIndex] = useState(0);
    const [showSAIFields, setShowSAIFields] = useState(false);
    const [Dropdown, setDropdown] = useState({
        academicYear: [],
        packageStatus: [],
        directCostType: [],
        dependentStatus: [],
        autoZeroEFC: [],
        documentsComplete: [],
        previousBachelorsDegree: [],
        enrolledGradProf: [],
        packagedForLoad: [],
        verificationStatus: [],
        pellLifeTimeLimit: [],
        acgEligibilityPaymentReason: [],
        studentDefaultOverpayCode: [],
        BorrowerPlusDefaultOverpayCode: [],
        financialAidYear: [],
        // SAI fields
        saiFormula: [],
        dependencyModel: [],
        studentConsSig: [],
        studentSpouseConsSig: [],
        parentConsSig: [],
        parentsSpousePartnerConsSig: []
    })

    const [VerFlagDropdown, setVerFlagDropdown] = useState({
        verificationTrackingFlag: [],
        faYear: []
    })

    const { t } = useTranslation([
        "translation",
        "financialAid",
        "financialAidHeadcells",
    ]);

    const transform = (val) => {
        return currencyPipe(val, { symbol: "", decimal: '.', separator: ',', precision: 2 }).format();
    }

    const initCurrencyFields = (value = 0) => {
        return transform(parseNumber(value))
    }

    const onSwipeCurrency = (e) => {
        const { name, value } = e.target;
        const amount = transform(parseNumber(value));
        formik.setFieldValue(name, amount);
        formik.setFieldValue("amount", amount);
    }

    const [isCopyDisabled, setCopyDisabled] = useState(false);

    const [costofAttendence] = useState([
        { id: "costLabel", numeric: true, disablePadding: false, label: t("costofAttendence.costLabel", { ns: "financialAidHeadcells" }), },
        { id: "costAmount", numeric: true, disablePadding: false, label: t("costofAttendence.costAmount", { ns: "financialAidHeadcells" }), },
        { id: "insertUserID", numeric: true, disablePadding: false, label: t("costofAttendence.insertUserID", { ns: "financialAidHeadcells" }), },
        { id: "insertTime", numeric: true, disablePadding: false, label: t("costofAttendence.insertTime", { ns: "financialAidHeadcells" }), },
        { id: "updateUserID", numeric: true, disablePadding: false, label: t("costofAttendence.updateUserID", { ns: "financialAidHeadcells" }), },
        { id: "updateTime", numeric: true, disablePadding: false, label: t("costofAttendence.updateTime", { ns: "financialAidHeadcells" }), },
    ]);

    const [borrowerPerAwardType] = useState([
        { id: 'awardType', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.awardType', { ns: 'financialAidHeadcells' }) },
        { id: 'borrower', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.borrower', { ns: 'financialAidHeadcells' }) },
        { id: 'awardTypeID', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.awardTypeID', { ns: 'financialAidHeadcells' }) },
        { id: 'borrowerID', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.borrowerID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialStatusID', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.financialStatusID', { ns: 'financialAidHeadcells' }) },
        { id: 'studentUID', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.studentUID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialBorrowerID', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.financialBorrowerID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertUSerID', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.insertUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.insertTime', { ns: 'financialAidHeadcells' }) },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.updateUser', { ns: 'financialAidHeadcells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('borrowerPerAwardType.updateTime', { ns: 'financialAidHeadcells' }) },

    ]);

    const [mpnDataforDefaultBorrower] = useState([
        { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'ownerUID', numeric: true, disablePadding: false, label: t('studentMPN.ownerUID', { ns: 'financialAidHeadcells' }) },
        { id: 'mpnIndicator', numeric: true, disablePadding: false, label: t('studentMPN.mpnIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'mpnid', numeric: true, disablePadding: false, label: t('studentMPN.mpnID', { ns: 'financialAidHeadcells' }) },
        { id: 'mpnStatusCode', numeric: true, disablePadding: false, label: t('studentMPN.mpnStatusCode', { ns: 'financialAidHeadcells' }) },
        { id: 'mpnLinkIndicator', numeric: true, disablePadding: false, label: t('studentMPN.mpnLinkIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'mpnExpDate', numeric: true, disablePadding: false, label: t('studentMPN.mpnExpirationDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'codAwardType', numeric: true, disablePadding: false, label: t('studentMPN.codAwardType', { ns: 'financialAidHeadcells' }) },
        { id: 'insertUserID', numeric: true, disablePadding: false, label: t('studentMPN.insertUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('studentMPN.insertTime', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('studentMPN.updateUserId', { ns: 'financialAidHeadcells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('studentMPN.updateTime', { ns: 'financialAidHeadcells' }), dateTime: true },

    ]);

    const [counsellingDataforDefaultBorrower] = useState([
        { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'ownerUID', numeric: true, disablePadding: false, label: t('studentCounselling.ownerUID', { ns: 'financialAidHeadcells' }) },
        { id: 'counselingType', numeric: true, disablePadding: false, label: t('studentCounselling.counsellingType', { ns: 'financialAidHeadcells' }) },
        { id: 'csn', numeric: true, disablePadding: false, label: t('studentCounselling.csn', { ns: 'financialAidHeadcells' }) },
        { id: 'entranceCounseling', numeric: true, disablePadding: false, label: t('studentCounselling.counselingComplete', { ns: 'financialAidHeadcells' }) },
        { id: 'counselingCompletionDate', numeric: true, disablePadding: false, label: t('studentCounselling.counselingCompletionDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'counselingExpirationDate', numeric: true, disablePadding: false, label: t('studentCounselling.counselingExpirationDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'counselingException', numeric: true, disablePadding: false, label: t('studentCounselling.counsellingException', { ns: 'financialAidHeadcells' }) },
        { id: 'insertUserID', numeric: true, disablePadding: false, label: t('studentCounselling.insertUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('studentCounselling.insertTime', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('studentCounselling.updateUserId', { ns: 'financialAidHeadcells' }) },
        { id: 'uPdateTime', numeric: true, disablePadding: false, label: t('studentCounselling.updateTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    ]);

    const onCloseSubPopupPage = (value) => {
        setShowAddData(false);
        setShowAddTransDoc(false)
        setShowBorrowerDetailsc(false)
        setShowAddMPN(false)
        setShowAddCounselling(false)
        getMpnData();
        getCounselingData();
        setBarrowerValue();
        getFAYearBudget();
        DefaultTable.resetTable("COATable");
        DefaultTable.resetTable("MpnTable");
        DefaultTable.resetTable("CounselingTable");
        DefaultTable.resetTable("BorrowerTable");
        setSelectedCODRow('');
        setSelectBorrower('');
        setSelectedCounselingRow('')
        // getMpnBorrowerData();
    }

    const initialValues = {
        academicYear: -1,
        faYear: 0,
        packageStatus: 0,
        directCostType: 0,
        dependentStatus: "Dependent",
        autoZeroEFC: "Yes",
        documentsComplete: "No",
        previousBachelorsDegree: "No",
        enrolledGradProf: "No",
        packagedForLoad: 0,
        verificationStatus: "",
        verificationTrackingFlag: "",
        pellLifeTimeLimit: "",
        acgEligibilityPaymentReason: "",
        studentDefaultOverpayCode: "N",
        BorrowerPlusDefaultOverpayCode: "N",
        narrative: "",
        percentPrimaryEFCforRemaining: 100,
        financialAidYear: 0,
        altPrimaryEFC: 0,
        monthsCOA: 0,
        packageEnrollmentIntensity: "",
        fisapTotalIncome: 0,
        initialPackagingDate: null,
        lastPackagingDate: null,
        lockPackaging: false,
        primaryBorrowerID: 0,
        primaryefc: 0,
        resolved: false,
        rigorousHighschoolProgramCode: "",
        verificationCode: "",
        defaultBorrowerforStudent: "",
        secondaryefc: 0,
        totalcosts: initCurrencyFields(0),
        remainingNeed: initCurrencyFields(0),
        sai: "",
        provisionalSAI: "",
        saiFormula: "",
        dependencyModel: "",
        studentConsSig: 0,
        studentSpouseConsSig: 0,
        studentConsSigDate: null,
        studentSpouseConsSigDate: null,
        parentConsSig: 0,
        parentsSpousePartnerConsSig: 0,
        parentConsSigDate: null,
        parentSpousePartnerConsSigDate: null,
        SAIField: 'true'
    };

    const validationSchema = yup.object({
        academicYear: yup.number()
            .min(0, t("validationMessages.isRequired", { what: t("commonLabel.academicYear", { ns: "financialAid" }) }))
            .required(t("validationMessages.isRequired", { what: t("commonLabel.academicYear", { ns: "financialAid" }) })),
        faYear: yup.number()
            .min(1, t("validationMessages.isRequired", { what: t("commonLabel.financialAidYear", { ns: "financialAid" }) }))
            .required(t("validationMessages.isRequired", { what: t("commonLabel.financialAidYear", { ns: "financialAid" }) })),
        packageEnrollmentIntensity:
            yup.string()
                .matches(/^([0-9]|[1-9][0-9]|100)$/, "Number outside of allowed range 0 - 100")
    });

    const formik = useFormik({
        initialValues: formValues || initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payloadRequest =
            {
                "studentUID": selectedSelected.studentUID,
                "academicYearUID": values.academicYear,
                "financialAidYearUID": values.faYear,
                "packageStatusUID": values.packageStatus,
                "directCostTypeUID": values.directCostType,
                "primaryEFC": values.primaryefc,
                "secondaryEFC": values.secondaryefc || 0,
                "percentPrimaryEFC": values.percentPrimaryEFCforRemaining,
                "altPrimaryEFC": values.altPrimaryEFC,
                "dependentStatus": values.dependentStatus.toString(),
                "automaticZeroEFC": values.autoZeroEFC,
                "lockPackaging": values.lockPackaging,
                "documentsComplete": values.documentsComplete,
                "bachelorsDegree": values.previousBachelorsDegree,
                "enrolledGradProf": values.enrolledGradProf,
                "initialPackageDate": values.initialPackagingDate,
                "lastPackageDate": values.lastPackagingDate,
                "fisapTotalIncome": Number(values.fisapTotalIncome),
                "packageAsLoadUID": values.packagedForLoad,
                "coA9Month": values.monthsCOA,
                "packageEnrollmentIntensity": (values.packageEnrollmentIntensity === "") ? 0 : parseInt(values.packageEnrollmentIntensity),
                "verificationCode": values.verificationCode,
                "verificationStatus": values.verificationStatus,
                "resolved": values.resolved,
                "verificationTrackingFlag": values.verificationTrackingFlag || "",
                "nsldsPellLifetimeLimit": values.pellLifeTimeLimit || "",
                "note": values.narrative,
                "acgEligibilityPaymentReasonCode": values.acgEligibilityPaymentReason,
                "rigorousHighschoolProgramCode": values.rigorousHighschoolProgramCode,
                "studentDefaultOverPayCode": values.studentDefaultOverpayCode,
                "borrowerDefaultOverPayCode": values.BorrowerPlusDefaultOverpayCode,
                "primaryBorrowerID": BarrowerID || null,
                "costs": (COARow) ? COARow : [],
                "borrowers": (BorrowerRow) ? BorrowerRow : [],
                "sai": (values.sai === "") ? null : values.sai,
                "provisionalSAI": (values.provisionalSAI === "") ? null : values.provisionalSAI,
                "saiFormula": values.saiFormula,
                "dependencyModel": values.dependencyModel,
                "studentConsent": (values.studentConsSig === "") ? 0 : values.studentConsSig,
                "studentSpouseConsent": (values.studentSpouseConsSig === "") ? 0 : values.studentSpouseConsSig,
                "studentConsentDate": values.studentConsSigDate,
                "studentSpouseConsentDate": values.studentSpouseConsSigDate,
                "parentConsent": (values.parentConsSig === "") ? 0 : values.parentConsSig,
                "parentSpousePartnerConsent": (values.parentsSpousePartnerConsSig === "") ? 0 : values.parentsSpousePartnerConsSig,
                "parentSpousePartnerConsentDate": values.parentSpousePartnerConsSigDate,
                "parentConsentDate": values.parentConsSigDate,
                "useSAI": (values.SAIField === 'true') ? true: false
            }
            if (selectedStatusRow) {
                putApi((`${update_financialStatus_details}/${selectedStatusRow.financialStatusID}`), payloadRequest).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    let newSelectedRow = {
                        ...values,
                        "primaryEFC": values.primaryefc,
                        "secondaryEFC": values.secondaryefc,
                        "academicYearUID": values.academicYear,
                        "academicYear": values.acdYearText,
                        "acgEligibilityPaymentReasonCode": values.acgEligibilityPaymentReason,
                        "automaticZeroEFC": values.autoZeroEFC,
                        "borrowerDefaultOverPayCode": values.BorrowerPlusDefaultOverpayCode,
                        "financialAidYearUID": values.faYear,
                        "financialAidYear": values.faYearText,
                        "initialPackageDate": values.initialPackagingDate,
                        "lastPackageDate": values.lastPackagingDate,
                        "coA9Month": values.monthsCOA,
                        "packageStatusUID": values.packageStatus,
                        "parentConsent": values.parentConsSig,
                        "parentConsentDate": values.parentConsSigDate,
                        "parentSpousePartnerConsent": values.parentsSpousePartnerConsSig,
                        "parentSpousePartnerConsentDate": values.parentSpousePartnerConsSigDate,
                        "nsldsPellLifetimeLimit": values.pellLifeTimeLimit,
                        "percentPrimaryEFC": values.percentPrimaryEFCforRemaining,
                        "bachelorsDegree": values.previousBachelorsDegree,
                        "studentConsent": values.studentConsSig,
                        "studentconsentDate": values.studentConsSigDate,
                        "studentSpouseConsent": values.studentSpouseConsSig,
                        "studentSpouseConsentDate": values.studentSpouseConsSigDate,
                        "directCostTypeUID": values.directCostType,
                        "financialStatusID": selectedStatusRow.financialStatusID,
                        "useSAI": (values.SAIField === 'true') ? true: false,
                        "note": values.narrative,
                        "studentDefaultOverPayCode": values.studentDefaultOverpayCode
                    }
                    DefaultTable.updateSelectedRow("statusTable", newSelectedRow);
                    onClose(false)
                }).catch((err) => {
                    console.log(err)
                })
            }
            else {
                postApi(save_financialStatus_details, payloadRequest).then((res) => {
                    if (res.data.statusCode == 400) {
                        setOpenAlert(true);
                        setTextData(res.data.data)
                    } else {
                        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
                        onClose(false)
                    }

                }).catch((err) => {
                    console.log(err)
                })
            }
        },
    });
    const classess = useStyles();


    const awardsusagelimits = (value) => {
        setShowDynamicTableusagelimits(true);
        // getMpnData();

    };

    const informedborrowinginfo = (value) => {
        setShowDynamicTableusagelimits(false);
        // getCounselingData();
    };

    const AddCostAttendance = (value) => {
        setShowAddData(true);
        setShowAddTransDoc(true)
        setSelectedCOARow('');
    };

    const AddBorrowerDetail = (value) => {
        setShowAddData(true);
        setShowBorrowerDetailsc(true)
        setSelectBorrower('')
    };

    const AddCounsellingDefault = (value) => {
        setShowAddData(value);
        setShowAddCounselling(value)
        addDatas(value)
        setSelectedCounselingRow('');
    };

    const AddMPNDefault = (value) => {
        setShowAddData(value);
        setShowAddMPN(value)
        addDatas(value)
        setSelectedCODRow('');
    };

    const handleStudentlookUp = () => {
        addDatas(true)
        setShowAddData(true);
        setShowStudentContactLookup(true)
    }

    React.useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
        DefaultTable.resetTable("COATable");
        DefaultTable.resetTable("MpnTable");
        DefaultTable.resetTable("CounselingTable");
        DefaultTable.resetTable("BorrowerTable");
    }, []);

    useEffect(() => {
        // setSelectBorrower(BorrowerRow)
        getFinancialData();
        getMpnData();
        getCounselingData();
        if (selectedStatusRow) {
            setBarrowerID(selectedStatusRow.primaryBorrowerID)
            setFormValues({
                ...formValues,
                SAIField: (selectedStatusRow.useSAI === true) ? 'true' : 'false',
                academicYear: selectedStatusRow.academicYearUID,
                faYear: selectedStatusRow.financialAidYearUID,
                packageStatus: selectedStatusRow.packageStatusUID,
                directCostType: selectedStatusRow.directCostTypeUID,
                dependentStatus: selectedStatusRow.dependentStatus,
                autoZeroEFC: selectedStatusRow.automaticZeroEFC,
                documentsComplete: selectedStatusRow.documentsComplete,
                previousBachelorsDegree: selectedStatusRow.bachelorsDegree,
                enrolledGradProf: selectedStatusRow.enrolledGradProf,
                packagedForLoad: selectedStatusRow.packageAsLoadUID || 0,
                verificationStatus: selectedStatusRow.verificationStatus,
                verificationTrackingFlag: selectedStatusRow.verificationTrackingFlag,
                pellLifeTimeLimit: selectedStatusRow.nsldsPellLifetimeLimit,
                acgEligibilityPaymentReason: selectedStatusRow.acgEligibilityPaymentReasonCode,
                studentDefaultOverpayCode: selectedStatusRow.studentDefaultOverPayCode,
                BorrowerPlusDefaultOverpayCode: selectedStatusRow.borrowerDefaultOverPayCode,
                narrative: selectedStatusRow.note,
                percentPrimaryEFCforRemaining: selectedStatusRow.percentPrimaryEFC,
                //financialAidYear: selectedStatusRow.financialAidYearUID,
                altPrimaryEFC: selectedStatusRow.altPrimaryEFC || 0,
                monthsCOA: selectedStatusRow.coA9Month || 0,
                fisapTotalIncome: selectedStatusRow.fisapTotalIncome,
                initialPackagingDate: selectedStatusRow.initialPackageDate || null,
                lastPackagingDate: selectedStatusRow.lastPackageDate || null,
                lockPackaging: selectedStatusRow.lockPackaging || false,
                defaultBorrowerforStudent: selectedStatusRow.primaryBorrower || null,
                primaryefc: selectedStatusRow.primaryEFC || 0,
                resolved: selectedStatusRow.resolved,
                rigorousHighschoolProgramCode: selectedStatusRow.rigorousHighschoolProgramCode,
                verificationCode: selectedStatusRow.verificationCode,
                // defaultBorrowerforStudent: selectedStatusRow.packageStatusUID || null,
                secondaryefc: selectedStatusRow.secondaryEFC,
                totalcost: selectedStatusRow.need,
                remainingNeed: selectedStatusRow.remainingNeed,
                sai: selectedStatusRow.sai,
                provisionalSAI: selectedStatusRow.provisionalSAI,
                saiFormula: selectedStatusRow.saiFormula,
                dependencyModel: selectedStatusRow.dependencyModel,
                studentConsSig: selectedStatusRow.studentConsent,
                studentSpouseConsSig: selectedStatusRow.studentSpouseConsent,
                studentConsSigDate: selectedStatusRow.studentconsentDate || null,
                studentSpouseConsSigDate: selectedStatusRow.studentSpouseConsentDate || null,
                parentConsSig: selectedStatusRow.parentConsent,
                parentsSpousePartnerConsSig: selectedStatusRow.parentSpousePartnerConsent,
                parentSpousePartnerConsSigDate: selectedStatusRow.parentSpousePartnerConsentDate || null,
                parentConsSigDate: selectedStatusRow.parentConsentDate || null,
                packageEnrollmentIntensity: selectedStatusRow.packageEnrollmentIntensity || 0
            })
        }
    }, [])



    const getFinancialData = () => {
        let payloadRequest =
        {
            "studentUId": selectedSelected.studentUID,
            "financialStatusId": (selectedStatusRow.financialStatusID) ? selectedStatusRow.financialStatusID : null
        }
        postApi(get_financialStatus_details, payloadRequest).then((res) => {
            const { academicYears, packageStatuses, directCosts, dependentStatuses, autoZeroEFC,
                documentsComplete, prevBachelorsDegree, enrolledGradProf, studentLoads, verificationStatuses,
                pellLifetimeLimits, acgEligibilityPaymentReasons, studentDefaultOverpayCodes,
                borrowerDefaultOverpayCodes, costsOfAttendance, financialBorrower, borrowerMPN,
                borrowerCounsellings, copyFinancialAidYears, saiFormula, dependency, studentConsSig, studentSpouseConsSig, parentConsSig, parentSpouseConsSig } = res.data.data
            setDropdown({
                ...Dropdown,
                academicYear: academicYears,
                packageStatus: packageStatuses,
                directCostType: directCosts,
                dependentStatus: dependentStatuses,
                autoZeroEFC: autoZeroEFC,
                documentsComplete: documentsComplete,
                previousBachelorsDegree: prevBachelorsDegree,
                enrolledGradProf: enrolledGradProf,
                packagedForLoad: studentLoads,
                verificationStatus: verificationStatuses,
                pellLifeTimeLimit: pellLifetimeLimits,
                acgEligibilityPaymentReason: acgEligibilityPaymentReasons,
                studentDefaultOverpayCode: studentDefaultOverpayCodes,
                BorrowerPlusDefaultOverpayCode: borrowerDefaultOverpayCodes,
                financialAidYear: copyFinancialAidYears,
                saiFormula: saiFormula,
                dependencyModel: dependency,
                studentConsSig: studentConsSig,
                studentSpouseConsSig: studentSpouseConsSig,
                parentConsSig: parentConsSig,
                parentsSpousePartnerConsSig: parentSpouseConsSig
            })
            if (financialBorrower) {
                setBorrowerRow(financialBorrower)
            }
            if (costsOfAttendance) {
                // let newArray = costsOfAttendance.map(v => [{...v, costItem : v.costLabel}])
                setCOARow(costsOfAttendance)
            }
            if (borrowerMPN) {
                setMpnRow(borrowerMPN)
            }
            if (borrowerCounsellings) {
                setCounselingRow(borrowerCounsellings)
            }
        }).catch((err) => {
            console.log(err)
            // setIsLoading(false);
        })
    }


    useEffect(() => {
        if (formik.values.directCostType && formik.values.faYear) {
            getFAYearBudget();
        }
    }, [formik.values.directCostType, formik.values.faYear])

    useEffect(() => {
        if (formik.values.financialAidYear) {
            setCopyDisabled(true);
        }
    }, [formik.values.financialAidYear])

    // useEffect(() => {
    //     let fa_year = VerFlagDropdown.faYear.filter((v) => v.uniqueId === formik.values.faYear)[0]?.displayText || formik.values.faYearText;

    //     if (typeof fa_year !== 'undefined') {
    //         if (fa_year.indexOf("-")  > -1) {
    //             let start_year = fa_year.split("-");
    //             if (parseInt(start_year[0]) >= 2024) {
    //                 setShowSAIFields(true);
    //             } else {
    //                 setShowSAIFields(false);
    //             }
    //         } else {
    //             let year_value = parseInt(fa_year);
    //             if (!isNaN(year_value) && year_value >= 2024) {
    //                 setShowSAIFields(true);
    //             } else {
    //                 setShowSAIFields(false);
    //             }
    //         }
    //     } else {
    //         setShowSAIFields(false);
    //     }
    // }, [formik.values.faYear, formik.values.faYearText]);

    const getFAYearBudget = () => {
        const { directCostType, faYear } = formik.values;
        if (directCostType && faYear) {
            getApi(`${get_fa_yearbudgets}/${faYear}/${directCostType}`).then((resp) => {
                const { budgets } = resp.data.data;
                setCOARow([...budgets])
                DefaultTable.resetTable("COATable")
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const copyBorrowerAwardPerType = () => {
        getApi(`${copy_borrowerAwardPerType}/${formik.values.financialAidYear}`).then((resp) => {
            const { financialBorrowers, primaryBorrower, primaryBorrowerID } = resp.data.data;
            var isDuplicate = BorrowerRow.filter((item) => {
                console.log(item);
                if (item.awardTypeID === financialBorrowers[0].awardTypeID) {
                    console.log(financialBorrowers[0]);
                    return item
                }
            })
            if (isDuplicate.length === 0) {
                setBorrowerRow([...BorrowerRow, ...financialBorrowers]);
            }
            formik.setFieldValue("defaultBorrowerforStudent", primaryBorrower);
            setBarrowerID(primaryBorrowerID);
            if (primaryBorrowerID) {
                getMpnData(primaryBorrowerID);
                getCounselingData(primaryBorrowerID);
            }
            DefaultTable.resetTable("BorrowerTable")
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (COARow.length > 0) {
            let amountArray = COARow.map(v => Number(v.costAmount))
            let totalCost = amountArray.reduce((val, act) => { return Number(val) + act }, 0)
            formik.setFieldValue("totalcosts", Number(totalCost).toFixed(2))
        }
    }, [COARow, formik.values.altPrimaryEFC, formik.values.percentPrimaryEFCforRemaining])


    useEffect(() => {
        academicYearDependency()
        if (selectedStatusRow) {
            formik.setFieldValue("faYearText", selectedStatusRow.financialAidYear)
            formik.setFieldValue("acdYearText", selectedStatusRow.academicYear)
        }
    }, [formik.values.academicYear])

    const academicYearDependency = () => {
        let payloadRequest =
        {
            "studentUId": selectedSelected.studentUID,
            "academicYearId": formik.values.academicYear,
            "financialStatusId": (selectedStatusRow.financialStatusID) ? selectedStatusRow.financialStatusID : null
        }

        postApi(get_academicyearDependency, payloadRequest).then((res) => {
            const { financialAidYears, verificationTrackingFlags } = res.data.data
            setVerFlagDropdown({
                ...VerFlagDropdown,
                faYear: financialAidYears,
                verificationTrackingFlag: verificationTrackingFlags,
            })
            if (!selectedStatusRow) {
                formik.setFieldValue("faYear", 0)
            }
        }).catch((err) => {
            console.log(err)
            // setIsLoading(false);
        })
    }

    const setBarrowerValue = (value) => {
        if (value.contactId) {
            setBarrowerID(value.contactId)
        }
        formik.setFieldValue("defaultBorrowerforStudent", `${value ? ((value.contactLast === "") ? "" : (value.contactLast + ',')) : ""} ${value ? value.contactFirst : ""}`)
    }



    const getCounselingData = (primaryBorrowerID = null) => {
        if (primaryBorrowerID) {
            setIsLoadingCODRow(true);
            getApi(`${counselings_gridList}/${primaryBorrowerID}`).then((resp) => {
                setCounselingRow(resp.data.data)
                setIsLoadingCODRow(false);
            }).catch((err) => {
                console.log(err);
                setIsLoadingCODRow(false);
            })
        } else {
            if (BarrowerID) {
                setIsLoadingCODRow(true);
                getApi(`${counselings_gridList}/${BarrowerID}`).then((resp) => {
                    setCounselingRow(resp.data.data)
                    setIsLoadingCODRow(false);
                }).catch((err) => {
                    console.log(err);
                    setIsLoadingCODRow(false);
                })
            }
        }
    }

    const getMpnData = (primaryBorrowerID = null) => {
        if (primaryBorrowerID) {
            setIsLoadingCODRow(true);
            getApi(`${mpn_gridList}/${primaryBorrowerID}`).then((resp) => {
                setMpnRow(resp.data.data)
                setIsLoadingCODRow(false);
            }).catch((err) => {
                console.log(err);
                setIsLoadingCODRow(false);
            })
        } else {
            if (BarrowerID) {
                setIsLoadingCODRow(true);
                getApi(`${mpn_gridList}/${BarrowerID}`).then((resp) => {
                    setMpnRow(resp.data.data)
                    setIsLoadingCODRow(false);
                }).catch((err) => {
                    console.log(err);
                    setIsLoadingCODRow(false);
                })
            }
        }
    }

    const handleExtraCounselingRowSelect = (value) => {
        setSelectedCounselingRow(value);
    }

    const handleExtraCounselingEditRow = () => {
        setShowAddData(true)
        setShowAddCounselling(true)
        addDatas(true);
    }

    const handleDeleteCounseling = (v, i) => {
        if (selectedCounselingRow.id) {
            deleteApi(`${delete_financialStatus_counseling}/${selectedCounselingRow.id}`).then(() => {
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                getCounselingData();
                DefaultTable.resetTable("CounselingTable")
            }).catch(err => {
                console.log(err)
            })
        }
        // //Client Side delete
        // let remainingArr = counselingRow.filter((item, ind) =>
        //     i.indexOf(ind) == -1
        // )
        // toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
        // setCounselingRow([...remainingArr])
        // DefaultTable.resetTable("CounselingTable")
    }

    const handleMpnEditRow = (value, rows, index) => {
        setShowAddData(true)
        setShowAddMPN(true)
        addDatas(true);
        setMpnIndex(index)
    }

    const handleCOAEditRow = (value, rows, index) => {
        setShowAddData(true)
        setShowAddTransDoc(true)
        addDatas(true);
        setCoaIndex(index);
    }

    const handleCOARowSelect = (value) => {
        setSelectedCOARow(value);
    }
    const handleMpnRowSelect = (value) => {
        setSelectedCODRow(value);
    }

    const handleDeleteMPN = (v, i) => {
        if (selectedCODRow.id) {
            deleteApi(`${delete_financialStatus_mpn}/${selectedCODRow.id}`).then(() => {
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                getMpnData();
                DefaultTable.resetTable("MpnTable")
            }).catch(err => {
                console.log(err);
                setIsLoadingCODRow(false);
            })
        }
        // //Client Side delete
        // let remainingArr = MpnRow.filter((item, ind) =>
        //     i.indexOf(ind) == -1
        // )
        // toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
        // setMpnRow([...remainingArr])
        // DefaultTable.resetTable("MpnTable")
    }

    const handleBorrowerRowEdit = (value, rows, index) => {
        setShowAddData(true)
        setShowBorrowerDetailsc(true)
        addDatas(true);
        setBorrowIndex(index);
    }

    const handleBorrowerRowSelect = (value) => {
        setSelectBorrower(value)
    }

    const RemoveBorrower = () => {
        formik.setFieldValue("defaultBorrowerforStudent", "")
        setBarrowerID(null)
        setMpnRow([]);
        setCounselingRow([]);
    }

    useEffect(() => {
        if ((formik.values.academicYear !== -1) && (formik.values.faYear !== 0)) {
            // setIsButtonDisable(false);
        }
        else if ((formik.values.academicYear === -1)) {
            // setIsButtonDisable(true);
        }


    }, [formik.values.academicYear])


    useEffect(() => {
        //Checking if form is submitted or not 
        if (!formik.isSubmitting) return;
        //Checking the input if it has error and passing the error object {name:"name"} to scroll helper
        if (Object.keys(formik.errors).length > 0) {
            scrollIntoViewHelper(formik.errors);
        }
    }, [formik]);

    const handleCOADeleteRow = (v, i) => {
        //Client Side delete
        let remainingArr = COARow.filter((item, ind) =>
            i.indexOf(ind) == -1
        )
        setCOARow([...remainingArr])
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
        DefaultTable.resetTable("COATable")
    }
    //Role Based Access implementation starts here
    // Role based access control redux
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;
    const [finAidStatus, setFinAidStatus] = useState({
        maintenance: 2
    })
    useEffect(() => {
        let changeFinAidStatus = nestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === maintenance && v.moduleorder === 4
        )
        setFinAidStatus({
            ...finAidStatus,
            maintenance: (changeFinAidStatus.length > 0) ? changeFinAidStatus[0].menuAccess : 2,
        })

    }, [access])
    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (finAidStatus.maintenance === 1) ? setAccessFlag(true) : setAccessFlag(false)
    }, [finAidStatus])
    //Role Based Access implementation ends here

    // useEffect(()=>{
    //     if(formik.values.SAIField === true){
    //         setShowSAIFields(true)
    //     }
    //     else{
    //         setShowSAIFields(false)
    //     }
    // },[formik.values.SAIField])
    const [internationalRadioFlag, setRadioFlag] = useState('true')
    const handleSelection = (event) => {
        setRadioFlag(event.target.value);
        formik.setFieldValue("SAIField", event.target.value);
    }

    // useEffect(()=>{
    //     if(selectedStatusRow){
    //         if(selectedStatusRow.useSAI === true){
    //             formik.setFieldValue("SAIField", "1")
    //         }
    //         else{
    //             formik.setFieldValue("SAIField", "0")
    //         }
    //     }
    // },[selectedStatusRow, formik.values.SAIField])
    return (
        <>
            {!showAddData ?

                <>
                    <PopupHeader
                        title={selectedStatusRow == "" ? (t("commonLabel.addFinancialAidStatusDetails", { ns: "financialAid" })) : (t("commonLabel.updateFinancialAidStatusDetails", { ns: "financialAid" }))}
                        onClose={onClose}
                        navigationList={financialAid_finacialaid}
                    />
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className={classess.root}
                    >
                        <Card>
                            <CardContent>
                                <FormGroup>
                                    {(selectedStatusRow) ?
                                        <TextInput
                                            mandatory={true}
                                            label={t("commonLabel.academicYear", { ns: "financialAid" })}
                                            labelId="acdYearText"
                                            formikValue={formik.values.acdYearText}
                                            formikChange={formik.handleChange}
                                            onlyNumeric
                                            disabled
                                        /> :
                                        <SelectInput
                                            mandatory={true}
                                            sortAsc={false}
                                            label={t("commonLabel.academicYear", { ns: "financialAid" })}
                                            labelId="academicYear"
                                            formikValue={formik.values.academicYear}
                                            formikChange={formik.handleChange}
                                            errors={formik.errors.academicYear}
                                            touched={formik.touched.academicYear}
                                            dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.academicYear }}
                                            disabled={accessFlag}
                                        />
                                    }
                                    {(selectedStatusRow) ?
                                        <TextInput
                                            mandatory={true}
                                            label={t("commonLabel.financialAidYear", { ns: "financialAid" })}
                                            labelId="faYearText"
                                            formikValue={formik.values.faYearText}
                                            formikChange={formik.handleChange}
                                            onlyNumeric
                                            disabled
                                        /> :
                                        <SelectInput
                                            mandatory={true}
                                            sortAsc={false}
                                            label={t("commonLabel.financialAidYear", { ns: "financialAid" })}
                                            labelId="faYear"
                                            formikValue={formik.values.faYear}
                                            formikChange={formik.handleChange}
                                            errors={formik.errors.faYear}
                                            touched={formik.touched.faYear}
                                            dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: VerFlagDropdown.faYear }}
                                            disabled={accessFlag}

                                        />
                                    }


                                    <SelectInput
                                        disabled={accessFlag}
                                        label={t("commonLabel.packageStatus", { ns: "financialAid" })}
                                        labelId="packageStatus"
                                        formikValue={formik.values.packageStatus}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.packageStatus }}

                                    />
                                    <SelectInput
                                        label={t("commonLabel.directCostType", { ns: "financialAid" })}
                                        labelId="directCostType"
                                        formikValue={formik.values.directCostType}
                                        formikChange={(e) => { formik.handleChange(e); getFAYearBudget(); }}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.directCostType }}
                                        disabled={accessFlag}


                                    />
                                </FormGroup>
                                <FormGroup>
                                    <RadioInput isDoubled
                                        labelId={"SAIField"}
                                        formikValue={formik.values.SAIField}
                                        customChange={handleSelection}
                                        // formikChange={formik.handleChange}
                                        disabled={accessFlag}
                                        list={[{
                                            label: t('commonLabel.ShowSAI', { ns: "financialAid" }),
                                            value: 'true',
                                        },
                                        {
                                            label: t('commonLabel.ShowEFC', { ns: "financialAid" }),
                                            value: 'false',
                                        },
                                        ]}
                                    />
                                </FormGroup>
                                {formik.values.SAIField === 'false' &&
                                    <>
                                        <FormGroup>
                                            <TextInput
                                                label={t("commonLabel.primaryefc", { ns: "financialAid" })}
                                                labelId="primaryefc"
                                                formikValue={formik.values.primaryefc}
                                                formikChange={formik.handleChange}
                                                onlyNumeric
                                                disabled={accessFlag}

                                            />
                                            <TextInput
                                                label={t("commonLabel.secondaryefc", { ns: "financialAid" })}
                                                labelId="secondaryefc"
                                                formikValue={formik.values.secondaryefc}
                                                formikChange={formik.handleChange}
                                                onlyNumeric
                                                disabled={accessFlag}

                                            />
                                            <TextInput
                                                label={t("commonLabel.percentPrimaryEFCforRemaining", { ns: "financialAid" })}
                                                labelId="percentPrimaryEFCforRemaining"
                                                formikValue={formik.values.percentPrimaryEFCforRemaining}
                                                formikChange={formik.handleChange}
                                                onlyNumeric
                                                maxValue={100}
                                                disabled={accessFlag}

                                            />
                                            <TextInput
                                                label={t("commonLabel.altPrimaryEFC", { ns: "financialAid" })}
                                                labelId="altPrimaryEFC"
                                                formikValue={formik.values.altPrimaryEFC}
                                                formikChange={formik.handleChange}
                                                onlyNumeric
                                                disabled={accessFlag}

                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInput
                                                label={t("commonLabel.dependentStatus", { ns: "financialAid" })}
                                                labelId="dependentStatus"
                                                formikValue={formik.values.dependentStatus}
                                                formikChange={formik.handleChange}
                                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.dependentStatus }}
                                                disabled={accessFlag}

                                            />
                                            <SelectInput
                                                label={t("commonLabel.autoZeroEFC", { ns: "financialAid" })}
                                                labelId="autoZeroEFC"
                                                formikValue={formik.values.autoZeroEFC}
                                                formikChange={formik.handleChange}
                                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.autoZeroEFC }}
                                                disabled={accessFlag}


                                            />
                                            <CheckBoxInput
                                                label={t("commonLabel.lockPackaging", { ns: "financialAid" })}
                                                labelId={"lockPackaging"}
                                                formikValue={formik.values.lockPackaging}
                                                formikChange={formik.handleChange}
                                                checked={formik.values.lockPackaging}
                                                disabled={accessFlag}

                                            />

                                            <SelectInput
                                                label={t("commonLabel.documentsComplete", { ns: "financialAid" })}
                                                labelId="documentsComplete"
                                                formikValue={formik.values.documentsComplete}
                                                formikChange={formik.handleChange}
                                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.documentsComplete }}
                                                disabled={accessFlag}


                                            />
                                        </FormGroup>
                                    </>}
                                    {formik.values.SAIField === 'true' &&
                                    <>
                                        <FormGroup>
                                            <TextInput
                                                labelId="sai"
                                                label={(t('commonLabel.sai', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.sai}
                                                formikChange={formik.handleChange}
                                                onlyNumeric
                                            />
                                            <TextInput
                                                labelId="provisionalSAI"
                                                label={(t('commonLabel.provisionalSAI', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.provisionalSAI}
                                                formikChange={formik.handleChange}
                                                onlyNumeric={true}
                                            />
                                            <SelectInput
                                                labelId="saiFormula"
                                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.saiFormula }}
                                                label={(t('commonLabel.saiFormula', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.saiFormula}
                                                formikChange={formik.handleChange}
                                            />
                                            <SelectInput
                                                labelId="dependencyModel"
                                                dropdownData={{ key: "displayText", value: "uniqueId", optionLists: Dropdown.dependencyModel }}
                                                label={(t('commonLabel.dependencyModel', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.dependencyModel}
                                                formikChange={formik.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInput
                                                labelId="studentConsSig"
                                                dropdownData={{ key: "displayText", value: "uniqueId", optionLists: Dropdown.studentConsSig }}
                                                label={(t('commonLabel.studentConsSig', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.studentConsSig}
                                                formikChange={formik.handleChange}
                                            />
                                            <SelectInput
                                                labelId="studentSpouseConsSig"
                                                dropdownData={{ key: "displayText", value: "uniqueId", optionLists: Dropdown.studentSpouseConsSig }}
                                                label={(t('commonLabel.studentSpouseConsSig', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.studentSpouseConsSig}
                                                formikChange={formik.handleChange}
                                            />
                                            <DateInput
                                                labelId={"studentConsSigDate"}
                                                label={(t('commonLabel.studentConsSigDate', { ns: "financialAid" }))}
                                                defaultValue={formik.values.studentConsSigDate}
                                                formik={formik}
                                                disabled={accessFlag}
                                            />
                                            <DateInput
                                                labelId={"studentSpouseConsSigDate"}
                                                label={(t('commonLabel.studentSpouseConsSigDate', { ns: "financialAid" }))}
                                                defaultValue={formik.values.studentSpouseConsSigDate}
                                                formik={formik}
                                                disabled={accessFlag}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <SelectInput
                                                labelId="parentConsSig"
                                                dropdownData={{ key: "displayText", value: "uniqueId", optionLists: Dropdown.parentConsSig }}
                                                label={(t('commonLabel.parentConsSig', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.parentConsSig}
                                                formikChange={formik.handleChange}
                                            />
                                            <SelectInput
                                                labelId="parentsSpousePartnerConsSig"
                                                dropdownData={{ key: "displayText", value: "uniqueId", optionLists: Dropdown.parentsSpousePartnerConsSig }}
                                                label={(t('commonLabel.parentsSpousePartnerConsSig', { ns: "financialAid" }))}
                                                disabled={accessFlag}
                                                formikValue={formik.values.parentsSpousePartnerConsSig}
                                                formikChange={formik.handleChange}
                                            />
                                            <DateInput
                                                labelId={"parentConsSigDate"}
                                                label={(t('commonLabel.parentConsSigDate', { ns: "financialAid" }))}
                                                defaultValue={formik.values.parentConsSigDate}
                                                formik={formik}
                                                disabled={accessFlag}
                                            />
                                            <DateInput
                                                labelId={"parentSpousePartnerConsSigDate"}
                                                label={(t('commonLabel.parentSpousePartnerConsSigDate', { ns: "financialAid" }))}
                                                defaultValue={formik.values.parentSpousePartnerConsSigDate}
                                                formik={formik}
                                                disabled={accessFlag}
                                            />
                                        </FormGroup>
                                    </>}
                                <FormGroup>
                                    <SelectInput
                                        label={t("commonLabel.previousBachelorsDegree", { ns: "financialAid" })}
                                        labelId="previousBachelorsDegree"
                                        formikValue={formik.values.previousBachelorsDegree}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.previousBachelorsDegree }}
                                        disabled={accessFlag}


                                    />
                                    <SelectInput
                                        label={t("commonLabel.enrolledGrad/Prof", { ns: "financialAid" })}
                                        labelId="enrolledGradProf"
                                        formikValue={formik.values.enrolledGradProf}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.enrolledGradProf }}
                                        disabled={accessFlag}


                                    />
                                    <DateInput
                                        label={t("commonLabel.initialPackagingDate", { ns: "financialAid" })}
                                        labelId={"initialPackagingDate"}
                                        defaultValue={formik.values.initialPackagingDate}
                                        formik={formik}
                                        disabled={accessFlag}

                                    />

                                    <DateInput
                                        label={t("commonLabel.lastPackagingDate", { ns: "financialAid" })}
                                        labelId={"lastPackagingDate"}
                                        defaultValue={formik.values.lastPackagingDate}
                                        formik={formik}
                                        disabled={accessFlag}

                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        label={t("commonLabel.fisapTotalIncome", { ns: "financialAid" })}
                                        labelId="fisapTotalIncome"
                                        formikValue={formik.values.fisapTotalIncome}
                                        formikChange={formik.handleChange}
                                        onlyNumeric
                                        disabled={accessFlag}

                                    />
                                    <SelectInput
                                        label={t("commonLabel.packagedForLoad", { ns: "financialAid" })}
                                        labelId="packagedForLoad"
                                        formikValue={formik.values.packagedForLoad}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.packagedForLoad }}
                                        disabled={accessFlag}


                                    />
                                    <TextInput
                                        label={t("commonLabel.monthsCOA", { ns: "financialAid" })}
                                        labelId="monthsCOA"
                                        formikValue={formik.values.monthsCOA}
                                        formikChange={formik.handleChange}
                                        onlyNumeric
                                        disabled={accessFlag}

                                    />
                                    <TextInput
                                        labelId="packageEnrollmentIntensity"
                                        label={t("commonLabel.packageEnrollmentIntensity", {
                                            ns: "financialAid",
                                        })}
                                        formikValue={formik.values.packageEnrollmentIntensity}
                                        formikChange={formik.handleChange}
                                        onlyNumeric
                                        // maxValue={100}
                                        // minValue={0}
                                        disabled={accessFlag}
                                        errors={formik.errors.packageEnrollmentIntensity}
                                        touched={formik.touched.packageEnrollmentIntensity}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        label={t("commonLabel.verificationCode", { ns: "financialAid" })}
                                        labelId="verificationCode"
                                        formikValue={formik.values.verificationCode}
                                        formikChange={formik.handleChange}
                                        maxlength={"3"}
                                        disabled={accessFlag}

                                    />
                                    <SelectInput
                                        label={t("commonLabel.verificationStatus", { ns: "financialAid" })}
                                        labelId="verificationStatus"
                                        formikValue={formik.values.verificationStatus}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.verificationStatus }}
                                        disabled={accessFlag}


                                    />
                                    <SelectInput
                                        label={t("commonLabel.verificationTrackingFlag", { ns: "financialAid" })}
                                        labelId="verificationTrackingFlag"
                                        formikValue={formik.values.verificationTrackingFlag}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: VerFlagDropdown.verificationTrackingFlag }}
                                        disabled={accessFlag}
                                    />
                                    <SelectInput
                                        label={t("commonLabel.pellLifeTimeLimit", { ns: "financialAid" })}
                                        labelId="pellLifeTimeLimit"
                                        formikValue={formik.values.pellLifeTimeLimit}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.pellLifeTimeLimit }}
                                        disabled={accessFlag}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <CheckBoxInput
                                        label={t("commonLabel.resolved", { ns: "financialAid" })}
                                        labelId={"resolved"}
                                        formikValue={formik.values.resolved}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.resolved}
                                        disabled={accessFlag}

                                    />
                                </FormGroup>

                                <p>Note</p>
                                <Splitter height={"5px"} />

                                <FormGroup>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        disabled={accessFlag}
                                        maxRows={10}
                                        minRows={3}
                                        name="narrative"
                                        onChange={formik.handleChange}
                                        value={formik.values.narrative}
                                        style={{
                                            width: "100%",
                                            padding: "16px",
                                            boxSizing: "border-box",
                                            height: "auto",
                                            overflow: "auto",
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <TextInput
                                        label={t("commonLabel.totalcosts", { ns: "financialAid" })}
                                        labelId="totalcosts"
                                        formikValue={formik.values.totalcosts}
                                        formikChange={formik.handleChange}
                                        onBlur={onSwipeCurrency}
                                        disabled={true}

                                    />
                                    <TextInput
                                        disabled={true}
                                        label={t("commonLabel.remainingNeed", { ns: "financialAid" })}
                                        labelId="remainingNeed"
                                        formikValue={formik.values.remainingNeed}
                                        formikChange={formik.handleChange}
                                        onBlur={onSwipeCurrency}
                                    />
                                    <InfoMessage label={t('commonLabel.needUpdateOnAddUpdateOnly', { ns: 'financialAid' })} />
                                </FormGroup>

                                <Splitter height={"10px"} />
                                <hr></hr>
                                <FormGroup>
                                    <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.cod", { ns: "financialAid" })} />
                                    <Grid container className={"jc"}>
                                        <ButtonWrapper className="ButtonDistribute">
                                            <Button
                                                onClick={() => toggleShow(!show)}
                                                aria-label={"Cancel"}
                                                className="BgLightBlue"
                                                variant="contained"
                                                size="large"
                                            >
                                                {t("commonLabel.viewHideCosts", { ns: "financialAid" })}
                                            </Button>
                                        </ButtonWrapper>
                                    </Grid>
                                </FormGroup>

                                {show && (
                                    <div>
                                        <FormGroup>
                                            <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.costofAttendence", { ns: "financialAid" })} />
                                            <Grid container className={"jc"}>
                                                <ButtonWrapper className="ButtonDistribute"
                                                    title={(formik.values.faYear == 0 || formik.values.faYear == null) ? t('status.academicYearFaYearSelect', { ns: "financialAid" }) : ''} >

                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        size="Small"
                                                        type="button"
                                                        onClick={AddCostAttendance}
                                                        disabled={((formik.values.faYear == 0 || formik.values.faYear == null) ? true : false) || (accessFlag)}>
                                                        <span className="plus">+</span>{" "}
                                                        {t("commonLabel.addCost", {
                                                            ns: "financialAid",
                                                        })}
                                                    </Button>
                                                </ButtonWrapper>
                                            </Grid>
                                        </FormGroup>
                                        <DefaultTable
                                            trackingLabel={"COATable"}
                                            headCells={costofAttendence}
                                            rows={COARow}
                                            isLoading={isLoading}
                                            pagination={true}
                                            onRowSelect={handleCOARowSelect}
                                            onEdit={handleCOAEditRow}
                                            onDelete={handleCOADeleteRow}
                                            deleteDisable={finAidStatus.maintenance !== 2 ? true : false}
                                        />
                                    </div>
                                )}

                                <hr></hr>
                                <Splitter height={"10px"} />

                                <FormGroup>
                                    <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codExtras", { ns: "financialAid" })} />
                                    <Grid container className={"jc"}>
                                        <ButtonWrapper className="ButtonDistribute">
                                            <Button
                                                onClick={() => setShowTable(!showTable)}
                                                aria-label={"Cancel"}
                                                className="BgLightBlue"
                                                variant="contained"
                                                size="large"
                                            >
                                                {t('buttonLabel.viewHideCODExtras', { ns: "financialAid" })}
                                            </Button>
                                        </ButtonWrapper>
                                    </Grid>
                                </FormGroup>
                                <Splitter height={"5px"} />

                                {showTable && (
                                    <div>
                                        <Grid container className={"jc"}>
                                            <FormGroup>
                                                <SelectInput
                                                    label={t("commonLabel.acgEligibilityPaymentReason", { ns: "financialAid" })}
                                                    labelId="acgEligibilityPaymentReason"
                                                    formikValue={formik.values.acgEligibilityPaymentReason}
                                                    formikChange={formik.handleChange}
                                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.acgEligibilityPaymentReason }}
                                                    disabled={accessFlag}

                                                />
                                                <TextInput
                                                    label={t("commonLabel.rigorousHighschoolProgramCode", { ns: "financialAid" })}
                                                    labelId="rigorousHighschoolProgramCode"
                                                    formikValue={formik.values.rigorousHighschoolProgramCode}
                                                    formikChange={formik.handleChange}
                                                    maxlength={"6"}
                                                    disabled={accessFlag}

                                                />
                                                <SelectInput
                                                    label={t("commonLabel.studentDefaultOverpayCode", { ns: "financialAid" })}
                                                    labelId="studentDefaultOverpayCode"
                                                    formikValue={formik.values.studentDefaultOverpayCode}
                                                    formikChange={formik.handleChange}
                                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.studentDefaultOverpayCode }}
                                                    disabled={accessFlag}

                                                />
                                                <SelectInput
                                                    label={t("commonLabel.BorrowerPlusDefaultOverpayCode", { ns: "financialAid" })}
                                                    labelId="BorrowerPlusDefaultOverpayCode"
                                                    formikValue={formik.values.BorrowerPlusDefaultOverpayCode}
                                                    formikChange={formik.handleChange}
                                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.BorrowerPlusDefaultOverpayCode }}
                                                    disabled={accessFlag}

                                                />
                                            </FormGroup>

                                        </Grid>

                                    </div>
                                )}
                                <hr></hr>
                                <Splitter height={"10px"} />

                                <HeaderTitle variant={'subHeading'} headerTitle={t('commonLabel.borrowerInformation', { ns: "financialAid" })} />
                                <FormGroup>
                                    <Grid container className={"jc"}>
                                        <TextLookUpInput
                                            label={t('commonLabel.defaultBorrowerforStudent', { ns: "financialAid" })}
                                            labelId='defaultBorrowerforStudent'
                                            formikValue={formik.values.defaultBorrowerforStudent}
                                            formikChange={formik.handleChange}
                                            handlelookUp={handleStudentlookUp}
                                            isDoubled={true}
                                            disabled={accessFlag}

                                        />
                                        <ButtonWrapperFilter className='flexStart'>
                                            <Button disabled={accessFlag} className="BgLightBlue" variant="contained" size="large" type="button" onClick={RemoveBorrower}>
                                                {t('buttonLabel.remove', { ns: "financialAid" })}
                                            </Button>
                                        </ButtonWrapperFilter>
                                    </Grid>
                                </FormGroup>
                            </CardContent>

                            <Splitter height={"5px"} />
                            <CardContent>
                                <Grid container className={"jc"}>
                                    <ButtonWrapper>
                                        {/* <HeaderTitle headerTitle={t("commonLabel.viewMPNEntanceCounselling", { ns: "financialAid" })} /> */}
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            type="button"
                                            onClick={awardsusagelimits}
                                        >
                                            {t('buttonLabel.mpndataforDefaultBorrower', { ns: "financialAid" })}

                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            type="button"
                                            onClick={informedborrowinginfo}

                                        >
                                            {t('buttonLabel.counsellingdataforDefaultBorrower', { ns: "financialAid" })}

                                        </Button>
                                    </ButtonWrapper>
                                </Grid>
                                <Splitter height={'30px'} />

                                {showDynamicTableusagelimits == true ? (
                                    <FormGroup>
                                        <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.mpndataforDefaultBorrower", { ns: "financialAid" })} />
                                        <Grid container className={"jc"}>
                                            <ButtonWrapper className="ButtonDistribute" title={(BarrowerID == null) ? t("status.pleaseSelectTheBorrowerFirst", { ns: "financialAid" }) : ''}>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    type="button"
                                                    onClick={AddMPNDefault}
                                                    disabled={(BarrowerID == null) ? true : false}
                                                >
                                                    <span className="plus">+</span>{" "}
                                                    {t("buttonLabel.add", { ns: "financialAid" })}
                                                </Button>
                                            </ButtonWrapper>
                                        </Grid>
                                    </FormGroup>
                                ) : (
                                    <FormGroup>
                                        <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.counsellingDataForDefaultBorrower", { ns: "financialAid" })} />
                                        <Grid container className={"jc"}>
                                            <ButtonWrapper className="ButtonDistribute" title={(BarrowerID == null) ? t("status.pleaseSelectTheBorrowerFirst", { ns: "financialAid" }) : ''}>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    type="button"
                                                    onClick={AddCounsellingDefault}
                                                    disabled={(BarrowerID == null) ? true : false}
                                                >
                                                    <span className="plus">+</span>{" "}
                                                    {t("buttonLabel.add", { ns: "financialAid" })}
                                                </Button>
                                            </ButtonWrapper>
                                        </Grid>
                                    </FormGroup>
                                )}


                                {showDynamicTableusagelimits == true ? (

                                    <DefaultTable
                                        trackingLabel={"MpnTable"}
                                        headCells={mpnDataforDefaultBorrower}
                                        rows={MpnRow}
                                        isLoading={isLoading}
                                        pagination={true}
                                        onRowSelect={handleMpnRowSelect}
                                        onEdit={handleMpnEditRow}
                                        onDelete={handleDeleteMPN}
                                        deleteDisable={finAidStatus.maintenance !== 2 ? true : false}

                                    />
                                ) : (
                                    <DefaultTable
                                        trackingLabel={"CounselingTable"}
                                        headCells={counsellingDataforDefaultBorrower}
                                        rows={counselingRow}
                                        pagination={true}
                                        isLoading={isLoading}
                                        onRowSelect={handleExtraCounselingRowSelect}
                                        onEdit={handleExtraCounselingEditRow}
                                        onDelete={handleDeleteCounseling}
                                        deleteDisable={finAidStatus.maintenance !== 2 ? true : false}
                                    />
                                )}
                            </CardContent>
                            <Splitter height={"10px"} />
                            <CardContent>
                                <FormGroup>
                                    <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.borrowerPerAwardType", { ns: "financialAid" })} />
                                    <Grid container className={"jc"}>
                                        <ButtonWrapper className="ButtonDistribute">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="large"
                                                type="button"
                                                onClick={AddBorrowerDetail}
                                                disabled={accessFlag}
                                            >
                                                <span className="plus">+</span>{" "}
                                                {t("buttonLabel.add", { ns: "financialAid" })}
                                            </Button>
                                        </ButtonWrapper>
                                    </Grid>
                                </FormGroup>
                                <DefaultTable
                                    trackingLabel={"BorrowerTable"}
                                    headCells={borrowerPerAwardType}
                                    rows={BorrowerRow}
                                    pagination={true}
                                    onEdit={handleBorrowerRowEdit}
                                    onRowSelect={handleBorrowerRowSelect}
                                />
                            </CardContent>

                            <Splitter height={"10px"} />
                            <CardContent>
                                <FormGroup>
                                    <SelectInput
                                        label={t('commonLabel.financialAidYear', { ns: "financialAid" })}
                                        labelId="financialAidYear"
                                        formikValue={formik.values.financialAidYear}
                                        formikChange={formik.handleChange}
                                        touched={formik.touched.financialAidYear}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.financialAidYear }}
                                        disabled={accessFlag}

                                    />
                                    <ButtonWrapperFilter className='flexStart'>
                                        <Button disabled={(accessFlag) || (!(isCopyDisabled))} className="BgLightBlue" variant="contained"
                                            size="large" type="button" onClick={copyBorrowerAwardPerType}>
                                            {t('buttonLabel.copy', { ns: 'financialAid' })}
                                        </Button>
                                    </ButtonWrapperFilter>
                                </FormGroup>
                            </CardContent>

                        </Card>


                        <CardContent>
                            <div style={{ display: "flex" }}>
                                <Tooltips flag={(selectedStatusRow) ? true : false} row={
                                    {
                                        insertUserID: selectedStatusRow.insertUserID,
                                        insertTime: selectedStatusRow.insertTime,
                                        updateUserID: selectedStatusRow.updateUserID,
                                        updateTime: selectedStatusRow.updateTime
                                    }
                                } />
                                <ButtonWrapperFilter>
                                    <Button
                                        className="BgYellowOrange search"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        disabled={accessFlag}
                                    >
                                        {selectedStatusRow == "" ? (t('buttonLabel.add', { ns: "financialAid" })) : (t('buttonLabel.update', { ns: "financialAid" }))}

                                    </Button>


                                    <Button
                                        aria-label={"Cancel"}
                                        className="BgLightBlue"
                                        variant="contained"
                                        size="large"
                                        onClick={onClose}
                                    >
                                        {t("buttonLabel.cancel", { ns: "financialAid" })}
                                    </Button>
                                </ButtonWrapperFilter>
                            </div>
                        </CardContent>
                    </form>
                    <GenericModal
                        open={openAlert}
                        title={textData}
                        onClose={setOpenAlert} />
                </> : (showAddTransDoc) ? <AddCostOfAttendence onClose={onCloseSubPopupPage} selectedSelected={selectedSelected}
                    selectedCOARow={selectedCOARow}
                    setCOARow={setCOARow}
                    COARow={COARow}
                    coaIndex={coaIndex}
                />
                    : (showBorrowerDetails) ? <AddBorrowerDetails accessFlag={accessFlag} onClose={onCloseSubPopupPage} selectedSelected={selectedSelected}
                        selectBorrower={selectBorrower}
                        setBorrowerRow={setBorrowerRow}
                        BorrowerRow={BorrowerRow}
                        borrowIndex={borrowIndex}
                        selectedStatusRow={selectedStatusRow}
                    />
                        : (showAddMPN) ? <AddMPNDefaultBorrower accessFlag={accessFlag} onClose={onCloseSubPopupPage} selectedSelected={selectedSelected}
                            selectedCODRow={selectedCODRow}
                            setMpnRow={setMpnRow}
                            MpnRow={MpnRow}
                            borrowerID={BarrowerID}
                            mpnIndex={mpnIndex}
                        />
                            : (showAddCounselling) ? <AddCounsellingDefaultBorrower accessFlag={accessFlag} onClose={onCloseSubPopupPage} selectedSelected={selectedSelected}
                                selectedCounselingRow={selectedCounselingRow}
                                setCounselingRow={setCounselingRow}
                                counselingRow={counselingRow}
                                borrowerID={BarrowerID}
                            />
                                : (showStudentContactLookup) && <StudentContactLookup onClose={onCloseSubPopupPage} selectedSelected={selectedSelected} setBarrowerValue={setBarrowerValue} />}
        </>
    );
};



export default AddFinancialAidStatusDetails;

















