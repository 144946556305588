import React, { useState, useEffect } from 'react';
import { FormInputWrapper } from '../../styles/common-styles';
import { InputLabel, FormHelperText } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';

/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formik: Object,
 * disabled:boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean
 * defaultValue: date,
 * isDoubled? : Boolean,
 * onError?: Function
 * }} props 
 */
export const DateInput = (props) => {
    const { label, labelId, formik, errors, touched, mandatory, defaultValue, maxDateMessage, minDateMessage, isDoubled, maxDate, minDate, disabled, onError, customStyle, disableFuture,handleUpdate } = props;
    const [selectedDate, setSelectedDate] = useState(null);
    // Adjust the the date to UTC 00:00 time regardless input by date picker keyboard or picked by mouse
    // const getDateIgnoreTimezone = (date) =>
    //     date && date.getUTCHours() !== 0
    //         ? ((theDate) =>
    //             new Date(theDate.getTime() - theDate.getTimezoneOffset() * 60 * 1000))(new Date(date))
    //         : date;
    useEffect(() => {
        if (defaultValue != undefined) {
            setSelectedDate(new Date(defaultValue));
        }
        if (defaultValue == null) {
            setSelectedDate(defaultValue);
        }
    }, [defaultValue])
    const handleDateChange = (date) => {
        /// handle update should call if some input fixes perform on date change/update
       (labelId === 'graduationDate' || labelId==='conferredDate') && handleUpdate();
        if (date === undefined) {
            setSelectedDate(null);
            formik.setFieldValue(labelId, null);
        } else {
            setSelectedDate(moment(new Date(date)).format("YYYY-MM-DDTHH:mm:ss"));
            formik.setFieldValue(labelId, moment(new Date(date)).format("YYYY-MM-DDTHH:mm:ss"));
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'doubled' : null} >
                <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
                <KeyboardDatePicker
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    name={labelId}
                    disabled={disabled}
                    id={labelId}
                    value={selectedDate}
                    minDate={minDate}
                    error={(errors && touched) ? true : false}
                    onChange={(date) => {
                        
                        if (date) {
                            let d = new Date();
                            const [hr, min, sec] = [d.getHours(), d.getMinutes(), d.getSeconds()];
                            date.setHours(hr, min, sec);
                            // const theDate = getDateIgnoreTimezone(date);
                            handleDateChange(date);
                            return;
                        }
                        handleDateChange(undefined);
                    }}
                    maxDate={maxDate}
                    maxDateMessage={(maxDateMessage) ? maxDateMessage : ''}
                    minDateMessage={(minDateMessage) ? minDateMessage : ''}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    autoComplete={'off'}
                    autocomplete="off"
                    disableFuture={disableFuture ? disableFuture : false}
                    onError={onError}
                />
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormInputWrapper>
        </MuiPickersUtilsProvider>
    )
}

export const TimeInput = (props) => {
    const { label, labelId, formik, errors, touched, mandatory, defaultValue, isDoubled,disabled,customStyle,helperText } = props;
    const [selectedTime, setSelectedTime] = useState(null);
    useEffect(() => {
        if (defaultValue != undefined) {
            setSelectedTime(new Date(defaultValue))
        }
    }, [defaultValue])
    const handleTimeChange = (time) => {
        setSelectedTime(time);
        formik.setFieldValue(labelId, time);
    }
    const [localError, setLocalError] = useState(false);
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'doubled' : null}>
                <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
                <KeyboardTimePicker
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    name={labelId}
                    disabled={disabled}
                    id={labelId}
                    value={selectedTime}
                    onChange={handleTimeChange}
                    helperText={localError ? 'Invalid Time Format(Format: HH:MM AM/PM)'  : ''}
                    error={(errors && touched) ? true : false}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    keyboardIcon={<AccessTimeIcon />}
                    onError={(error)=> setLocalError(error) }
                />
                {errors && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormInputWrapper>
        </MuiPickersUtilsProvider>
    )
}

export const DateTimeInput = (props) => {
    const { label, labelId, formik, errors, touched, mandatory, defaultValue, isDoubled, disabled } = props;
    const [selectedTime, setSelectedTime] = useState(null);
    useEffect(() => {
        if (defaultValue != undefined) {
            setSelectedTime(new Date(defaultValue));
        }
    }, [defaultValue])
    const handleTimeChange = (time) => {
        setSelectedTime(time);
        formik.setFieldValue(labelId, time);
        console.log(time);
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <FormInputWrapper isDoubled={isDoubled ? isDoubled : null} className={isDoubled ? 'doubled' : null}>
                <InputLabel htmlFor={labelId}>{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
                <KeyboardDateTimePicker
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    name={labelId}
                    id={labelId}
                    disabled={disabled}
                    value={selectedTime}
                    format="MM/dd/yyyy hh:mm:ss a"
                    ampm={true}
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                {errors && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormInputWrapper>
        </MuiPickersUtilsProvider>
    )
}