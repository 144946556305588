import React from 'react';
import {  toast } from "react-toastify";

const Msg = ({ title, text }) => (
    <>
        {/* As per Marsha requested I commented below lines */}
        {/* {title && 
            <h4><strong>{title}</strong></h4>
        } */}
        {text && <span>{text}</span>}
    </>
)

const type = { info: toast.TYPE.INFO, success: toast.TYPE.SUCCESS, warning: toast.TYPE.WARNING, error: toast.TYPE.ERROR };

export const toastMessage = (tag, title, text) => {
    toast(<Msg title={title} text={text} />, {
        type: type[tag]
    });
}