import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Card, CardContent } from "@material-ui/core";
import {
    FormGroup,
    useStyles,
    ButtonWrapper,
    Splitter,
} from "../../../../styles/common-styles";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../components/Forms";
import AddISIRSAIDetails from "./addIsirSAIDetails";
import AddCustomISRRecord from "./addCustomIsrRecord";
import { useSelector } from 'react-redux';
import { deleteApi, getApi, postApi } from "../../../../services/apiBase";
import { isir_financial, isir_financial_grid_new, isir_financial_grid_2_3, isir_select_items, isir_set_comment_resolved } from "../../../../services/apiURL";
import GenericModal from "../../../../components/Modal/GenericModal";
import { toastMessage } from "../../../../utils/Message/toasts";
import ShowISIRReport from "./ShowISIRReport";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";

const Isir = ({ getSelectedRow, selectedStatusRow, addDatas, fyDropDown, selectedSelected }) => {

    const initialValues = {
        filtertoPackage: selectedStatusRow?.financialStatusID
    };

    const [tableRow, setTableRow] = useState([]);
    const [tableRow2, setTableRow2] = useState([]);
    const [showISIRFullReport, setShowISIRFullReport] = useState(false);

    const [selectedRow1, setSelectedRow1] = useState('');
    const [fullReportSelectedRow, setFullReportSelectedRow] = useState('');
    const [dropdown, setDropdown] = useState({
        "applicationTypeList": [],
        "housingStatusList": [],
        "fieldNamesList": [],
        "pellLifeTimeList": [],
        "dependentStatusList": [],
        "enrollmentStatusList": [],
        "interestedInLoansList": [],
        "finISARRecordsList": [],
        "customImportFieldsList": [],
        "filterToPackageList": []
    });

    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells', `registrationHeadCells`]);
    const [showAddIsirdetails, setShowAddIsirdetails] = useState(false);
    const [showAddCustomIsirdetails, setShowCustomAddIsirdetails] = useState(false);
        
    const [financialISIR] = useState([
        { id: 'financialISIRID', numeric: true, disablePadding: false, label: t('financialISIR.financialISIRID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialStatusUID', numeric: true, disablePadding: false, label: t('financialISIR.financialStatusUID', { ns: 'financialAidHeadcells' }) },
        { id: 'documentID', numeric: true, disablePadding: false, label: t('financialISIR.documentID', { ns: 'financialAidHeadcells' }) },
        { id: 'yearIndicator', numeric: true, disablePadding: false, label: t('financialISIR.yearIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'transactionID', numeric: true, disablePadding: false, label: t('financialISIR.transactionID', { ns: 'financialAidHeadcells' }) },
        { id: 'activeFlag', numeric: true, disablePadding: false, label: t('financialISIR.activeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'lock', numeric: true, disablePadding: false, label: t('financialISIR.lock', { ns: 'financialAidHeadcells' }) },
        { id: 'studentUID', numeric: true, disablePadding: false, label: t('financialISIR.studentUID', { ns: 'financialAidHeadcells' }) },
        { id: 'studentSSN', numeric: true, disablePadding: false, label: t('financialISIR.studentSSN', { ns: 'financialAidHeadcells' }) },
        { id: 'studentLast', numeric: true, disablePadding: false, label: t('financialISIR.studentLast', { ns: 'financialAidHeadcells' }) },
        { id: 'studentFirst', numeric: true, disablePadding: false, label: t('financialISIR.studentFirst', { ns: 'financialAidHeadcells' }) },
        { id: 'studentMiddle', numeric: true, disablePadding: false, label: t('financialISIR.studentMiddle', { ns: 'financialAidHeadcells' }) },
        { id: 'address', numeric: true, disablePadding: false, label: t('financialISIR.address', { ns: 'financialAidHeadcells' }) },
        { id: 'city', numeric: true, disablePadding: false, label: t('financialISIR.city', { ns: 'financialAidHeadcells' }) },
        { id: 'state', numeric: true, disablePadding: false, label: t('financialISIR.state', { ns: 'financialAidHeadcells' }) },
        { id: 'zipCode', numeric: true, disablePadding: false, label: t('financialISIR.zipCode', { ns: 'financialAidHeadcells' }) },
        { id: 'country', numeric: true, disablePadding: false, label: t('financialISIR.country', { ns: 'financialAidHeadcells' }) },
        { id: 'phone', numeric: true, disablePadding: false, label: t('financialISIR.phone', { ns: 'financialAidHeadcells' }) },
        { id: 'email', numeric: true, disablePadding: false, label: t('financialISIR.email', { ns: 'financialAidHeadcells' }) }
    ]);

    const [commentcodesforISR] = useState([
        { id: 'select', numeric: true, disablePadding: false, label: t('massStudentRegistration.select', { ns: 'registrationHeadCells' }) },
        { id: 'commentCode', numeric: true, disablePadding: false, label: t('commentcodesforISR.commentCode', { ns: 'financialAidHeadcells' }) },
        { id: 'comment', numeric: true, disablePadding: false, label: t('commentcodesforISR.comment', { ns: 'financialAidHeadcells' }) },
        { id: 'resolved', numeric: true, disablePadding: false, label: t('commentcodesforISR.resolved', { ns: 'financialAidHeadcells' }) },
        { id: 'note', numeric: true, disablePadding: false, label: t('commentcodesforISR.note', { ns: 'financialAidHeadcells' }) },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('commentcodesforISR.updateUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'uPdateTime', numeric: true, disablePadding: false, label: t('commentcodesforISR.uPdateTime', { ns: 'financialAidHeadcells' }) },
    ])
    
    // eslint-disable-next-line no-unused-vars
    const [formValues, setFormValues] = useState(initialValues);

    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        onSubmit: (values) => {

        },
    });
    const classes = useStyles();
    const [showAddData, setShowAddData] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    
    const onClose = (value) => {
        getGridOneData()
        addDatas(false);
        setShowAddData(value);
        setShowAddIsirdetails(false);
        setShowCustomAddIsirdetails(false);
        setShowISIRFullReport(false)
        DefaultTable.resetTable("customISIRRec")
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    };

    const getGridOneData = async () => {
        setIsLoading1(true)
        try {
            var results = await getApi(`${isir_financial_grid_new}/${formik?.values?.filtertoPackage}/${selectedSelected.studentUID}`);
            setIsLoading1(false);
            setTableRow(results.data.data);
        }
        catch (err) {
            console.error(err);
            setIsLoading1(false);
        }
    }

    useEffect(() => {
        getApi(`${isir_select_items}/${selectedSelected.studentUID}/${selectedStatusRow.academicYearUID}`).then(resp => {
            resp.data.data.filterToPackageList.push({
                "uniqueId": -1,
                "displayText": "All",
                "isSelected": false
            },)
            setDropdown(resp.data.data)
        })
        getGridOneData()
    }, [])

    useEffect(() => {
        setSelectedRow1('')
        setFullReportSelectedRow('')
        DefaultTable.resetTable("batchesBilling")
        getGridOneData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.filtertoPackage])

    const handleISIRRowSelected = async (row) => {
        setSelectedRow1(row)
        let fullReportRow = getFullReport(row)
        setFullReportSelectedRow(fullReportRow)
        setIsLoading2(true)
        
        try {
            const comments = await getApi(`${isir_financial_grid_2_3}/${row.financialISIRID}/${selectedSelected.studentUID}/true`);
            setIsLoading2(false);

            let temp = comments.data.data.commentsCodesJSONList.map(el => {
                el.select = false;
                return el;
            });
            setTableRow2(temp);
        }
        catch (err) {
            console.error(err);
            setIsLoading2(false);
        }
    }

    const getFullReport = (row) => {
        let fullReport = {}
        for (let [key, value] of Object.entries(row)) {
            let item = financialISIR.find(o => o.id.toLowerCase().trim() === key.toLowerCase().trim());
            item == null ? fullReport[key] = value : fullReport[item.label] = value
        }
        return fullReport
    }

    const handleFinancialISIRDeleteClicked = (row) => {
        deleteApi(`${isir_financial}/${selectedRow1.financialISIRID}/true`).then(resp => {
            if (resp.data.data) {
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                getGridOneData()
                setSelectedRow1('')
                setFullReportSelectedRow('')
                DefaultTable.resetTable("batchesBilling")
            }
        })
    };

    const [showPopup, setShowPopup] = useState(false)

    const setResolved = async () => {
        const selectedCourses = tableRow2.filter(row => row.select)
        if (selectedCourses.length === 0) {
            setShowPopup(true)
            return
        }
        const payload = {
            IDs: selectedCourses.map(el => el.id),
            isNewTable: true
        }
        
        await postApi(isir_set_comment_resolved, payload);
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
        handleISIRRowSelected(selectedRow1);
    }

    const onFullDetailsClick = () => {
        setShowAddData(true);
        setShowAddIsirdetails(false)
        setShowCustomAddIsirdetails(false)
        addDatas(true)
        setShowISIRFullReport(true)
    }

    const handleCellClick = (cellClick, id, value, row, index) => {
        const newArr = tableRow2.map((v, i) => {
            if (i === index) {
                if (id === 'select') {
                    v[id] = !value
                }
            }
            return v;
        })
        setTableRow2(newArr)
    }

    // Role based access control redux
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;

    const [statusAccess, setStatusAccess] = useState({
        maintenance: 2,
    })

    useEffect(() => {
        let finStatusAccess = nestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === maintenance && v.moduleorder === 4
        )

        setStatusAccess({
            ...statusAccess,
            maintenance: (finStatusAccess.length > 0) ? finStatusAccess[0].menuAccess : 2,
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access])

    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (statusAccess.maintenance !== 2) ? setAccessFlag(true) : setAccessFlag(false)
    }, [statusAccess])
    //RBA Ends here
    return (
        <>
            <GenericModal
                open={showPopup}
                title={"Please select a row to resolve"}
                onClose={setShowPopup}
            />
            {!showAddData ?
                <>
                    <form onSubmit={formik.handleSubmit} className={classes.root}>
                        <Card>
                            <CardContent className={"headerTitle"}>
                               
                                <ButtonWrapper className="ButtonDistribute">

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="button"
                                        onClick={onFullDetailsClick}
                                        disabled={selectedRow1 ? false : true}
                                    >
                                        {t("commonLabel.fullDetails", { ns: "financialAid" })}

                                    </Button>
                                  
                                </ButtonWrapper>
                                <FormGroup>
                                    <SelectInput
                                        label={t("commonLabel.filtertoPackage", { ns: "financialAid" })}
                                        labelId={"filtertoPackage"}
                                        formikValue={formik.values.filtertoPackage}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropdown.filterToPackageList }}
                                    />
                                </FormGroup>
                                <DefaultTable
                                    isLoading={isLoading1}
                                    trackingLabel={"batchesBilling"}
                                    headCells={financialISIR}
                                    rows={tableRow}
                                    pagination={true}
                                    onRowSelect={handleISIRRowSelected}
                                    onDelete={handleFinancialISIRDeleteClicked}
                                    deleteDisable={accessFlag}
                                />
                            </CardContent>
                        </Card>
                        <Splitter />
                        <Card>
                            <CardContent className={"headerTitle"}>
                                <FormGroup>
                                    <HeaderTitle variant={'subHeading'}
                                        headerTitle={t("commonLabel.commentCodesforselectedISIRrecord", { ns: "financialAid" })}
                                        addData={() => {
                                            setShowAddData(false);
                                            addDatas(false)
                                            setShowCustomAddIsirdetails(false)
                                            setShowAddIsirdetails(false)

                                            setResolved()
                                        }}
                                        buttonText={t("buttonLabel.setAlltoResolved", { ns: "financialAid" })}
                                        disableButton={(statusAccess.maintenance !== 2) ? true : false}
                                    />
                                </FormGroup>
                                <DefaultTable
                                    isLoading={isLoading2}
                                    headCells={commentcodesforISR}
                                    rows={tableRow2}
                                    pagination={true}
                                    isLookup={true}
                                    onCellClick={handleCellClick}
                                />
                            </CardContent>
                        </Card>
                        <Splitter />
                    </form>
                </> : (showAddIsirdetails) ? <AddISIRSAIDetails accessFlag={accessFlag} selectedRow1={selectedRow1} dropdown={dropdown} addDatas={addDatas} onClose={onClose} FinancialStatusUID={formik.values.filtertoPackage} />
                    : (showAddCustomIsirdetails) ? <AddCustomISRRecord accessFlag={accessFlag} dropdown={dropdown} addDatas={addDatas} onClose={onClose} />
                        : (showISIRFullReport) && <ShowISIRReport selectedRow={fullReportSelectedRow} financialISIR={financialISIR} onClose={onClose} />

            }
        </>
    );
};
export default Isir; 