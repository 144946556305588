import BrandingColors from './color';
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core';

export const ButtonWrapper = styled.div`{
    flex-grow: 1;
    justify-content: ${props => props.flexStart ? props.flexStart : "flex-end"};
    display: flex;
    button{
        background-color: ${BrandingColors.hoverOrangeCustom};
        border-color: ${BrandingColors.hoverOrangeCustom};
        min-height:36px;
        min-width:${props => props.buttonMinWidth ? props.buttonMinWidth : "160px"};
        padding: 0px 12px 0px 12px;
        vertical-align: middle;
        text-transform: none;
        box-shadow: none;
        margin-left:24px;
        border-radius:0;
        color: ${BrandingColors.darkGreyFont};
        &:hover {
            background-color: ${BrandingColors.btnHoverBlue};
            border-color: ${BrandingColors.btnHoverBlue};
            border-radius:4px;
        }
        span{
            color: ${BrandingColors.darkGreyFont};
            font-family: 'FiraSans-Regular', sans-serif;
            font-weight: 600;
            font-size: 14px;
            &:active {
                color: ${BrandingColors.darkGreyFont};
            }
            @media (min-width:1920px) {
                font-size: 18px;
            }
        }
        .plus{
            font-size: 14px;
            color: ${BrandingColors.darkGreyFont};
            line-height: 18px; 
            padding-right: 10px; 
        }
        span #textDisabled{
            color: ${BrandingColors.lightGrey}
        }
        &.linkButton{
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
            &:hover{
                text-decoration: underline;
                color: ${BrandingColors.linkTextFont};
            },
            &:disabled{
                text-decoration: none;
                
            }
            span{
                font-family: 'FiraSans-Regular', sans-serif;
                font-size: 14px;
                color: ${BrandingColors.linkTextFont};
            }       
        }
        
        &[disabled]{
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
            text-decoration: none;
            span{
                color: ${BrandingColors.btnPressedGrey};
            }
        }

        }
    }
    @media (min-width:1920px) {
        button{
            span,.plus{
                font-size: 18px
            }
        }
    }
}`
export const FormInputWrapper = styled.div`{
    @media (max-width:980px) {
        width:${props => props.isDoubled ? "100%" : "47%"};
        margin-bottom: 10px;
    }
    position: relative;
    width: ${props => props.fullWidth ? "100%" : props.isCustomised ? props.isCustomised : props.isDoubled ? "49%" : props.isTripple ? "72%" : props.isImage ? '29.34%' : "24%"};
    &:nth-child(4) {
        margin: 0;
    }
    margin-right: ${props => props.isImage ? '4%' : props.isDoubled ? "0px" : '20px'};
    label{
        margin-bottom:5px;
        font-size: 14px;
        font-weight: 700;
        font-family: 'FiraSans-Regular', sans-serif;
        color: ${BrandingColors.darkGreyFont}
    }
    &.growDouble{
        flex-grow: 0.5;
    }
    &.picker{
        @media only screen and (min-width:768px) and (max-width:980px) {
            margin-left: 0;
        }
    }
    &.disabled{
        span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
            color: ${BrandingColors.lightGrey};
        }
    }
    & .MuiListItem-button:hover{
        background-color: ${BrandingColors.hoverOrangeCustom}!important;
    }
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
        font-size: 12px;
        color: ${BrandingColors.darkGreyFont};
        font-weight: 700;
        font-family: 'FiraSans-Regular', sans-serif;
    }
    @media (min-width:1920px) {
        label, span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
            font-size: 16px;
        }
    }
}`
export const FormGroup = styled.div`{
    display:flex;
    justify-content: flex-start;
    flex:1 1 0px;
    padding-bottom: 20px;
    &.jc-fs{
        justify-content: flex-start;
    }
    &.align-center{
        align-items: center;
    }
    .radio-group{
         align-items: center;
    }
    &.jc-end{
        justify-content: end;
    }
    &.pb-0{
        padding-bottom: 0;
    }
    .radio-group.column{
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
         align-items: flex-start;
    }
    @media (max-width:980px) {
        flex-wrap : wrap
    }
}`
export const ButtonInput = styled.div`{
    margin-top: 15px;
    display: flex;
    button{
        min-width: 100px;
        text-transform: none;
        height: 45px;
    }
    .BgYellowOrange{
        background-color:${BrandingColors.yellowOrange};
        color: ${BrandingColors.darkGreyFont};
        padding: 4px 0;
        &:hover{
            background-color:${BrandingColors.brightTeal};
        color: ${BrandingColors.darkBlack};
        }
       span{
        color: ${BrandingColors.darkBlack};
        font-family: 'Lato-semi-bold';
        line-height:1;
       }
    }
    @media (min-width:1920px){
        .BgYellowOrange span{
            font-size: 18px;
        }
    }
    
}`
export const ButtonWrapperFilter = styled.div`{
    display:flex;
    flex-grow: 1;
    justify-content:flex-end;
    button{
        min-width: 150px;
        text-transform: none;
    margin-left: 30px;
    height: 42px;
    }
    .BgYellowOrange{
        &.Mui-disabled{
            background-color: ${BrandingColors.btnPrimaryDisabled};
            border-color: ${BrandingColors.btnPrimaryDisabled};
            color: ${BrandingColors.disableGreyFont};
            min-height: 36px;
            padding: 0px 12px 0px 12px;
            vertical-align: middle;
            span{
                font-family: 'FiraSans-Regular', sans-serif;
                font-weight: 600;
                font-size: 14px;
                color: ${BrandingColors.btnTextDisabled};
            }
        }
        background-color:${BrandingColors.yellowOrange};
        color: ${BrandingColors.darkGreyFont};
        border-color: ${BrandingColors.yellowOrange};
        min-height: 36px;
        padding: 0px 12px 0px 12px;
        vertical-align: middle;
        &:hover{
            background-color: ${BrandingColors.brightTeal};;
            border-color: ${BrandingColors.brightTeal};
            color: ${BrandingColors.darkBlack};
            span{
                font-family: 'FiraSans-Regular', sans-serif;
                font-weight: 600;
                font-size: 14px;
                color: ${BrandingColors.darkBlack};
            }
        }
        &:active{
            background-color: ${BrandingColors.brightTeal};
            border-color: ${BrandingColors.brightTeal};
            color: ${BrandingColors.darkBlack};
            span{
                font-family: 'FiraSans-Regular', sans-serif;
                font-weight: 600;
                font-size: 14px;
                color: ${BrandingColors.darkBlack};
            }
        }
        span{
            color: ${BrandingColors.darkGreyFont};
            font-family: 'FiraSans-Regular', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height:1;
            @media (min-width:1920px) {
                font-size: 18px;
            }
        }
    }
    .BgLightBlue{
        &.Mui-disabled{
            background-color: ${BrandingColors.white};
            border-color: ${BrandingColors.disablelightGreyFont};
            color: ${BrandingColors.disableGreyFont};
            min-height: 36px;
            padding: 0px 12px 0px 12px;
            vertical-align: middle;
            span{
                font-family: 'FiraSans-Regular', sans-serif;
                font-weight: 600;
                font-size: 14px;
                color: ${BrandingColors.btnTextDisabled};
            }
        }
        background-color: ${BrandingColors.white};
        border: 2px solid ${BrandingColors.brightTeal};
        color: ${BrandingColors.darkGreyFont};
        min-height: 36px;
        padding: 0px 12px 0px 12px;
        vertical-align: middle;
        box-shadow: none; 
        &:hover{
            background-color: ${BrandingColors.white};
            border-color:${BrandingColors.texthover};
            color: ${BrandingColors.darkBlack};
        }
        &:active{
            background-color: ${BrandingColors.white};
            border-color: ${BrandingColors.texthover};
            color: ${BrandingColors.darkBlack};
        }
       span{
        font-family: 'FiraSans-Regular', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height:1;
        &:hover{
            color: ${BrandingColors.darkBlack}; 
        }
        &:active{
            color: ${BrandingColors.darkBlack}; 
        }
        @media (min-width:1920px) {
            font-size: 18px;
            }
       }
    }
    .confirm {
        min-width: 80px;
        height: 30px;
        margin-left: 15px;
    }
    &.change-pwd-btn{
        justify-content : center;
        flex-direction: column;
        align-items: center;
        padding-top: 25px;
        button{
            width: 70%;
            margin-bottom: 20px;
            margin-left: 0;
        }
    }
    @media (min-width:1920px) {
        .BgYellowOrange,.BgLightBlue{
            span,&.Mui-disabled span{
                font-size: 18px;
            }
        }
        .confirm, .change-pwd-btn {
            font-size: 18px;
        }
    }
}`


export const FormEmailInputWrapper = styled.div`{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &.textareaEmail{
        margin-bottom:0;
        &.bold{
            textarea{
            font-weight: bold;
            }
        }
        &.italic{
            textarea{
               font-style: italic;
                }
        }
        &.underline{
            textarea{
               text-decoration: underline;
                }
        }
    }
    label{
        padding-right: 16px;
    width: 10%;
    }
    input{
        padding: 2.325% 14px;
    }
}`

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiCard-root': {
            borderRadius: '0',
            marginBottom: '25px'
        },
        '& .MuiCardContent-root.headerTitle': {
            padding: '8px 16px',
            '& .tableToolbar.whiteclass': {
                backgroundColor: BrandingColors.hoverBlue
            },
        },
        '& .MuiGrid-container.jc': {
            alignItems: 'center'
        },
        '& .MuiTextField-root,& .MuiFormControl-root': {
            width: '100%',
            '&.checkboxInput': {
                width: 'auto'
            }
        },
        '& .icon-text .MuiTextField-root,& .icon-text .MuiFormControl-root': {
            width: '87%',
        },
        '& .icon-text.doubled .MuiTextField-root,& .icon-text.doubled .MuiFormControl-root': {
            width: '92%',
        },
        '& .icon-text.doublesize .MuiTextField-root,& .icon-text.doublesize .MuiFormControl-root': {
            width: '92%',
        },
        '& .MuiAutocomplete-root .material-icons-outlined': {
            color: "#0000ff"
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${BrandingColors.texthover}`
        },
        '& .icon-text svg': {
            paddingLeft: '5px',
            cursor: 'pointer',
            color: `${BrandingColors.fontOrangeCustom}`
        },
        '& .MuiOutlinedInput-input,& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '12px',
            borderRadius: 4
        },
        '& .MuiListItem-button:hover': {
            backgroundColor: `${BrandingColors.hoverOrangeCustom}`
        },
        '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
            padding: 0,
            borderRadius: 4
        },
        '& .MuiOutlinedInput-root': {
            background: `${BrandingColors.white}`,
            borderColor: `${BrandingColors.lightGrey}`,
            boxSizing: 'border-box',
            borderRadius: 4
        },
        '& .doublesize input,& .doublesize .MuiOutlinedInput-input': {
            padding: '2.325% 14px'
        },
        '& .tripplesize input,& .tripplesize .MuiOutlinedInput-input': {
            padding: '1.5% 14px'
        },
        '& .title': {
            paddingBottom: '16px'
        },
        '& .formulaAddButton': {
            marginTop: "18px"
        },
        '& .formulaAddButtonOne': {
            marginTop: "18px",
            marginRight: "400px"
        },
        '& .formulaAddButtonCourses': {
            marginTop: "18px",
            marginRight: "100px"
        },
        '& .emailIconStudentAccess': {
            color: `${BrandingColors.darkBlack}`,
        },
        '& .MuiInputAdornment-root .MuiIconButton-root': {
            padding: 0
        },
        '& .MuiRadio-root .MuiIconButton-label input + div .MuiSvgIcon-root': {
            fontSize: '1em',
            color: `${BrandingColors.lightGreyFont}`
        },
        '& .MuiRadio-root .MuiIconButton-label input:checked + div .MuiSvgIcon-root': {
            fontSize: '1em',
            color: `${BrandingColors.lightBlueFont}`
        },
        '& .MuiRadio-root .MuiIconButton-label input:hover + div .MuiSvgIcon-root': {
            fontSize: '1em',
            color: `${BrandingColors.hoverlightBlueFont}`
        },
        '& .MuiRadio-root .MuiIconButton-label input:disabled + div .MuiSvgIcon-root': {
            fontSize: '1em',
            color: `${BrandingColors.disablelightGreyFont}`
        },
        '& .MuiRadio-root': {
            color: `${BrandingColors.darkGreyFont}`,
            paddingRight: '5px'
        },
        '& .MuiRadio-root.Mui-checked': {
            color: `${BrandingColors.darkGreyFont}`
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: `${BrandingColors.darkGreyFont}`,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${BrandingColors.textBord}`,
        },
        '& .mandatory': {
            color: `${BrandingColors.redColor}`
        },
        '& .centerButton': {
            justifyContent: 'center'
        },
        '& .activityButton': {
            marginLeft: "218px"
        },
        '& .awardButton': {
            marginLeft: "24px"
        },
        '& .identifierButton': {
            marginLeft: "30px"
        },
        '& .billingTransactionButton': {
            marginTop: "18px",
        },
        '& .ButtonDistribute': {
            marginLeft: "140px"
        },
        '& .ButtonAddAddress': {
            marginLeft: "140px",
            marginTop: "18px"
        },
        '& .ButtonInitiate': {
            marginLeft: "140px"
        },
        '& .ImportButton': {
            marginRight: "395px",
            marginTop: "15px"
        },
        '& .helperText': {
            margin: "0",
            color: `${BrandingColors.redColor}`,
            paddingTop: 4,
            marginTop: 0,
            fontFamily: 'FiraSans-Regular'
        },
        '& .MuiFormHelperText-contained.helperText': {
            margin: "0",
            color: `${BrandingColors.redColor}`,
            paddingTop: 4,
            marginTop: 0,
            fontFamily: 'FiraSans-Regular'
        },
        '& .arrow': {
            cursor: 'pointer',
            transform: 'rotate(90deg)'
        },
        '& .arrow.active': {
            color: `${BrandingColors.yellowOrange}`,
            transform: 'rotate(270deg)'
        },
        '& .MuiFormHelperText-contained': {
            color: `${BrandingColors.redColor}`,
            marginTop: '6px',
            marginBottom: '12px',
            fontFamily: 'FiraSans-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '0.75rem'
        },
        '& .MuiCheckbox-root svg': {
            color: `${BrandingColors.darkBlack}`,
            fontSize: '1.5em'
        },
        '& .MuiCheckbox-indeterminate.MuiCheckbox-root svg': {
            color: `${BrandingColors.btnTxtBlue}`,
        },
        '& .addIcon path': {
            color: `${BrandingColors.customGreen}`,
        },
        '& .removeIcon': {
            borderRadius: "50%",
            background: `${BrandingColors.redColor}`,
            color: `${BrandingColors.white}`,
            width: '30px',
            height: '30px'

        },
        '& .iconSize': {
            fontSize: '1.5em'
        },
        '& .greenText': {
            color: `${BrandingColors.customGreen}`,
        },
        '& .page3 .MuiIconButton-root:hover': {
            backgroundColor: 'transparent'
        },
        '& .Note': {
            color: `${BrandingColors.customBlue}`,
        },
        '& .GradeOptions': {
            fontSize: '1.5rem',
            marginRight: '10px',
            fill: 'currentColor',
        },
        '& .GradeOptionsRadio': {
            margin: '10px'
        },
        '& .addMaintenancePlus': {
            cursor: 'pointer',
            color: `${BrandingColors.yellowOrange}`,
            display: 'block',
            left: '7px',
            width: '30px',
            height: '30px',
            borderRadius: '2px'
        },
        '& .addMaintenancePlus.active': {
            display: 'block',
            left: '-8px',
            width: '21px',
            height: '4px',
            top: '8px',
            background: `${BrandingColors.yellowOrange}`,
            borderRadius: '2px',
        },
        '& .timeError': {
            color: `${BrandingColors.redColor}`,
            fontSize: '0.75rem',
            fontStyle: 'normal',
            marginTop: '6px',
            fontFamily: 'FiraSans-Regular',
            fontWeight: '400',
            marginBottom: '12px',
            display: 'flex'
        },
        '& .addNotesButton': {
            marginRight: '70px'
        },

        '& .massStudentCheckBox .MuiTypography-body1': {
            fontWeight: 'bold'
        },
        ' & .costCenterButton': {
            marginTop: '-50px'
        },
        ' &.addQueryBuilderButton': {
            marginTop: '20px'
        },
        '& .flexStart': {
            justifyContent: 'flex-start',
            alignItems: 'center',
            position: 'relative',
            top: '8px'
        },
        '& .flexStartCenter': {
            display: 'flex',
            margin: '8px',
            cursor: 'pointer',
            paddingTop: '2px',
            '& svg': {
                fontSize: "18px",
                color: `${BrandingColors.yellowOrange}`
            }
        },
        '& .flex': {
            display: 'flex',
        },
        '& .container-progress': {
            height: '20px',
            backgroundColor: '#CCC',
            position: 'relative',
            borderRadius: '7px'
        },
        '& .container-progress .progress-bar': {
            position: 'absolute',
            height: '100%',
            borderRadius: '7px',
            backgroundColor: 'violet'
        },
        '& .ExportOptions': {
            fontSize: '2.5rem',
            marginRight: '20px',
            fill: 'currentColor',
        },
        '& .ExportOptionsRadio': {
            margin: '10px'
        },
        '& .MuiDialogContentText-root': {
            backgroundColor: `${BrandingColors.white}`,
            color: `${BrandingColors.darkBlack}`,
            fontSize: '1.5em'
        },
        '& .addGroupButton': {
            justifyContent: 'start',
            marginTop: '17px'
        },
        '& .addButtonSetup': {
            marginRight: '25px'
        },

        '& .addStylingButton': {
            justifyContent: 'start',
            marginTop: '17px',
            marginLeft: '612px'
        },
        '& .addradio': {
            marginLeft: '150px',
        },

        '& .radio': {
            marginRight: '30%',
        },
        '& .obsolete': {
            marginLeft: '20px',
            paddingTop: '15px'
        },

        '&.btn-group ': {
            border: '1px solid green',
            padding: '15px 32px',
            textalign: 'center',
            fontSize: '16px',
            width: '150px',
            display: 'block'
        },
        '&.sent ': {
            marginTop: '500px'
        },
        '& .addBrowse': {
            justifyContent: 'start',
            marginTop: '22px',
            marginRight: '50px'
        },
        '&.student-configuration-table': {
            fontFamily: 'FiraSans-Regular',
            width: '10%'
        },
        '&.student-configuration-table td,.student-configuration-table th': {
            textalign: 'left',
            padding: '8px'

        },
        '&.reg-classification': {
            fontFamily: 'FiraSans-Regular',
            width: '10%'
        },
        '&.reg-classification td,.reg-classification th': {
            textalign: 'left',
            padding: '10px'

        },
        '& .studentName': {
            fontSize: "16px",
            lineHeight: "24px",
            display: "flex",
            justifyContent: "right",
        },
        '& .headerTitle': {
            fontFamily: '"FiraSans-Regular", sans-serif',
            fontSize: "18px",
            lineHeight: "26px",
            fontWeight: 600
        }

    },

    adornedEnd: {
        '& p': {
            padding: 0,
            fontSize: "12px"
        }

    },

    flexColumn: {
        display: 'flex',
        margin: '1rem',
        justifyContent: 'center'
    },
    imgCenter: {
        margin: '0 auto',
        display: 'block',
        maxWidth: '50%'
    },
    heading: {
        color: `${BrandingColors.fontOrangeCustom}`
    }
}));

export const useTableStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        '& .tableToolbar': {
            backgroundColor: `${BrandingColors.hoverOrangeCustom}`,
            padding: '0 24px',
            minHeight: '50px',
            '& svg': {
                cursor: 'pointer',
                margin: '0 10px',
                color: `${BrandingColors.darkBlack}`,
                '&.disable': {
                    pointerEvents: 'none',
                    color: `${BrandingColors.lightGrey}`
                }
            }
        },
        '& .tableToolbar.whiteclass': {
            backgroundColor: BrandingColors.hoverBlue,
            padding: '0 24px',
            minHeight: '50px',
            '& svg': {
                cursor: 'pointer',
                margin: '0 10px',
                color: `${BrandingColors.darkBlack}`,
                '&.disable': {
                    pointerEvents: 'none',
                    color: `${BrandingColors.lightGrey}`
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '0',
            height: '54px',
            boxSizing: 'border-box',
            borderTop: "1px solid #D1D1D1",
            borderBottom: "1px solid #D1D1D1",
            borderRadius: 0
        },
        '& .MuiCheckbox-root svg': {
            fontSize: '24px'
        },
        '& .MuiTableCell-head': {
            boxSizing: 'border-box',
            '& span': {
                fontWeight: '500',
                width: "100%",
                justifyContent: 'flex-start',
                lineHeight: '1.4',
            },
            '& span p': {
                color: "#424B5C !important",
            },
            '&:first-child span': {
                width: 'auto'
            }
        },
        '& .MuiTableHead-root th': {
            // borderBottomColor: `${BrandingColors.fontOrangeCustom}`,
            '& .drag': {
                ' &:hover': {
                    borderRight: '2px solid grey'
                }
            },
            '@media (min-width: 1920px)': {
                fontSize: '18px'
            }
        },
        '& .MuiTablePagination-actions,& .MuiTablePagination-caption,& .MuiTablePagination-selectLabel,& .MuiTablePagination-displayedRows': {
            display: 'none',
        },
        '& .MuiPagination-root': {
            margin: 0,
            '& .MuiPagination-ul': {
                justifyContent: 'flex-end'
            }
        },
        '& .MuiTableRow-root.MuiTableRow-hover:hover': {
            backgroundColor: `${BrandingColors.hoverOrangeCustom}`
        },
        '& .css-1p5q5e5-MuiStack-root': {
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 16px'
        },

        '& .MuiTableContainer-root': {
            overflow: 'auto !important',
            maxHeight: '400px'
        },
        '& .MuiTablePagination-selectRoot': {
            margin: '0 8px',
            overflow: 'hidden',
            border: '1px solid rgba(224, 224, 224, 1)'
        },
        '& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeSmall': {
            backgroundColor: `${BrandingColors.white}`,
            color: `${BrandingColors.darkGreyFont}`
        },
        '& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeSmall.Mui-selected,& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeSmall:hover': {
            backgroundColor: `${BrandingColors.hoverOrangeCustom}`,
            borderRadius: '0px',
            border: `1px solid ${BrandingColors.fontOrangeCustom}`,
            color: `${BrandingColors.darkBlack}`
        },
        '& .MuiPaper-root': {
            boxShadow: 'none'
        },
        '& .row-checkbox': {
            pointerEvents: 'none'
        },
        '& .MuiTableHead-root': {
            position: 'sticky',
            top: 0,
            background: 'white',
            'z-index': '1',
            boxShadow: `0px 1px ${BrandingColors.fontOrangeCustom}, 0px 0px ${BrandingColors.fontOrangeCustom}, 0px 0px ${BrandingColors.fontOrangeCustom}`
        },
        '& .MuiTableCell-root.MuiTableCell-body': {
            '@media (min-width: 1920px)': {
                fontSize: '18px'
            }
        }

    },

    paper: {
        width: '100%',
        overflowX: 'auto',
        boxShadow: 'none',
        boxSizing: 'border-box',
        // For accordion table
        '& .collapsibleTableStyles': {
            '& tr:nth-of-type(even)': {
                '& .MuiTableCell-root': {
                    // padding: 0,
                    borderBottom: 0,

                },
                '& .MuiCollapse-hidden': {
                    display: 'none'
                }
            },
        }
    },


    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


export const Splitter = styled.div`
    height: ${props => props.height || "50px"};
`

export const ImageContent = styled.div`
width:${props => props.width || "75%"}
`

export const UploadImageContent = styled.div`
width:30%
`
export const UploadImageContentForStudent = styled.div`
width:100%
`

export const HorizontalList = styled.ul`
    display: flex;
    list-style-type: none;
    font-size: 1rem;
    li{
        padding-right: 7%;
    }
`
export const BreadcrumbName = styled.span`
color: ${BrandingColors.darkGreyFont};
font-size: 0.875rem;
font-weight: 500;
`

export const SwitchRow = styled.div`{
    display: flex;
    padding: 0px 10px 20px 30px;
}`

export const SwitchColumn = styled.div`{
    flex: 80%;
    line-height: 30px;
}`

export const SwitchColumn1 = styled.div`{
    flex: 20%;
}`

export const SwitchBorder = styled.div`{
    border-bottom: 1px solid grey;
    margin: 0px 0px 20px 0px;
}`
